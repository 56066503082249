import React, { FC, useState } from 'react';
import BetaBanner from '../header/BetaBanner';
import { OwlerLogoLarge } from '../header/OwlerLogo';

const HeroNav: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <BetaBanner />
      <nav className="bg-white border-gray-200">
        <div className="flex flex-wrap items-center justify-between m-4 p-4">
          <OwlerLogoLarge />
          <div className="md:hidden flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="inline-flex p-2 w-15 h-15 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-8 h-8"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-end w-full md:flex md:w-auto text-sm ${
              isOpen ? 'block' : 'hidden'
            }`}
          >
            <ul className="md:order-1 flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li>
                <a
                  href="/about"
                  className="block py-2 px-3 md:p-0 rounded md:bg-transparent md:hover:text-blue-700"
                >
                  ABOUT
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="block py-2 px-3 md:p-0 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                >
                  CONTACT
                </a>
              </li>
              {/* <li>
                <a
                  href="/pricing"
                  className="block py-2 px-3 md:p-0 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                >
                  PRICING
                </a>
              </li> */}
              <li>
                <a
                  href="/resources"
                  className="block py-2 px-3 md:p-0 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:mr-3"
                >
                  RESOURCES
                </a>
              </li>
            </ul>
            <div className="py-2 px-3 md:order-3 flex flex-col font-medium p-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-4 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <div className="text-white self-center hidden sm:block md:text-gray-800">
                |
              </div>
              <a
                className="self-center m-4 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700"
                href="/login"
              >
                SIGN IN
              </a>
              <a
                href="/signup"
                className="text-white mb-7 md:mb-0 shadow bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-small rounded-3xl text-sm px-4 py-2.5 text-center self-center"
              >
                TRY IT FOR FREE
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeroNav;
