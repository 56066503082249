import React, { useState } from 'react';
import AssignmentRowView from './AssignmentList/AssignmentRowView';
import { Container } from '../../dependencyInjection/Container';
import { AssignmentReference } from './AssignmentList/AssignmentReference';
import { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import { useApi } from '../../contexts/ApiProvider';

type AssignmentListProps = {
  container: Container;
  assignments?: AssignmentReference[];
  isLoading: boolean;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  reuseCallback: () => void;
  editCallback: () => void;
};

const AssignmentList: React.FC<AssignmentListProps> = ({
  container,
  assignments,
  isLoading,
  assignmentMetadataContentModel,
  reuseCallback,
  editCallback,
}) => {
  const { api, analyticsApi } = useApi();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const handleItemClick = (newId: string) => {
    if (selectedId == newId) {
      analyticsApi.logAssignmentPreviewClose(
        api.getCurrentUserId(),
        selectedId
      );
      setSelectedId(null);
    } else {
      analyticsApi.logAssignmentPreviewOpen(api.getCurrentUserId(), newId);
      setSelectedId(newId);
    }
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!assignments) {
    return <div>No assignments found</div>;
  }

  return (
    <>
      <ul role="list" className="space-y-2">
        {' '}
        {assignments.map((assignment) => (
          <li
            key={assignment.assignment_id}
            className={`owler-li-bubble owler-border-gray-lg gap-x-6 py-5
                ${
                  assignment.assignment_id == selectedId
                    ? 'bg-indigo-50 border-indigo-300'
                    : 'hover:bg-gray-50'
                }`}
            onClick={() => handleItemClick(assignment.assignment_id)}
          >
            <AssignmentRowView
              key={assignment.assignment_id}
              assignmentReference={assignment}
              isSelected={assignment.assignment_id === selectedId}
              reuseCallback={reuseCallback}
              editCallback={editCallback}
              assignmentMetadataContentModel={assignmentMetadataContentModel}
              container={container}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default AssignmentList;
