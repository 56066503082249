import {
  FirebaseStorage,
  getDownloadURL,
  getStorage,
  ref,
} from 'firebase/storage';

export class FirebaseFileDownloader {
  constructor(private readonly _firebaseStorage: FirebaseStorage) {}

  private _createStorageProxyUrl(fullUrl: string, delimiter: string): string {
    const url = new URL(fullUrl);
    const path = url.pathname;
    const startIndex = path.indexOf(delimiter) + delimiter.length; // +delimiter length to skip the delimiter

    return '/storage/' + path.slice(startIndex) + url.search;
  }

  async downloadFileAsText(filePath: string): Promise<string> {
    const docRef = ref(this._firebaseStorage, filePath);
    try {
      const downloadUrl = await getDownloadURL(docRef);
      const proxyUrl = this._createStorageProxyUrl(downloadUrl, '/o/');
      const response = await fetch(proxyUrl);
      const text = await response.text();
      return text;
    } catch (error) {
      console.error(`Error downloading file: ${filePath}`, error);
      throw error;
    }
  }
}
