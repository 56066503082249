import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Todo: We probably want to bust these creds at some point and move to configs/vercel
const firebaseConfig = {
  apiKey: 'AIzaSyC2YTrkmi0ghnsZiCNH_G7AS23PxCG-QPU',
  authDomain: 'owlerai.firebaseapp.com',
  projectId: 'owlerai',
  storageBucket: 'owlerai.appspot.com',
  messagingSenderId: '1051758743434',
  appId: '1:1051758743434:web:c6f1f6df8d9fb83f304273',
  measurementId: 'G-VR9LE23Y9Z',
};

const app = initializeApp(firebaseConfig);

export default app;
