import React from 'react';

interface GeneralInfoCardProps {
  uid: string;
  schoolName: string;
  department: string;
  city: string;
  state: string;
  zip: string;
  subjects: string[];
  badges: string[];
}

const GeneralInfoCard: React.FC<GeneralInfoCardProps> = ({
  uid,
  schoolName,
  department,
  city,
  state,
  zip,
  subjects,
  badges,
}) => {
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          General Information
        </h3>
      </div>
      <div>
        <dl>
          <InfoItem term="User ID" description={uid} />
          <InfoItem term="School" description={schoolName} />
          <InfoItem term="Department" description={department} />
          <InfoItem term="Location" description={`${city}, ${state} ${zip}`} />
          <InfoItem term="Subjects" description={subjects.join(', ')} />
          <InfoItem
            term="Focus Areas"
            description={badges.map((badge) => (
              <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-blue-800">
                {badge}
              </span>
            ))}
          />
        </dl>
      </div>
    </div>
  );
};

interface InfoItemProps {
  term: string;
  description: React.ReactNode;
}

const InfoItem: React.FC<InfoItemProps> = ({ term, description }) => {
  return (
    <div className="py-4 px-5 sm:py-5 sm:px-6 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{term}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {description}
      </dd>
    </div>
  );
};

export default GeneralInfoCard;
