import React from 'react';
import { AssignmentMetadataInfo } from './AssignmentMetadataInfo';
import { AssignmentMetadataContentV1 } from '../../../Types';
import ListElementHeader from '../../utils/ListElementHeader';
import ListElementSubHeader from '../../utils/ListElementSubHeader';
import AssignmentSelectionButtons from '../../utils/AssignmentSelectionButtons';

type AssignmentMetadataInfoViewProps = {
  assignmentMetadataInfo: AssignmentMetadataInfo;
  isSelected: boolean;
  assignmentMetadataContent?: AssignmentMetadataContentV1;
  reuseCallback: () => void;
  editCallback?: () => void;
};

const AssignmentMetadataInfoView: React.FC<AssignmentMetadataInfoViewProps> = ({
  assignmentMetadataInfo,
  isSelected,
  assignmentMetadataContent,
  reuseCallback,
  editCallback,
}) => {
  return (
    <div className="min-w-0">
      <ListElementHeader
        text={
          assignmentMetadataInfo.course +
          ' - ' +
          assignmentMetadataInfo.question_truncated
        }
      />
      <ListElementSubHeader
        date={
          (assignmentMetadataInfo.created_at &&
            new Date(assignmentMetadataInfo.created_at).toDateString()) ||
          ''
        }
      />
      {isSelected && assignmentMetadataContent && (
        <div className="mt-4 text-gray-800">
          <p className="mt-1 text-sm">
            <span className="font-bold">Department:</span>{' '}
            {assignmentMetadataContent.department}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Course:</span>{' '}
            {assignmentMetadataContent.course}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Teacher Type:</span>{' '}
            {assignmentMetadataContent.teacher_type}
          </p>
          {assignmentMetadataContent.grade_level && (
            <p className="mt-1 text-sm">
              <span className="font-bold">Grade Level:</span>{' '}
              {assignmentMetadataContent.grade_level}
            </p>
          )}
          <p className="mt-1 text-sm">
            <span className="font-bold">Question:</span>{' '}
            {assignmentMetadataContent.question}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Question background:</span>{' '}
            {assignmentMetadataContent.question_background}
          </p>
          <div className="mt-2 overflow-hidden border border-gray-200 rounded-md">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="w-1/5 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="w-7/10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="w-1/10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Points Possible
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {assignmentMetadataContent.rubric.map((rubric) => (
                  <tr
                    key={
                      assignmentMetadataInfo.assignment_metadata_id +
                      rubric.name
                    }
                  >
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900 break-words">
                        {rubric.name}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900 break-words">
                        {rubric.description}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900 break-words">
                        {rubric.points_possible}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <AssignmentSelectionButtons
            editCallback={editCallback}
            reuseCallback={reuseCallback}
          />
        </div>
      )}
    </div>
  );
};

export default AssignmentMetadataInfoView;
