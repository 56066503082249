import React, { FC } from 'react';

type TestimonialProps = {
  quote: string;
  author: string;
  img: string;
};

const TestimonialCard: FC<TestimonialProps> = ({ quote, author, img }) => {
  return (
    <div className="border p-6 rounded-lg shadow-md flex flex-col">
      <img className="" src={img} />
      <div className="m-4 bg-indigo-50 rounded-3xl">
        <p className="font-semibold text-gray-900">{author}</p>
      </div>
      <blockquote className="italic text-start font-poppins text-gray-600">
        "{quote}"
      </blockquote>
    </div>
  );
};

export default TestimonialCard;
