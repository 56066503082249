import { useQuery } from '@tanstack/react-query';
import { AssignmentMetadataContentV1 } from '../../../Types';
import { AssignmentMetadataContentProvider } from '../../AssignmentMetadata/AssignmentMetadataContentProvider';

function useAssignmentMetadataContentByPath(
  assignmentMetadataContentProvider: AssignmentMetadataContentProvider,
  assignmentMetadataPath?: string
):
  | {
      assignmentMetadataContent?: AssignmentMetadataContentV1;
      error: any;
      isLoading: boolean;
      refetch: any;
    }
  | undefined {
  const {
    data: assignmentMetadataContent,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      'assignmentMetadataContentProvider.getByPath(',
      assignmentMetadataPath,
    ],
    queryFn: async () => {
      const res = await assignmentMetadataContentProvider.getByPath(
        assignmentMetadataPath
      );
      return res ?? null;
    },
  });

  return {
    assignmentMetadataContent: assignmentMetadataContent ?? undefined,
    error,
    isLoading,
    refetch,
  };
}

export { useAssignmentMetadataContentByPath };
