import React from 'react';
import { AssignmentReference } from './AssignmentReference';
import AssignmentStatusView from './AssignmentStatusView';
import { useAssignment } from '../hooks/useAssignment';
import { Container } from '../../../dependencyInjection/Container';
import { useGradingStatus } from '../hooks/useGradingStatus';
import { GradingStatuses } from './GradingStatuses';
import ListElementHeader from '../../utils/ListElementHeader';
import ListElementSubHeader from '../../utils/ListElementSubHeader';
import { AssignmentMetadataContentModel } from '../../AssignmentMetadata/AssignmentMetadataContentModel';
import { useSetAssignmentMetadataContentModel } from '../hooks/useSetAssignmentMetadataContent';
import AssignmentSelectionButtons from '../../utils/AssignmentSelectionButtons';

type AssignmentViewProps = {
  assignmentReference: AssignmentReference;
  isSelected: boolean;
  reuseCallback: () => void;
  editCallback: () => void;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  container: Container;
};

const AssignmentRowView: React.FC<AssignmentViewProps> = ({
  assignmentReference,
  isSelected,
  reuseCallback,
  editCallback,
  assignmentMetadataContentModel,
  container,
}) => {
  const { assignment, refetch } = useAssignment(
    assignmentReference,
    container.assignmentProvider
  );

  const gradingStatus = useGradingStatus(
    container.assignmentStatusProvider,
    assignment
  );

  const showViewAssignmentButton =
    gradingStatus?.jobStatus === GradingStatuses.Completed;

  useSetAssignmentMetadataContentModel(
    isSelected,
    assignment?.metadata_content,
    assignment?.assignment_metadata_info,
    assignmentMetadataContentModel
  );

  return (
    <div className="max-w-full">
      <div className="flex justify-between items-center gap-x-6">
        <div className="flex-1 min-w-0">
          {assignment && (
            <>
              <ListElementHeader text={assignment.metadata_content.question} />
              <ListElementSubHeader
                date={new Date(assignment.created_at).toDateString()}
              />
            </>
          )}
        </div>
        <div className="flex items-center gap-x-6 justify-end">
          <div className="flex items-center gap-x-6">
            {assignment && (
              <div className="items-top">
                <AssignmentStatusView
                  assignment={assignment}
                  container={container}
                  gradingStatus={gradingStatus}
                />
              </div>
            )}
            {showViewAssignmentButton && assignment && (
              <a
                href={`/assignments/${assignment?.assignment_id}`}
                className="owler-white-button"
              >
                View assignment
                <span className="sr-only">
                  , {assignment.metadata_content.question}
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
      {isSelected && assignment && assignment.metadata_content && (
        <div className="mt-4 text-gray-800">
          <p className="mt-1 text-sm">
            <span className="font-bold">Department:</span>{' '}
            {assignment.metadata_content.department}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Course:</span>{' '}
            {assignment.metadata_content.course}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Teacher Type:</span>{' '}
            {assignment.metadata_content.teacher_type}
          </p>
          {assignment.metadata_content.grade_level && (
            <p className="mt-1 text-sm">
              <span className="font-bold">Grade Level:</span>{' '}
              {assignment.metadata_content.grade_level}
            </p>
          )}
          <p className="mt-1 text-sm">
            <span className="font-bold">Question:</span>{' '}
            {assignment.metadata_content.question}
          </p>
          <p className="mt-1 text-sm">
            <span className="font-bold">Question background:</span>{' '}
            {assignment.metadata_content.question_background}
          </p>
          <div className="mt-2 overflow-hidden border border-gray-200 rounded-md">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="w-1/5 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="w-7/10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="w-1/10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                  >
                    Points Possible
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {assignment.metadata_content.rubric.map((rubric) => (
                  <tr
                    key={
                      assignment.assignment_metadata_info
                        .assignment_metadata_id + rubric.name
                    }
                  >
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900 break-words">
                        {rubric.name}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900 break-words">
                        {rubric.description}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900 break-words">
                        {rubric.points_possible}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <AssignmentSelectionButtons
            editCallback={editCallback}
            reuseCallback={reuseCallback}
          />
        </div>
      )}
    </div>
  );
};

export default AssignmentRowView;
