import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { ReviewDetailPartial } from '../../../components/review/ReviewDetail';
import { useFetchSingleGradingResultFromExport } from '../../../utils/useFetchSingleGradingResultFromExport';
import { useGradingResult } from '../../../components/review/GradingResultContext';

const SubmissionViewerView: React.FC = () => {
  const { assignmentId, submissionId } = useParams();
  const { gradingResult, setGradingResult } = useGradingResult();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  if (!assignmentId || !submissionId || !token) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        <h1 className="text-3xl m-3">:(</h1>
        <p>Submission not found or invalid token provided.</p>
        <p>Check with your teacher for the correct URL.</p>
      </div>
    );
  }

  const {
    loading,
    error,
    data: exportResult,
  } = useFetchSingleGradingResultFromExport(assignmentId, submissionId, token);

  useEffect(() => {
    if (exportResult) {
      setGradingResult(exportResult.gradingResult);
    }
  }, [exportResult]);

  if (loading) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (error || !exportResult) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        <h1 className="text-3xl m-3">:(</h1>
        <p>Submission not found or invalid token provided.</p>
        <p>Check with your teacher for the correct URL.</p>
      </div>
    );
  }

  const assignmentMetadataContent = exportResult.assignmentMetadataContent;

  return (
    <ReviewDetailPartial
      assignmentMetadataContent={assignmentMetadataContent}
      assignmentId={assignmentId}
      submissionId={submissionId}
      isEditable={false}
    />
  );
};

export { SubmissionViewerView };
