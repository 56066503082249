import { useState, useEffect } from 'react';
import { Assignment } from '../../../Types';
import { FirebaseStorage, listAll, ref } from 'firebase/storage';
import {
  Firestore,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import { AssignmentReference } from '../AssignmentList/AssignmentReference';
import { Submission } from '../../../Types';

const useSubmissions = (
  submissionsProvider: SubmissionsProvider,
  assignmentReference?: AssignmentReference
) => {
  const [submissions, setSubmissions] = useState<Submission[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (!assignmentReference) {
      return;
    }

    submissionsProvider
      .getSubmissions(assignmentReference)
      .then(setSubmissions);
  }, [assignmentReference, submissionsProvider]);

  return submissions;
};

function trimLeadingPath(fileName: string): string {
  return fileName.split('/').pop()!;
}

export class FirebaseSubmissionsProvider implements SubmissionsProvider {
  constructor(
    private readonly _firebaseStorage: FirebaseStorage,
    private readonly _firestore: Firestore,
    private readonly _userId: string
  ) {}

  async getSubmissions(
    assignmentReference: AssignmentReference
  ): Promise<Submission[] | undefined> {
    // try to get submissions from the submissions collection
    const submissions =
      await this.getSubmissionsFromSubmissionsCollection(assignmentReference);
    if (submissions) {
      return submissions;
    }

    // otherwise use the grading job id to fetch submissions
    return this.getSubmissionsForGradingJobId(
      assignmentReference.grading_job_id
    );
  }

  async getSubmissionsFromSubmissionsCollection(
    assignmentReference: AssignmentReference
  ): Promise<Submission[] | undefined> {
    // check if firebaseAssignment has a submissions collection
    try {
      const assignmentDocRef = doc(
        this._firestore,
        'users',
        this._userId,
        'assignments',
        assignmentReference.assignment_id
      );

      if (!assignmentDocRef) {
        return undefined;
      }
      const submissionsColRef = collection(assignmentDocRef, 'submissions');
      const submissionsSnapshot = await getDocs(submissionsColRef);

      if (submissionsSnapshot.empty) {
        return undefined;
      }

      return submissionsSnapshot.docs.map((doc) => {
        const firebaseSubmission = doc.data();
        return {
          fileName: trimLeadingPath(firebaseSubmission.output_path),
          submissionId: doc.id,
          outputPath: firebaseSubmission.output_path,
          submissionPath: firebaseSubmission.submission_path,
          token: firebaseSubmission.token,
        };
      });
    } catch (error) {
      console.log('Error fetching submissions: ', error);
    }

    return undefined;
  }

  async getSubmissionsForGradingJobId(
    gradingJobId: string
  ): Promise<Submission[] | undefined> {
    const folderPath = `users/${this._userId}/output/${gradingJobId}`;
    const storageReference = ref(this._firebaseStorage, folderPath);
    const listResult = await listAll(storageReference);

    const fileNames = listResult.items.map((item) => item.name);

    return fileNames.map((fileName) => {
      return { fileName: fileName };
    });
  }
}

export interface SubmissionsProvider {
  getSubmissions(
    assignmentReference: AssignmentReference
  ): Promise<Submission[] | undefined>;
}

export { useSubmissions };
