import React, { useEffect } from 'react';
import { useApi } from '../../contexts/ApiProvider'; // Adjust the import path if necessary

export default function LogoutView() {
  const { api, analyticsApi } = useApi();

  useEffect(() => {
    const userId = api.getCurrentUserId();
    api.logout().catch((error) => console.error('Logout failed:', error));
    analyticsApi.logLogoutPageCompletion(userId);
    analyticsApi.reset();
  }, []);

  return <div>Logging out...</div>;
}
