import { useEffect } from 'react';
import { AssignmentMetadataContentModel } from '../../AssignmentMetadata/AssignmentMetadataContentModel';
import { AssignmentMetadataContentV1 } from '../../../Types';
import { AssignmentMetadataInfo } from '../../AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';

function useSetAssignmentMetadataContentModel(
  shouldUpdate: boolean,
  assignmentMetadataContent: AssignmentMetadataContentV1 | undefined,
  assignmentMetadataInfo: AssignmentMetadataInfo | undefined,
  assignmentMetadataContentModel: AssignmentMetadataContentModel,
  setShouldUpdate?: (shouldUpdate: boolean) => void
) {
  useEffect(() => {
    if (shouldUpdate && assignmentMetadataContent && assignmentMetadataInfo) {
      assignmentMetadataContentModel.setAssignmentMetadataContent(
        assignmentMetadataContent
      );
      // set the original because this is the original metadata content loaded from the server
      // this is used to determine if the user has made any changes to the metadata content.
      assignmentMetadataContentModel.setOriginalAssignmentMetadataContent(
        assignmentMetadataContent
      );
      assignmentMetadataContentModel.setAssignmentMetadataInfo(
        assignmentMetadataInfo
      );
      // reset the shouldUpdate flag so that we don't update again until requested
      if (setShouldUpdate) {
        setShouldUpdate(false);
      }
    }
  }, [
    shouldUpdate,
    assignmentMetadataContent,
    assignmentMetadataInfo,
    assignmentMetadataContentModel,
  ]);
}

export { useSetAssignmentMetadataContentModel };
