import React from 'react';
import { Assignment } from '../../../Types';
import { Container } from '../../../dependencyInjection/Container';
import { GradingStatus } from './GradingStatus';

type AssignmentStatusViewProps = {
  assignment: Assignment;
  container: Container;
  gradingStatus?: GradingStatus;
};

const AssignmentStatusView: React.FC<AssignmentStatusViewProps> = ({
  assignment,
  container,
  gradingStatus,
}) => {
  if (!gradingStatus) {
    return <></>;
  }

  const classes = [
    gradingStatus.color,
    'rounded-md',
    'whitespace-nowrap',
    'mt-0.5',
    'px-1.5',
    'py-0.5',
    'text-xs',
    'font-medium',
    'ring-1',
    'ring-inset',
  ].join(' ');

  return (
    <div className="flex items-start">
      <p className={classes}>{gradingStatus.toString()}</p>
    </div>
  );
};

export default AssignmentStatusView;
