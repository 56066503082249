import React, { FC } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import { Container } from '../../dependencyInjection/Container';
import AssignmentPage from '../../components/assignments/AssignmentPage';

type AssignmentsViewProps = {
  container: Container;
};

const AssignmentsView: FC<AssignmentsViewProps> = ({ container }) => {
  return (
    <Dashboard
      currentNavigation="Assignments"
      children={
        <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200">
          <div className="px-4 py-5 sm:p-6">
            <AssignmentPage container={container} />
          </div>
        </div>
      }
    />
  );
};

export default AssignmentsView;
