import React, { useState } from 'react';
import { highlightColors } from '../../constants';
import { AssignmentMetadataContent, DatabaseFieldAccessor } from '../../Types';
import { PointsRange } from './PointRange';
import {
  QualitativePointsRange,
  DescriptorRange,
} from './QualitativePointRange';
import { parseGradeWithPercentSign } from './reviewUtils';
import { TextEditor } from '../TextEditor/TextEditor';
import { UnorderedListTextEditor } from '../TextEditor/UnorderedListTextEditor';
import { useApi } from '../../contexts/ApiProvider';
import _ from 'lodash';
import { containsInvalidCharactersForFirestore } from '../../utils';
import { useGradingResult } from './GradingResultContext';

interface ReviewFeedbackProps {
  assignmentMetadataContent: AssignmentMetadataContent;
  assignmentId: string;
  submissionId?: string;
  isEditable?: boolean;
}

const ReviewFeedback: React.FC<ReviewFeedbackProps> = ({
  assignmentMetadataContent,
  assignmentId,
  submissionId,
  isEditable = false,
}) => {
  const { gradingResult, setGradingResult } = useGradingResult();
  if (!gradingResult) {
    return <div>Loading...</div>;
  }
  const { api, analyticsApi } = useApi();

  const [openHighlightedText, setOpenHighlightedText] = useState<
    Record<string, boolean>
  >({});

  const toggleHighlightedText = (key: string) => {
    setOpenHighlightedText({
      ...openHighlightedText,
      [key]: !openHighlightedText[key],
    });
  };

  const gradeDescriptorMapping: DescriptorRange[] = [
    // scale is translated to be from 50-100%, so for this mapping, 0 = 50% and 50 = 100%
    { range: [0, 12.5], descriptor: 'Does Not Meet Expectations' },
    { range: [12.5, 25], descriptor: 'Partially Meets Expectations' },
    { range: [25, 37.5], descriptor: 'Mostly Meets Expectations' },
    // go up to 51 to include 50% as a possible grade
    { range: [37.5, 51], descriptor: 'Meets Expectations' },
  ];

  let explanationAccessor: DatabaseFieldAccessor<string> | undefined =
    undefined;
  let suggestionsAccessor: DatabaseFieldAccessor<string[]> | undefined =
    undefined;
  let followUpQuestionsAccessor: DatabaseFieldAccessor<string[]> | undefined =
    undefined;
  let performanceEstimateAccessor: DatabaseFieldAccessor<string> | undefined =
    undefined;

  let rubricExplanationAccessors: Record<
    string,
    DatabaseFieldAccessor<string>
  > = {};
  let rubricHighlightedTextAccessors: Record<
    string,
    DatabaseFieldAccessor<string[]>
  > = {};
  let rubricScoreAccessors: Record<string, DatabaseFieldAccessor<string>> = {};
  let rubricValidMap: Record<string, boolean> = {};

  if (isEditable && submissionId !== undefined) {
    const userId = api.getCurrentUserId();
    const documentPath = [
      'users',
      userId,
      'assignments',
      assignmentId,
      'submissions',
      submissionId,
      'output',
      'gradingOutput',
    ];
    explanationAccessor = api.createDatabaseFieldAccessor<string>(
      documentPath,
      ['explanation']
    );
    suggestionsAccessor = api.createDatabaseFieldAccessor<string[]>(
      documentPath,
      ['suggestions']
    );
    followUpQuestionsAccessor = api.createDatabaseFieldAccessor<string[]>(
      documentPath,
      ['follow_up_questions']
    );
    performanceEstimateAccessor = api.createDatabaseFieldAccessor<string>(
      documentPath,
      ['grade']
    );
    // Assume gradingResult.rubricGrading is an object with rubric keys and grading details
    Object.entries(gradingResult.rubricGrading).forEach(([index, value]) => {
      // Create accessor for the 'explanation' field inside each rubric category
      const key = Object.keys(value)[0];
      const isValid = !containsInvalidCharactersForFirestore(key);
      rubricValidMap[key] = isValid;
      if (isValid) {
        rubricExplanationAccessors[key] =
          api.createDatabaseFieldAccessor<string>(documentPath, [
            'rubric_grading',
            key,
            'explanation',
          ]);
        rubricHighlightedTextAccessors[key] = api.createDatabaseFieldAccessor<
          string[]
        >(documentPath, ['rubric_grading', key, 'highlighted_text']);
        rubricScoreAccessors[key] = api.createDatabaseFieldAccessor<string>(
          documentPath,
          ['rubric_grading', key, 'points_earned']
        );
      }
    });
  }

  const logAnalytics = (action: string) => {
    const userId = api.getCurrentUserId();

    if (action === 'performance') {
      analyticsApi.logReviewSliderPerformance(userId);
    } else if (action === 'explanation') {
      analyticsApi.logReviewTextEditedExplanation(userId);
    } else if (action === 'suggestions') {
      analyticsApi.logReviewTextEditedSuggestions(userId);
    } else if (action === 'followup') {
      analyticsApi.logReviewTextEditedFollowUp(userId);
    } else if (action === 'score') {
      analyticsApi.logReviewSliderScore(userId);
    } else if (action === 'feedback') {
      analyticsApi.logReviewTextEditedFeedback(userId);
    }
  };

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <div>
          <h2 className="text-3xl my-4">Owler Feedback</h2>
          <div className="my-4">
            <QualitativePointsRange
              key={gradingResult.grade}
              // remove the % sign from the grade and translate it to be from 0-50
              pointsEarned={Math.max(
                parseGradeWithPercentSign(gradingResult.grade) - 50,
                0
              )}
              pointsPossible={50.0}
              numberOfSteps={50}
              offset={50}
              descriptorMapping={gradeDescriptorMapping}
              saveContent={
                performanceEstimateAccessor
                  ? (content) => {
                      performanceEstimateAccessor?.write(content);
                      logAnalytics('performance');
                    }
                  : undefined
              }
            />
          </div>
          <div className="pt-4">
            <p className="font-bold underline mt-6 mb-2">Explanation</p>
            {explanationAccessor && (
              <TextEditor
                saveContent={(content) => {
                  explanationAccessor?.write(content);
                  logAnalytics('explanation');
                }}
                initialContent={gradingResult.explanation}
              />
            )}
            {explanationAccessor === undefined && (
              <p>{gradingResult.explanation}</p>
            )}
          </div>
        </div>
        <div>
          <p className="font-bold underline mt-6 mb-2">
            Suggestions for Improvement
          </p>
          <ul className="list-disc pl-5">
            {suggestionsAccessor && gradingResult.suggestions ? (
              <UnorderedListTextEditor
                saveContent={(content) => {
                  if (suggestionsAccessor) {
                    suggestionsAccessor.write(content);
                    logAnalytics('suggestions');
                  }
                }}
                initialContent={gradingResult.suggestions}
              />
            ) : (
              gradingResult.suggestions &&
              gradingResult.suggestions.map((line, index) => (
                <li key={index} className="mb-1">
                  {line}
                </li>
              ))
            )}
          </ul>
        </div>
        <div>
          <p className="font-bold underline mt-6 mb-2">Follow-Up Questions</p>
          <ul className="list-disc pl-5">
            {followUpQuestionsAccessor && gradingResult.followUpQuestions ? (
              <UnorderedListTextEditor
                saveContent={(content) => {
                  if (followUpQuestionsAccessor) {
                    followUpQuestionsAccessor.write(content);
                    logAnalytics('followup');
                  }
                }}
                initialContent={gradingResult.followUpQuestions}
              />
            ) : (
              gradingResult.followUpQuestions &&
              gradingResult.followUpQuestions.map((line, index) => (
                <li key={index} className="mb-1">
                  {line}
                </li>
              ))
            )}
          </ul>
        </div>
        <div>
          {gradingResult.rubricGrading &&
            gradingResult.rubricGrading.map((entry, index) => {
              const key = Object.keys(entry)[0];
              const data = entry[key];
              const isOpen = openHighlightedText[key];
              const rubric = assignmentMetadataContent.rubric;
              const rubricComponent = rubric.find(
                (rubricKey) => rubricKey.name === key
              );
              const rubricExplanationAccessor = rubricExplanationAccessors[key];
              const rubricHighlightedTextAccessor =
                rubricHighlightedTextAccessors[key];
              const rubricScoreAccessor = rubricScoreAccessors[key];

              return (
                <div key={index} className="my-4">
                  <h3
                    className={`text-xl ${
                      highlightColors[
                        index % gradingResult.rubricGrading.length
                      ]
                    } p-2 rounded`}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </h3>
                  {rubricValidMap[key] === false && (
                    <p className="text-red-500">
                      {`Rubric name contains invalid characters. The "${key}" rubric category cannot be edited. Please recreate the assignment to fix this issue.`}
                    </p>
                  )}
                  {data.points_earned &&
                    rubricComponent &&
                    isFinite(parseFloat(rubricComponent.points_possible)) && (
                      <div className="my-2">
                        <PointsRange
                          key={data.points_earned}
                          pointsEarned={parseFloat(data.points_earned)}
                          pointsPossible={parseFloat(
                            rubricComponent.points_possible
                          )}
                          saveContent={
                            rubricScoreAccessor
                              ? (content) => {
                                  rubricScoreAccessor?.write(content);
                                  logAnalytics('score');
                                }
                              : undefined
                          }
                        />
                      </div>
                    )}
                  <div>
                    <p className="font-bold underline my-2">Feedback</p>
                    {rubricExplanationAccessor ? (
                      <TextEditor
                        saveContent={(content) => {
                          if (rubricExplanationAccessor) {
                            rubricExplanationAccessor.write(content);
                            logAnalytics('feedback');
                          }
                        }}
                        initialContent={data.explanation}
                      />
                    ) : (
                      <p>{data.explanation}</p>
                    )}
                  </div>
                  <p
                    className="my-2 font-bold cursor-pointer flex items-center"
                    onClick={() => toggleHighlightedText(key)}
                  >
                    <span
                      className={`transition-transform duration-300 ${
                        isOpen ? 'transform rotate-90' : ''
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </span>
                    &nbsp;Highlighted Text
                  </p>
                  {isOpen && (
                    <ul className="list-disc pl-5">
                      {rubricHighlightedTextAccessor &&
                        data.highlighted_text && (
                          <UnorderedListTextEditor
                            saveContent={(contents: string[]) => {
                              rubricHighlightedTextAccessor.write(contents);
                              let newRubricGrading = _.cloneDeep(
                                gradingResult.rubricGrading
                              );
                              newRubricGrading[index][key].highlighted_text =
                                contents;
                              setGradingResult({
                                ...gradingResult,
                                rubricGrading: newRubricGrading,
                              });
                            }}
                            initialContent={data.highlighted_text}
                          />
                        )}
                      {rubricHighlightedTextAccessor === undefined &&
                        data.highlighted_text &&
                        data.highlighted_text.map((text, i) => (
                          <li key={i} className="mb-1">
                            {text}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ReviewFeedback;
