import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import { DeltaStatic } from 'quill';
import TurndownService from 'turndown';

interface TextEditorProps {
  saveContent: (content: string) => void;
  initialContent?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({
  saveContent,
  initialContent = '',
}) => {
  const quillRef = useRef<ReactQuill>(null);
  const [content, setContent] = useState<DeltaStatic | null>(null);
  const debounceTime: number = 500;
  const turndownService = new TurndownService();

  useEffect(() => {
    const quillInstance = quillRef.current?.getEditor();

    if (quillInstance) {
      const html = turndownService.turndown(initialContent);
      quillInstance.setContents(quillInstance.clipboard.convert(html));
      setContent(quillInstance.getContents());

      const handleChange = _.debounce(() => {
        const currentContent = quillInstance.getContents();
        const markdown = turndownService.turndown(quillInstance.root.innerHTML);

        if (!_.isEqual(currentContent, content)) {
          saveContent(markdown);
          setContent(currentContent);
        }
      }, debounceTime);

      quillInstance.on('text-change', handleChange);

      return () => {
        quillInstance.off('text-change', handleChange);
      };
    }
  }, [initialContent]);

  const modules = { toolbar: false };
  const formats: string[] = [];

  return (
    <div>
      <ReactQuill ref={quillRef} formats={formats} modules={modules} />
    </div>
  );
};

export { TextEditor };
