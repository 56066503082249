import React, { FC } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import TermsOfService from '../../components/termsOfService/TermsOfService';

type TermsOfServiceViewProps = {};

const TermsOfServiceView: FC<TermsOfServiceViewProps> = () => {
  return (
    <Dashboard
      currentNavigation=""
      children={
        <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200">
          <div className="px-4 py-5 sm:p-6">
            <TermsOfService />
          </div>
        </div>
      }
    />
  );
};

export default TermsOfServiceView;
