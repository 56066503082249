import React from 'react';
import chroma from 'chroma-js';

export interface PointsRangeProps {
  pointsEarned: number;
  pointsPossible: number;
  saveContent?: (content: string) => void;
  offset?: number;
  minimumPoints?: number;
  numberOfSteps?: number;
}

interface PointsRangeState {
  currentPoints: number;
  isDragging: boolean;
}

export class PointsRange extends React.Component<
  PointsRangeProps,
  PointsRangeState
> {
  sliderRef: React.RefObject<HTMLDivElement>; // Create a ref for the slider container
  minimumPoints: number;
  numberOfSteps: number;

  constructor(props: PointsRangeProps) {
    super(props);
    this.minimumPoints =
      props.minimumPoints == undefined ? 0 : props.minimumPoints;
    this.numberOfSteps = props.numberOfSteps || 20;
    this.state = {
      currentPoints: props.pointsEarned,
      isDragging: false,
    };
    this.sliderRef = React.createRef();
  }

  getColorScale() {
    const { pointsPossible } = this.props;
    return chroma
      .scale(['red', 'orange', 'green'])
      .domain([pointsPossible * 0.03, pointsPossible * 0.97]);
  }

  calculateBounds() {
    const { pointsPossible } = this.props;
    const currentPoints = this.state.currentPoints;
    const percentBounds = 0.1;
    const lowerBound = Math.max(
      currentPoints - pointsPossible * percentBounds,
      this.minimumPoints
    );
    const upperBound = Math.min(
      currentPoints + pointsPossible * percentBounds,
      pointsPossible
    );
    return { lowerBound, upperBound };
  }

  startDrag = (event: React.MouseEvent) => {
    event.preventDefault();
    this.setState({ isDragging: true });
    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDrag);
  };

  onDrag = (event: MouseEvent) => {
    if (
      !this.state.isDragging ||
      !this.sliderRef.current ||
      !this.props.saveContent
    )
      return;
    const sliderRect = this.sliderRef.current.getBoundingClientRect();
    const relativeX = event.clientX - sliderRect.left;

    const stepSize = this.props.pointsPossible / this.numberOfSteps;

    let stepIndex = Math.round(
      (relativeX / sliderRect.width) * this.numberOfSteps
    );
    stepIndex = Math.max(
      this.minimumPoints,
      Math.min(this.numberOfSteps, stepIndex)
    );
    const newValue = stepIndex * stepSize;
    this.setState({ currentPoints: newValue });
  };

  stopDrag = () => {
    this.setState({ isDragging: false });
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDrag);
    if (this.props.saveContent) {
      const offset = this.props.offset || 0;
      const savedGrade = this.state.currentPoints + offset;
      this.props.saveContent(savedGrade.toString());
    }
  };

  getEstimatedScore(): string {
    const { pointsPossible } = this.props;
    const { lowerBound, upperBound } = this.calculateBounds();
    return `${Math.round(lowerBound * 100) / 100} to ${
      Math.round(upperBound * 100) / 100
    } out of ${pointsPossible}`;
  }

  renderEstimatedScore() {
    return (
      <p className="mb-2">
        <span className="font-bold">Score Estimate:</span>{' '}
        {this.getEstimatedScore()}.
      </p>
    );
  }

  renderTicks() {
    const { pointsPossible } = this.props;
    const tickInterval = Math.ceil(pointsPossible / 10);
    const ticks = Array.from(
      { length: Math.ceil(pointsPossible / tickInterval) + 1 },
      (_, i) => i * tickInterval
    );

    return (
      <div className="relative flex justify-between">
        {ticks.map((tick) => (
          <div key={tick} className="text-xs text-center text-gray-500 px-4">
            <span>{tick}</span>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { pointsPossible } = this.props;
    const { lowerBound, upperBound } = this.calculateBounds();
    const colorScale = this.getColorScale();
    const lowerBoundColor = colorScale(lowerBound).css();
    const upperBoundColor = colorScale(upperBound).css();
    const gradient = `linear-gradient(90deg, ${lowerBoundColor}, ${upperBoundColor})`;

    const totalWidthPercent = 100;
    const lowerBoundPercent = (lowerBound / pointsPossible) * totalWidthPercent;
    const upperBoundPercent = (upperBound / pointsPossible) * totalWidthPercent;

    return (
      <div>
        {this.renderEstimatedScore()}
        <div
          ref={this.sliderRef}
          className="relative h-4 flex items-center"
          onMouseDown={this.startDrag}
        >
          <div className="absolute top-1/4 left-0 h-1 w-full bg-gray-300 rounded-full transform -translate-y-1/2"></div>
          <div
            className="absolute top-1/4 h-4 rounded-full shadow border border-white-900 transform -translate-y-1/2"
            style={{
              left: `${lowerBoundPercent}%`,
              width: `${upperBoundPercent - lowerBoundPercent}%`,
              backgroundImage: gradient,
            }}
          ></div>
        </div>
        {this.renderTicks()}
      </div>
    );
  }
}

export default PointsRange;
