import { RubricGrading } from '../../Types';
import { highlightColors } from '../../constants';
import FuzzySet from 'fuzzyset';

export interface Highlight {
  index: number;
  length: number;
  color: string;
  rubricName: string;
}

export interface TextSegment {
  text: string;
  index: number;
  colors: string[];
}

export function splitByPunctuation(input: string): string[] {
  const trimmedInput = input.replace(/\s+/g, '');
  return trimmedInput.split(/[.!?]/).filter((word) => word !== '');
}

export function createIndexMapping(originalText: string): Map<number, number> {
  const indexMap = new Map<number, number>();
  let strippedIndex = 0;

  for (let i = 0; i < originalText.length; i++) {
    if (!/\s+|[.!?]/.test(originalText[i])) {
      indexMap.set(strippedIndex, i);
      strippedIndex++;
    }
  }

  return indexMap;
}

export function generateHighlights(
  studentSubmission: string,
  rubricGrading: RubricGrading
): Highlight[] {
  const splitText = splitByPunctuation(studentSubmission);
  const strippedText = splitText.join('');
  const indexMap = createIndexMapping(studentSubmission);
  const fuzzy = FuzzySet(splitText);
  const matchThreshold = 0.5;

  const highlights: Highlight[] = [];

  for (const rubricResult of rubricGrading) {
    const rubricName = Object.keys(rubricResult)[0];
    const highlightedTextArr = rubricResult[rubricName].highlighted_text;
    const color =
      highlightColors[
        rubricGrading.indexOf(rubricResult) % highlightColors.length
      ];

    if (highlightedTextArr) {
      for (const highlightedText of highlightedTextArr) {
        const splitHighlighted = splitByPunctuation(highlightedText);

        for (const sentence of splitHighlighted) {
          const result = fuzzy.get(sentence);
          if (result) {
            const [score, matchedString] = result[0];
            if (score > matchThreshold) {
              const strippedIndex = strippedText.indexOf(matchedString);
              const originalIndex = indexMap.get(strippedIndex);
              if (originalIndex !== undefined) {
                let originalLength = 0;
                for (let i = originalIndex; i < studentSubmission.length; i++) {
                  if (/[.!?]/.test(studentSubmission[i])) {
                    break;
                  }
                  originalLength++;
                }
                highlights.push({
                  index: originalIndex,
                  length: originalLength,
                  color,
                  rubricName,
                });
              }
            }
          }
        }
      }
    }
  }

  return highlights;
}

export function generateTextSegments(
  studentSubmission: string,
  highlights: Highlight[]
): TextSegment[] {
  highlights.sort((a, b) => a.index - b.index);

  const segments: TextSegment[] = [];
  let pos = 0;
  let highlightIndex = 0;
  let activeHighlights: Highlight[] = [];

  while (pos < studentSubmission.length) {
    // Remove expired highlights
    activeHighlights = activeHighlights.filter((h) => h.index + h.length > pos);

    // Add new highlights starting at this position
    while (
      highlightIndex < highlights.length &&
      highlights[highlightIndex].index <= pos
    ) {
      activeHighlights.push(highlights[highlightIndex]);
      highlightIndex++;
    }

    // Find the next position where a highlight starts or ends
    const nextPos = Math.min(
      ...[
        studentSubmission.length,
        ...activeHighlights.map((h) => h.index + h.length),
        ...(highlightIndex < highlights.length
          ? [highlights[highlightIndex].index]
          : []),
      ]
    );

    // Create a segment with the current text and active colors
    segments.push({
      text: studentSubmission.substring(pos, nextPos),
      index: pos,
      colors: Array.from(new Set(activeHighlights.map((h) => h.color))),
    });

    pos = nextPos;
  }

  return segments;
}

export function parseGradeWithPercentSign(grade: string) {
  let gradeStr = String(grade);
  if (gradeStr.endsWith('%')) {
    gradeStr = gradeStr.slice(0, -1);
  }
  return parseFloat(gradeStr);
}
