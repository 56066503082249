import { useQuery } from '@tanstack/react-query';
import { AssignmentMetadataInfoListProvider } from '../../AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfoListProvider';
import { AssignmentMetadataInfo } from '../../AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';

function useAssignmentMetadataInfoList(
  assignmentMetadataInfoListProvider: AssignmentMetadataInfoListProvider
):
  | {
      assignmentMetadataInfoList: AssignmentMetadataInfo[] | undefined;
      isLoading: boolean;
      error: any;
      refresh: any;
    }
  | undefined {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['assignmentMetadataInfoListProvider.get()'],
    queryFn: () => assignmentMetadataInfoListProvider.get(),
  });

  return {
    assignmentMetadataInfoList: data,
    isLoading,
    error,
    refresh: refetch,
  };
}

export { useAssignmentMetadataInfoList };
