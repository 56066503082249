import React, { FC } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import PrivacyPolicy from '../../components/privacyPolicy/PrivacyPolicy';

type PricingViewProps = {};

const PrivacyPolicyView: FC<PricingViewProps> = () => {
  return (
    <Dashboard
      currentNavigation=""
      children={
        <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200">
          <div className="px-4 py-5 sm:p-6">
            <PrivacyPolicy />
          </div>
        </div>
      }
    />
  );
};

export default PrivacyPolicyView;
