import React, { FC, useState } from 'react';
import emailjs from '@emailjs/browser';

// TODO: create emailjs account for Owler

const ContactForm: FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [school, setSchool] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const serviceId = 'service_dbymizm';
    const templateId = 'template_tsk9fuk';
    const publicKey = 'V1HCVP4UttU1t6wBp';

    const templateParams = {
      from_name: name,
      from_email: email,
      from_school: school,
      from_role: role,
      to_name: 'Owler',
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response: any) => {
        console.log('Email sent successfully', response);
        setName('');
        setEmail('');
        setSchool('');
        setRole('');
        setMessage('');
      })
      .catch((error: any) => {
        console.error('Error sending email', error);
      });
  };

  return (
    <>
      <div className="bg-gradient-to-b from-indigo-50 to-indigo-200 pt-20">
        <h1 className="text-center text-4xl sm:text-5xl font-suranna">
          questions, feedback, or more info on custom pricing?
        </h1>
        <form
          onSubmit={handleSubmit}
          className="emailForm flex flex-col mx-7 my-5 sm:mx-96 sm:my-10 font-poppins"
        >
          <input
            className='"w-full py-4 mb-6 rounded-md border border-neutral-300 bg-white py-3 px-6 text-base font-medium outline-none focus:border-neutral-400 focus:shadow-md'
            type="text"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className='"w-full py-4 mb-6 rounded-md border border-neutral-300 bg-white py-3 px-6 text-base font-medium outline-none focus:border-neutral-400 focus:shadow-md'
            type="text"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className='"w-full py-4 mb-6 rounded-md border border-neutral-300 bg-white py-3 px-6 text-base font-medium outline-none focus:border-neutral-400 focus:shadow-md'
            type="text"
            placeholder="School"
            value={school}
            onChange={(e) => setSchool(e.target.value)}
          />
          <input
            className='"w-full py-4 mb-6 rounded-md border border-neutral-300 bg-white py-3 px-6 text-base font-medium outline-none focus:border-neutral-400 focus:shadow-md'
            type="text"
            placeholder="Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
          <textarea
            className="resize-none w-full py-4 mb-6 rounded-md border border-neutral-300 bg-white py-3 px-6 text-base font-medium outline-none focus:border-neutral-400 focus:shadow-md"
            cols={30}
            rows={9}
            value={message}
            placeholder="Message"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            type="submit"
            className="mb-10 text-white shadow bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-3xl text-sm sm:text-lg px-8 py-4 text-center self-center font-bold"
          >
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
