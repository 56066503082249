import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import { capitalizeFirstChar } from '../../utils';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useApi } from '../../contexts/ApiProvider';

interface AssignmentFormProps {
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  submit: () => void;
  goBack?: () => void;
}

interface Input {
  name: string;
  type: string;
  placeholder: string;
  value: string;
  error: string | null;
}

const AssignmentForm: React.FC<AssignmentFormProps> = ({
  assignmentMetadataContentModel,
  submit,
  goBack,
}) => {
  const { api, analyticsApi } = useApi();

  const assignmentMetadataContent =
    assignmentMetadataContentModel.AssignmentMetadataContent;

  const [inputs, setInputs] = useState<Input[]>([
    {
      type: 'text',
      name: 'department',
      placeholder: 'Department',
      value: assignmentMetadataContent.department,
      error: null,
    },
    {
      type: 'text',
      name: 'teacher_type',
      placeholder: 'Teacher Type (e.g. Teacher, Professor, etc.)',
      value: assignmentMetadataContent.teacher_type,
      error: null,
    },
    {
      type: 'text',
      name: 'school_type',
      placeholder: 'School Type (e.g. High School, College, etc.)',
      value: assignmentMetadataContent.school_type,
      error: null,
    },
    {
      type: 'text',
      name: 'course',
      placeholder: 'Course Title',
      value: assignmentMetadataContent.course,
      error: null,
    },
    {
      type: 'text',
      name: 'grade_level',
      placeholder: 'Grade Level (e.g. 9th, 10th, etc.)',
      value: assignmentMetadataContent.grade_level!,
      error: null,
    },
    {
      type: 'textArea',
      name: 'question_background',
      placeholder:
        'Please include relevant background information for this essay prompt (e.g., relevant books, type of essay, specifics of how it fits into the overall curriculum)',
      value: assignmentMetadataContent.question_background,
      error: null,
    },
    {
      type: 'textArea',
      name: 'question',
      placeholder: 'Essay prompt',
      value: assignmentMetadataContent.question,
      error: null,
    },
  ]);

  // Use effect to update state when assignmentMetadataContentModel changes
  useEffect(() => {
    setInputs(
      inputs.map((input) => ({
        ...input,
        value:
          assignmentMetadataContentModel.AssignmentMetadataContent[
            input.name
          ] || '',
        error: null,
      }))
    );
  }, [assignmentMetadataContentModel]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    // Update the state with the new value and reset error
    setInputs((inputs) =>
      inputs.map((input) =>
        input.name === name ? { ...input, value, error: null } : input
      )
    );
    assignmentMetadataContentModel.setValueForKey(value, name);
  };

  const setInputError = (name: string, error: string | null) => {
    setInputs((inputs) =>
      inputs.map((input) => (input.name === name ? { ...input, error } : input))
    );
  };

  const validateNonEmptyForm = (): boolean => {
    let valid = true;
    // Example for one field, repeat for others
    inputs.forEach((input) => {
      if (input.value.trim() === '') {
        setInputError(
          input.name,
          `${capitalizeFirstChar(input.name.replace('_', ' '))} is required`
        );
        valid = false;
      }
    });
    return valid;
  };

  const handleSubmit = () => {
    if (validateNonEmptyForm()) {
      const userId = api.getCurrentUserId();
      analyticsApi.logAssignmentFormSubmit(userId);
      submit();
    }
  };

  const handleGoBack = () => {
    if (goBack) {
      const userId = api.getCurrentUserId();
      analyticsApi.logAssignmentFormGoBack(userId);
      goBack();
    }
  };

  return (
    <div className="mx-auto max-w-5xl p-6 space-y-6 bg-white rounded-md">
      <h1 className="text-xl font-semibold">Create Assignment</h1>
      <p>
        First let's add some background information about the assignment. This
        information is used to tailor the grading output to your specific
        student body.
      </p>
      {inputs.map((input) => (
        <div key={input.name} className="mb-4">
          {' '}
          {/* Added div wrapper with margin-bottom for spacing */}
          {input.type === 'text' && (
            <input
              type="text"
              name={input.name}
              placeholder={input.placeholder}
              value={input.value}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          )}
          {input.type === 'textArea' && (
            <textarea
              name={input.name}
              placeholder={input.placeholder}
              rows={4}
              className="w-full p-2 border rounded"
              value={input.value}
              onChange={handleInputChange}
            ></textarea>
          )}
          {input.error && (
            <p className="text-red-500">{input.error}</p> // Conditionally display error message
          )}
        </div>
      ))}
      {goBack && (
        <div className="flex justify-between">
          <button
            onClick={handleGoBack} // This will navigate back to the previous page
            className="owler-indigo-secondary-button"
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" /> {/* Icon added here */}
            Previous
          </button>
          <button onClick={handleSubmit} className="owler-indigo-button">
            Create Assignment
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </button>
        </div>
      )}
      {!goBack && (
        <div className="flex justify-end">
          <button onClick={handleSubmit} className="owler-indigo-button">
            Create Assignment
            <ArrowRightIcon className="w-5 h-5 ml-2" />
          </button>
        </div>
      )}
    </div>
  );
};

export default AssignmentForm;
