import React, { useState } from 'react';
import { TextEditor } from './TextEditor';

interface UnorderedListTextEditorProps {
  saveContent: (contents: string[]) => void;
  initialContent: string[];
}

const UnorderedListTextEditor: React.FC<UnorderedListTextEditorProps> = ({
  saveContent,
  initialContent,
}) => {
  let contents = initialContent;

  const handleContentChange = (text: string, index: number) => {
    const updatedContents = [...contents];
    updatedContents[index] = text;
    contents = updatedContents;
    saveContent(updatedContents);
  };

  return (
    <>
      {contents.map((content, index) => (
        <li key={index} className="mb-1">
          <TextEditor
            initialContent={content}
            saveContent={(content) => handleContentChange(content, index)}
          />
        </li>
      ))}
    </>
  );
};

export { UnorderedListTextEditor };
