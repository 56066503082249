import React from 'react';

interface ProfileHeaderProps {
  name: string;
  title: string;
  stats: {
    classes: number;
    students: number;
    assignmentsGraded: number;
  };
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  name,
  title,
  stats,
}) => {
  return (
    <div className="bg-white text-black py-12 rounded-lg shadow mb-8">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <span className="inline-flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 mb-4">
            <span className="text-2xl font-medium leading-none text-white">
              {getInitials(name)}
            </span>
          </span>
          <h1 className="text-3xl font-bold">{name}</h1>
          <p className="text-xl">{title}</p>
        </div>
        {/* Stats section hidden on small screens */}
        <div className="hidden sm:grid sm:grid-cols-3 sm:divide-x sm:divide-gray-200 sm:mt-8">
          <Stat title="Classes" value={stats.classes} />
          <Stat title="Students" value={stats.students} />
          <Stat title="Assignments Graded" value={stats.assignmentsGraded} />
        </div>
      </div>
    </div>
  );
};

const Stat: React.FC<{ title: string; value: number }> = ({ title, value }) => {
  return (
    <div className="flex flex-col items-center p-4">
      <span className="text-lg font-medium">{value}</span>
      <span className="text-sm">{title}</span>
    </div>
  );
};

const getInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');
  return initials.toUpperCase();
};

export default ProfileHeader;
