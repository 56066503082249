import React from 'react';
import { PointsRange, PointsRangeProps } from './PointRange';

export interface DescriptorRange {
  // range is [inclusive, exclusive)
  range: [number, number];
  descriptor: string;
}

interface QualitativePointsRangeProps extends PointsRangeProps {
  descriptorMapping: DescriptorRange[];
}

export class QualitativePointsRange extends PointsRange {
  props!: QualitativePointsRangeProps;

  constructor(props: QualitativePointsRangeProps) {
    super(props);
  }

  getDescriptor(points: number): string {
    const { descriptorMapping } = this.props;
    const matchingRange = descriptorMapping.find(
      ({ range }) => points >= range[0] && points < range[1]
    );
    return matchingRange ? matchingRange.descriptor : 'Unknown';
  }

  renderTicks() {
    const { descriptorMapping, pointsPossible } = this.props;

    // Calculate midpoints for each range to use as ticks
    const ticks = descriptorMapping.map((item) => {
      const midpoint = (item.range[0] + item.range[1]) / 2; // Calculate the midpoint of the range
      return {
        value: midpoint,
        label: item.descriptor,
      };
    });

    return (
      <div className="relative w-full">
        {ticks.map((tick) => (
          <div
            key={tick.value}
            className={`absolute transform -translate-x-1/2 w-[120px] flex flex-col items-center text-xs text-center text-gray-500`}
            style={{ left: `${(tick.value / pointsPossible) * 100}%` }}
          >
            <span className="whitespace-normal">{tick.label}</span>
          </div>
        ))}
      </div>
    );
  }

  renderEstimatedScore() {
    return (
      <p className="mb-2 h-12">
        <span className="font-bold underline">
          Owler Overall Performance Estimate:
        </span>{' '}
        {this.getEstimatedScore()}.
      </p>
    );
  }

  getEstimatedScore(): string {
    const { pointsPossible, descriptorMapping } = this.props;
    const { lowerBound, upperBound } = this.calculateBounds();
    if (descriptorMapping) {
      const lowerBoundDescriptor = this.getDescriptor(lowerBound);
      const upperBoundDescriptor = this.getDescriptor(upperBound);
      if (lowerBoundDescriptor === upperBoundDescriptor) {
        return `${lowerBoundDescriptor}`;
      }
      return `${this.getDescriptor(lowerBound)} to ${this.getDescriptor(
        upperBound
      )}`;
    }
    return `${lowerBound} to ${upperBound} out of ${pointsPossible}`;
  }

  render() {
    const { pointsPossible } = this.props;
    const { lowerBound, upperBound } = this.calculateBounds();
    const colorScale = this.getColorScale();
    const lowerBoundColor = colorScale(lowerBound).css();
    const upperBoundColor = colorScale(upperBound).css();
    const gradient = `linear-gradient(90deg, ${lowerBoundColor}, ${upperBoundColor})`;

    const totalWidthPercent = 100;
    const lowerBoundPercent = (lowerBound / pointsPossible) * totalWidthPercent;
    const upperBoundPercent = (upperBound / pointsPossible) * totalWidthPercent;

    return (
      <div>
        {this.renderEstimatedScore()}
        <div
          ref={this.sliderRef}
          className="relative h-4 flex items-center"
          onMouseDown={this.startDrag}
        >
          <div className="absolute top-1/4 left-0 h-1 w-full bg-gray-300 rounded-full transform -translate-y-1/2"></div>
          <div
            className="absolute top-1/4 h-4 rounded-full shadow border border-white-900 transform -translate-y-1/2"
            style={{
              left: `${lowerBoundPercent}%`,
              width: `${upperBoundPercent - lowerBoundPercent}%`,
              backgroundImage: gradient,
            }}
          ></div>
        </div>
        {this.renderTicks()}
      </div>
    );
  }
}
