import React, { useEffect, useState } from 'react';
import { Container } from '../../../dependencyInjection/Container';
import { AssignmentMetadataInfo } from './AssignmentMetadataInfo';
import AssignmentMetadataInfoView from './AssignmentMetadataInfoView';
import { AssignmentMetadataContentModel } from '../AssignmentMetadataContentModel';
import { useAssignmentMetadataContentByPath } from '../../assignments/hooks/useAssignmentMetadataContentByPath';
import { useAssignmentMetadataInfoList } from '../../assignments/hooks/useAssignmentMetadataInfoList';
import { useSetAssignmentMetadataContentModel } from '../../assignments/hooks/useSetAssignmentMetadataContent';
import { useApi } from '../../../contexts/ApiProvider';

type AssignmentMetadataInfoListProps = {
  container: Container;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  reuseCallback: () => void;
  editCallback?: () => void;
};

const AssignmentMetadataInfoList: React.FC<AssignmentMetadataInfoListProps> = ({
  container,
  assignmentMetadataContentModel,
  reuseCallback,
  editCallback,
}) => {
  const { api, analyticsApi } = useApi();
  const [selectedAssignmentMetadataInfo, setSelectedAssignmentMetadataInfo] =
    useState<AssignmentMetadataInfo | undefined>(undefined);
  // controls whether the assignment metadata content should be updated, allowing user to edit
  // the inputted metadata content
  const [updateAssignmentMetadataContent, setUpdateAssignmentMetadataContent] =
    useState<boolean>(false);

  const maybeAssignmentMetadataInfoList = useAssignmentMetadataInfoList(
    container.assignmentMetadataInfoListProvider
  );
  const assignmentMetadataInfoList = maybeAssignmentMetadataInfoList
    ? maybeAssignmentMetadataInfoList.assignmentMetadataInfoList
    : undefined;

  const maybeSelectedAssignmentMetadataContent =
    useAssignmentMetadataContentByPath(
      container.assignmentMetadataContentProvider,
      selectedAssignmentMetadataInfo?.assignment_metadata_path
    );
  const selectedAssignmentMetadataContent =
    maybeSelectedAssignmentMetadataContent
      ? maybeSelectedAssignmentMetadataContent.assignmentMetadataContent
      : undefined;

  useSetAssignmentMetadataContentModel(
    updateAssignmentMetadataContent,
    selectedAssignmentMetadataContent,
    selectedAssignmentMetadataInfo,
    assignmentMetadataContentModel,
    setUpdateAssignmentMetadataContent
  );

  const handleItemClick = (assignmentMetadataInfo: AssignmentMetadataInfo) => {
    if (assignmentMetadataInfo == selectedAssignmentMetadataInfo) {
      // If the user clicks on the same assignment metadata info, then we want to deselect it
      analyticsApi.logSelectExistingAssignmentClose(
        api.getCurrentUserId(),
        assignmentMetadataInfo.assignment_metadata_id
      );
      setSelectedAssignmentMetadataInfo(undefined);
      assignmentMetadataContentModel.reset();
    } else {
      analyticsApi.logSelectExistingAssignmentOpen(
        api.getCurrentUserId(),
        assignmentMetadataInfo.assignment_metadata_id
      );
      setSelectedAssignmentMetadataInfo(assignmentMetadataInfo);
    }
    // mark that we should update the form with the contents of the assignmentMetadataContent
    setUpdateAssignmentMetadataContent(true);
  };

  if (!assignmentMetadataInfoList) {
    return <div>Loading...</div>;
  }

  return (
    <ul role="list" className="space-y-2">
      {assignmentMetadataInfoList.map((assignmentMetadataInfo) => (
        <li
          key={assignmentMetadataInfo.assignment_metadata_id}
          className={`owler-li-bubble owler-border-gray-lg ${
            selectedAssignmentMetadataInfo &&
            selectedAssignmentMetadataInfo?.assignment_metadata_id ===
              assignmentMetadataInfo.assignment_metadata_id
              ? 'bg-indigo-50 border-indigo-300' // Highlight selected item with an indigo border
              : 'hover:bg-gray-50'
          }`}
          onClick={() => handleItemClick(assignmentMetadataInfo)}
        >
          <AssignmentMetadataInfoView
            assignmentMetadataInfo={assignmentMetadataInfo}
            isSelected={
              selectedAssignmentMetadataInfo != undefined &&
              selectedAssignmentMetadataInfo?.assignment_metadata_id ===
                assignmentMetadataInfo.assignment_metadata_id
            }
            assignmentMetadataContent={
              selectedAssignmentMetadataInfo &&
              selectedAssignmentMetadataInfo?.assignment_metadata_id ===
                assignmentMetadataInfo.assignment_metadata_id
                ? selectedAssignmentMetadataContent
                : undefined
            }
            reuseCallback={reuseCallback}
            editCallback={editCallback}
          />
        </li>
      ))}
    </ul>
  );
};

export default AssignmentMetadataInfoList;
