// src/SegmentAnalyticsApi.ts
import { AnalyticsBrowser } from '@segment/analytics-next';
import { SegmentService } from './SegmentService';
import { AnalyticsApi } from '../Types';

class SegmentAnalyticsApi implements AnalyticsApi {
  private analytics: AnalyticsBrowser;
  private identifiedUsers: Set<string> = new Set();

  constructor() {
    this.analytics = SegmentService.getInstance();
  }

  private ensureUserIsIdentified(userId: string): void {
    if (!this.identifiedUsers.has(userId)) {
      this.analytics.identify(userId);
      this.identifiedUsers.add(userId);
    }
  }

  private logEvent(eventName: string, params: any): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.analytics.track(eventName, params);
        // console.log(
        // `Logged event: ${eventName} with params: ${JSON.stringify(params)}`
        // );
        resolve();
      } catch (error) {
        console.error(`Error logging event: ${eventName}`, error);
        reject(error);
      }
    });
  }

  logSignupPageCompletion(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('signup_page_completion', { userId });
  }

  logLoginPageCompletion(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('login_page_completion', { userId });
  }

  logLogoutPageCompletion(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    const result = this.logEvent('logout_page_completion', { userId });
    SegmentService.reset();
    return result;
  }

  logAssignmentListPageOpen(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_list_page_open', { userId });
  }

  logAssignmentPreviewOpen(
    userId: string,
    assignment_id: string
  ): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_preview_open', { userId, assignment_id });
  }

  logAssignmentPreviewClose(
    userId: string,
    assignment_id: string
  ): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_preview_close', { userId, assignment_id });
  }

  logAssignmentsPageCreateAssignment(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignments_page_create_assignment', { userId });
  }

  logSelectExistingAssignmentOpen(
    userId: string,
    assignmentId: string
  ): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('select_existing_assignment', {
      userId,
      assignmentId,
    });
  }

  logSelectExistingAssignmentClose(
    userId: string,
    assignment_id: string
  ): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('select_existing_assignment_close', {
      userId,
      assignment_id,
    });
  }

  logCreateAssignment(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('create_assignment', { userId });
  }

  logCreateRubric(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('create_rubric', { userId });
  }

  logCreateRubricPrevious(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('create_rubric_previous', { userId });
  }

  logAddRubricCategory(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('add_rubric_category', { userId });
  }

  logRemoveRubricCategory(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('delete_rubric_category', { userId });
  }

  logDuplicateAndEditAssignment(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('duplicate_and_edit_assignment', {
      userId,
    });
  }

  logReuseAssignment(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('reuse_assignment', { userId });
  }

  logUploadStudentSubmissions(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('upload_student_submissions', { userId });
  }

  logUploadStudentSubmissionsPrevious(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('upload_student_submissions_previous', { userId });
  }

  logUploadFiles(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('upload_files', { userId });
  }

  logPastedSubmission(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('pasted_submission', { userId });
  }

  logRemoveUploadedSubmission(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('remove_uploaded_submission', { userId });
  }

  logAssignmentViewCopyShareableLink(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('copy_shareable_link', { userId });
  }

  logAssignmentViewPrevious(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_view_previous', { userId });
  }

  logAssignmentViewNext(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_view_next', { userId });
  }

  logAssignmentFormSubmit(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_form_submit', { userId });
  }

  logAssignmentFormGoBack(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('assignment_form_go_back', { userId });
  }

  logRubricValidatedAndSubmitted(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('rubric_validated_submitted', { userId });
  }

  logReviewSliderPerformance(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('review_performance_slider_moved', { userId });
  }

  logReviewTextEditedExplanation(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('review_explanation_text_edited', { userId });
  }

  logReviewTextEditedSuggestions(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('review_suggestions_list_text_edited', { userId });
  }

  logReviewTextEditedFollowUp(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('review_followup_list_text_edited', { userId });
  }

  logReviewSliderScore(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('review_score_slider_moved', { userId });
  }

  logReviewTextEditedFeedback(userId: string): Promise<void> {
    this.ensureUserIsIdentified(userId);
    return this.logEvent('review_feedback_text_edited', { userId });
  }

  reset(): void {
    SegmentService.reset();
  }
}

export { SegmentAnalyticsApi };
