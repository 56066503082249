// GradingResultContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { GradingResult } from '../../Types';

// Define the shape of the context state
interface GradingResultContextState {
  gradingResult: GradingResult | null;
  setGradingResult: (gradingResult: GradingResult | null) => void;
}

// Initialize the context with default values
const defaultState: GradingResultContextState = {
  gradingResult: null,
  setGradingResult: () => {},
};

// Create the context
const GradingResultContext =
  createContext<GradingResultContextState>(defaultState);

// Custom hook for using the GradingResult context
export const useGradingResult = () => useContext(GradingResultContext);

// Define the props for the provider component
interface GradingResultProviderProps {
  children: ReactNode;
}

// Create the provider component
export const GradingResultProvider: React.FC<GradingResultProviderProps> = ({
  children,
}) => {
  const [gradingResult, setGradingResult] = useState<GradingResult | null>(
    null
  );

  return (
    <GradingResultContext.Provider value={{ gradingResult, setGradingResult }}>
      {children}
    </GradingResultContext.Provider>
  );
};
