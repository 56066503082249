import { GradingStatuses } from './GradingStatuses';

export class GradingStatus {
  constructor(readonly jobStatus: GradingStatuses) {}

  get doneRunning(): boolean {
    return (
      this.jobStatus === GradingStatuses.Completed ||
      this.jobStatus == GradingStatuses.Failed
    );
  }

  get color(): string {
    switch (this.jobStatus) {
      case GradingStatuses.InProgress:
        return 'text-yellow-600 bg-yellow-50 ring-yellow-500/10';
      case GradingStatuses.Completed:
        return 'text-green-700 bg-green-50 ring-green-600/20';
      case GradingStatuses.Failed:
        return 'text-red-800 bg-red-50 ring-red-600/20';
    }
  }

  toString(): string {
    switch (this.jobStatus) {
      case GradingStatuses.InProgress:
        return 'In progress';
      case GradingStatuses.Completed:
        return 'Complete';
      case GradingStatuses.Failed:
        return 'Error';
    }
  }
}
