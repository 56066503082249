import React from 'react';

const OwlerLogoLarge = () => {
  return (
    <a
      href="https://owlerai.com/"
      className="flex items-center space-x-3 rtl:space-x-reverse"
    >
      <img
        src="/images/owler-logo.png/"
        className="w-14 md:w-16 l:w-24"
        alt="Owler AI Logo"
      />
      <span className="self-center text-6xl md:text-7xl l:text-8xl font-poppins font-extrabold">
        owler
      </span>
    </a>
  );
};

const OwlerLogo = () => {
  return (
    <a
      href="https://owlerai.com/"
      className="flex items-center space-x-3 rtl:space-x-reverse"
    >
      <img
        src="/images/owler-logo.png/"
        className="w-8 md:w-9 l:w-10"
        alt="Owler AI Logo"
      />
      <span className="self-center text-2xl md:text-3xl l:text-4xl font-poppins font-extrabold">
        owler
      </span>
    </a>
  );
};

export { OwlerLogoLarge, OwlerLogo };
