import React, { FC } from 'react';
import HeroNav from '../../components/hero/HeroNav';
import PrivacyPolicy from '../../components/privacyPolicy/PrivacyPolicy';

type PrivacyPolicyUnauthenticatedViewProps = {};

const PrivacyPolicyUnauthenticatedView: FC<
  PrivacyPolicyUnauthenticatedViewProps
> = () => {
  return (
    <>
      <HeroNav />
      <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <PrivacyPolicy />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyUnauthenticatedView;
