import React, { FC, useState } from 'react';

import type { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import AssignmentForm from './AssignmentForm';
import RubricForm from './RubricForm';
import { Container } from '../../dependencyInjection/Container';
import AssignmentMetadataSelector from './AssignmentMetadataSelector';
import { scrollToTop } from '../../utils';
import { ManageNewSubmissionsView } from './ManageNewSubmissionsView';
import { useApi } from '../../contexts/ApiProvider';
import { GCAssignmentSelector } from './GCAssignmentSelector';

type AssignmentCreationPageProps = {
  container: Container;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  goBack?: () => void;
  postUpload: () => void;
};

const AssignmentCreationPage: FC<AssignmentCreationPageProps> = ({
  container,
  assignmentMetadataContentModel,
  goBack,
  postUpload,
}) => {
  const { api, analyticsApi } = useApi();
  const [currentStep, setCurrentStep] = useState<
    'assignment' | 'rubric' | 'upload' | 'upload-direct'
  >('assignment');

  const userId = api.getCurrentUserId();

  const goToEditAssignment = () => {
    scrollToTop();
  };

  const goToRubricForm = () => {
    scrollToTop();
    setCurrentStep('rubric');
  };
  const goToUploadDocuments = () => {
    scrollToTop();
    setCurrentStep('upload');
  };
  const goToUploadDirectDocuments = () => {
    scrollToTop();
    setCurrentStep('upload-direct');
  };
  const goToAssignmentForm = () => {
    scrollToTop();
    analyticsApi.logCreateRubricPrevious(userId);
    setCurrentStep('assignment');
  };

  switch (currentStep) {
    case 'assignment':
      return (
        <div>
          <AssignmentForm
            assignmentMetadataContentModel={assignmentMetadataContentModel}
            submit={() => {
              goToRubricForm();
              analyticsApi.logCreateAssignment(userId);
            }}
            goBack={goBack}
          />
          <GCAssignmentSelector
            assignmentMetadataContentModel={assignmentMetadataContentModel}
          />
          <AssignmentMetadataSelector
            container={container}
            assignmentMetadataContentModel={assignmentMetadataContentModel}
            editCallback={() => {
              goToEditAssignment();
              analyticsApi.logDuplicateAndEditAssignment(userId);
            }}
            reuseCallback={() => {
              goToUploadDirectDocuments();
              analyticsApi.logReuseAssignment(userId);
            }}
          />
        </div>
      );
    case 'rubric':
      return (
        <RubricForm
          assignmentMetadataContentModel={assignmentMetadataContentModel}
          submit={() => {
            goToUploadDocuments();
            analyticsApi.logCreateRubric(userId);
          }}
          goBack={() => {
            goToAssignmentForm();
            analyticsApi.logCreateRubricPrevious(userId);
          }}
        />
      );
    case 'upload':
      return (
        <ManageNewSubmissionsView
          assignmentMetadataContentModel={assignmentMetadataContentModel}
          onClickPrevious={() => {
            goToRubricForm();
            analyticsApi.logUploadStudentSubmissionsPrevious(userId);
          }}
          onCreateGradingJob={() => {
            postUpload();
            analyticsApi.logUploadStudentSubmissions(userId);
          }}
        />
      );
    case 'upload-direct':
      return (
        <ManageNewSubmissionsView
          assignmentMetadataContentModel={assignmentMetadataContentModel}
          onClickPrevious={() => {
            goToAssignmentForm();
            analyticsApi.logUploadStudentSubmissionsPrevious(userId);
          }}
          onCreateGradingJob={() => {
            postUpload();
            analyticsApi.logUploadStudentSubmissions(userId);
          }}
        />
      );
    default:
      return null; // Or some error component
  }
};

export default AssignmentCreationPage;
