import React, { ReactElement } from 'react';
import { RubricGrading } from '../../Types';
import {
  TextSegment,
  generateHighlights,
  generateTextSegments,
} from './reviewUtils';

function generateElementsFromSegments(segments: TextSegment[]): ReactElement[] {
  const elements: ReactElement[] = [];

  for (const segment of segments) {
    const lines = segment.text.split('\n');
    lines.forEach((line, i) => {
      if (segment.colors.length > 0) {
        // If there are multiple colors, you could concatenate them, or decide how to handle them
        const colorClass = segment.colors[0];
        elements.push(
          <span
            key={`${segment.index}-${colorClass}-${i}`}
            className={colorClass}
          >
            {line}
          </span>
        );
      } else {
        elements.push(<span key={`${segment.index}-normal-${i}`}>{line}</span>);
      }
      if (i < lines.length - 1) {
        elements.push(<br key={`${segment.index}-br-${i}`} />);
      }
    });
  }

  return elements;
}

interface ReviewStudentSubmissionProps {
  filename: string;
  studentSubmission: string;
  rubricGrading: RubricGrading;
}

const ReviewStudentSubmission: React.FC<ReviewStudentSubmissionProps> = ({
  filename,
  studentSubmission,
  rubricGrading,
}) => {
  const highlights = generateHighlights(studentSubmission, rubricGrading);
  const textSegments = generateTextSegments(studentSubmission, highlights);
  const highlightedElements = generateElementsFromSegments(textSegments);

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">
        <h2 className="text-3xl my-4">File: {filename}</h2>
        <p className="leading-7 mb-4">{highlightedElements}</p>
      </div>
    </div>
  );
};

export default ReviewStudentSubmission;
