import { AssignmentMetadataContentV1 } from '../../Types';
import { createRubric } from '../assignments/createRubric';

export function createAssignmentMetadataContent(): AssignmentMetadataContentV1 {
  return new AssignmentMetadataContentV1(
    '', // department: string,
    '', // teacher_type: string,
    '', // school_type: string,
    '', // course: string,
    '', // question_background: string,
    '', // question: string,
    [createRubric()] // rubric: Rubric[],
  );
}
