import React, { FC } from 'react';
import sample from '../../images/hero/sample.png';

const Sample: FC = () => {
  return (
    <>
      <div className="flex flex-col text-center py-3 sm:text-left sm:flex-row sm:py-8 sm:mx-12 sm:justify-around">
        <h1 className="font-suranna py-3 text-4xl sm:py-0 sm:w-1/4 sm:text-7xl sm:px-23">
          Owler's review screen makes scoring and feedback efficient
        </h1>
        <img className="self-center px-12 sm:w-2/3" src={sample} />
      </div>
    </>
  );
};

export default Sample;
