import React, { FC, useState, useEffect } from 'react';
import HorizontalDivider from '../components/hero/HorizontalDivider';
import HeroNav from '../components/hero/HeroNav';
import HeroDisplay from '../components/hero/HeroDisplay';
import StatsBanner from '../components/hero/StatsBanner';
import Benefits from '../components/hero/Benefits';
import Testimonials from '../components/hero/Testimonials';
import Steps from '../components/hero/Steps';
import Sample from '../components/hero/Sample';
import WhyOwler from '../components/hero/WhyOwler';

const Hero: FC = () => {
  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <header className="relative inset-x-0 top-0 z-50">
        <HeroNav />
      </header>
      <HeroDisplay />
      <StatsBanner />
      <Benefits />
      <Testimonials />
      <Steps />
      <Sample />
      <WhyOwler />
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      ></div>
    </div>
  );
};

export default Hero;
