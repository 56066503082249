import { FirebaseApp } from 'firebase/app';
import { Container } from './Container';
import createMockContainerFactory from './MockContainerFactory';
import createFirebaseContainerFactory from './FirebaseContainerFactory';

const createContainer = (firebaseApp: FirebaseApp): Container => {
  return import.meta.env.MODE === 'mock'
    ? createMockContainerFactory()
    : createFirebaseContainerFactory(firebaseApp);
};

export { createContainer };
