import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../components/dashboard/Dashboard';
import { Container } from '../../dependencyInjection/Container';
import AssignmentCreationPage from '../../components/assignments/AssignmentCreationPage';
import { AssignmentMetadataContentModel } from '../../components/AssignmentMetadata/AssignmentMetadataContentModel';

type CreateAssignmentViewProps = {
  container: Container;
};

const CreateAssignmentView: FC<CreateAssignmentViewProps> = ({ container }) => {
  const navigate = useNavigate();
  const assignmentMetadataContentModel =
    AssignmentMetadataContentModel.create();
  return (
    <Dashboard
      currentNavigation="Create Assignment"
      children={
        <div className="space-y-8">
          <div className="rounded-lg bg-white border shadow border-gray-200">
            <div className="px-4 py-5 sm:p-6">
              <AssignmentCreationPage
                container={container}
                assignmentMetadataContentModel={assignmentMetadataContentModel}
                postUpload={() => navigate('/assignments')}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CreateAssignmentView;
