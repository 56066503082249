import React from 'react';

type ListElementHeaderProps = {
  text: string;
  children?: React.ReactNode; // Adding a children prop to accept additional elements
};

const ListElementHeader: React.FC<ListElementHeaderProps> = ({
  text,
  children,
}) => {
  return (
    <div className="flex items-start gap-x-3 justify-between">
      <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
        {text}
      </p>
      {children}
    </div>
  );
};

export default ListElementHeader;
