import { FirebaseFileDownloader } from '../../FirebaseFileDownloader';
import { AssignmentMetadataContentV1 } from '../../Types';

export interface AssignmentMetadataContentProvider {
  getByPath(path?: string): Promise<AssignmentMetadataContentV1 | undefined>;
}

export class FirebaseAssignmentMetadataContentProvider
  implements AssignmentMetadataContentProvider
{
  constructor(
    private readonly _firebaseFileDownloader: FirebaseFileDownloader
  ) {}

  async getByPath(
    path?: string
  ): Promise<AssignmentMetadataContentV1 | undefined> {
    if (!path) {
      return undefined;
    }

    const rawMetadataContent =
      await this._firebaseFileDownloader.downloadFileAsText(path);
    const assignmentMetadataContent: AssignmentMetadataContentV1 =
      JSON.parse(rawMetadataContent);

    return assignmentMetadataContent;
  }
}
