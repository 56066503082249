import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import { Container } from '../../dependencyInjection/Container';
import AssignmentMetadataInfoList from '../AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfoList';

interface AssignmentMetadataSelectorProps {
  container: Container;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  reuseCallback: () => void;
  editCallback: () => void;
}

const AssignmentMetadataSelector: React.FC<AssignmentMetadataSelectorProps> = ({
  container,
  assignmentMetadataContentModel,
  reuseCallback,
  editCallback,
}) => {
  return (
    <>
      <div className="mx-auto max-w-5xl p-6 space-y-6 bg-white rounded-md">
        <h1 className="text-xl font-semibold">Select Existing Assignment</h1>
        <p>
          Below you can pick an existing assignment to reuse. When you select an
          assignment, the form above will be populated with the content, and you
          can use the existing assignment as is or edit the existing data to
          create a modified version. If you don't see the assignment you're
          looking for, create a new one above.
        </p>
        <AssignmentMetadataInfoList
          container={container}
          assignmentMetadataContentModel={assignmentMetadataContentModel}
          reuseCallback={reuseCallback}
          editCallback={editCallback}
        />
      </div>
    </>
  );
};

export default AssignmentMetadataSelector;
