import React, { FC } from 'react';
import HeroNav from '../../components/hero/HeroNav';
import TermsOfService from '../../components/termsOfService/TermsOfService';

type TermsOfServiceUnauthenticatedViewProps = {};

const TermsOfServiceUnauthenticatedView: FC<
  TermsOfServiceUnauthenticatedViewProps
> = () => {
  return (
    <>
      <HeroNav />
      <div className="overflow-hidden rounded-lg bg-white shadow border border-gray-200">
        <div className="px-4 py-5 sm:p-6">
          <TermsOfService />
        </div>
      </div>
    </>
  );
};

export default TermsOfServiceUnauthenticatedView;
