import React, { ReactNode, useEffect, useState } from 'react';
import { Container } from '../../dependencyInjection/Container';
import { useAssignmentById } from './hooks/useAssignmentById';
import { useSubmissions } from './hooks/useSubmissions';
import { ReviewDetail } from '../review/ReviewDetail';
import { useSearchParams } from 'react-router-dom';
import { useApi } from '../../contexts/ApiProvider';

type AssignmentViewProps = {
  container: Container;
  getAssignmentId: () => string;
};

const AssignmentView: React.FC<AssignmentViewProps> = ({
  container,
  getAssignmentId,
}) => {
  const assignment = useAssignmentById(
    getAssignmentId(),
    container.assignmentProvider
  );
  const submissions = useSubmissions(container.submissionsProvider, assignment);

  const { api, analyticsApi } = useApi();

  const [searchParam, setSearchParams] = useSearchParams();

  const fileName = searchParam.get('fileName');

  useEffect(() => {
    if (fileName === null && submissions && submissions.length > 0) {
      setSearchParams({ fileName: submissions.at(0)!.fileName });
    }
  }, [fileName, submissions]);

  if (!assignment) {
    return <div>loading assignment...</div>;
  }

  if (!submissions) {
    return <div>loading submissions...</div>;
  }

  if (submissions.length === 0) {
    return <div>nothing here :/</div>;
  }

  if (!fileName) {
    return <div>no file selected</div>;
  }

  const currentIndex = submissions.findIndex(
    (submission) => submission.fileName === fileName
  );
  const isFirstSubmission = currentIndex === 0;
  const isLastSubmission = currentIndex === submissions.length - 1;
  const currentSubmission = submissions[currentIndex];

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        const userId = api.getCurrentUserId();
        analyticsApi.logAssignmentViewCopyShareableLink(userId);
      },
      (err) => {
        console.error('Failed to copy: ', err);
      }
    );
  };

  const navigateAndUpdate = (navigateBy: -1 | 1) => {
    const nextFileName = submissions.at(currentIndex + navigateBy)?.fileName;
    if (nextFileName) {
      setSearchParams({ fileName: nextFileName });
      const userId = api.getCurrentUserId();
      if (navigateBy === -1) {
        analyticsApi.logAssignmentViewPrevious(userId);
      } else {
        analyticsApi.logAssignmentViewNext(userId);
      }
    }
  };

  const navigationButton = (
    text: string,
    disabled: boolean,
    navigateBy: -1 | 1
  ): ReactNode => {
    return (
      <button
        onClick={() => {
          navigateAndUpdate(navigateBy);
        }}
        className={disabled ? 'owler-disabled-button' : 'owler-indigo-button'}
        disabled={disabled}
      >
        {text}
      </button>
    );
  };

  const exportButton = (): ReactNode => {
    const exportUrl = `https://owlerai.com/export/${assignment.assignment_id}/${currentSubmission.submissionId}?token=${currentSubmission.token}`;

    return (
      <button
        onClick={() => copyToClipboard(exportUrl)}
        className="owler-indigo-button"
      >
        Copy Shareable Link
      </button>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="overflow-hidden rounded-lg bg-white flex flex-col h-full">
        <div className="flex justify-between px-6 py-4">
          {navigationButton('Previous', isFirstSubmission, -1)}
          <span>
            Submission {currentIndex + 1} of {submissions.length}
          </span>
          <div className="flex">
            {currentSubmission &&
              currentSubmission.submissionId &&
              currentSubmission.token && (
                <div className="mx-2">{exportButton()}</div>
              )}
            {navigationButton('Next', isLastSubmission, +1)}
          </div>
        </div>
        <ReviewDetail
          outputPath={fileName}
          assignment={assignment}
          submissionId={
            currentSubmission ? currentSubmission.submissionId : undefined
          }
        />
      </div>
    </div>
  );
};

export default AssignmentView;
