import React from 'react';
import {
  CheckIcon,
  HandThumbUpIcon,
  PencilSquareIcon,
  DocumentCheckIcon,
  ChatBubbleLeftIcon,
} from '@heroicons/react/20/solid';

const timeline = [
  {
    id: 1,
    content: 'Assignment submitted by',
    target: 'Alex Johnson',
    href: '#',
    date: 'Feb 10',
    datetime: '2021-02-10',
    icon: PencilSquareIcon,
    iconBackground: 'bg-yellow-400',
  },
  {
    id: 2,
    content: 'Assignment graded for',
    target: 'Emma Williams',
    href: '#',
    date: 'Feb 12',
    datetime: '2021-02-12',
    icon: DocumentCheckIcon,
    iconBackground: 'bg-green-500',
  },
  {
    id: 3,
    content: 'Feedback provided to',
    target: 'Jacob Smith',
    href: '#',
    date: 'Feb 14',
    datetime: '2021-02-14',
    icon: ChatBubbleLeftIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 4,
    content: 'Resubmission requested for',
    target: 'Olivia Brown',
    href: '#',
    date: 'Feb 16',
    datetime: '2021-02-16',
    icon: HandThumbUpIcon,
    iconBackground: 'bg-orange-500',
  },
  {
    id: 5,
    content: 'Final grade posted for',
    target: 'Michael Davis',
    href: '#',
    date: 'Feb 18',
    datetime: '2021-02-18',
    icon: CheckIcon,
    iconBackground: 'bg-purple-600',
  },
];

type TimelineEventProps = {
  content: string;
  target: string;
  href: string;
  date: string;
  datetime: string;
  Icon: any;
  iconBackground: string;
  isLastItem: boolean;
};

// Timeline event component
const TimelineEvent: React.FC<TimelineEventProps> = ({
  content,
  target,
  href,
  date,
  datetime,
  Icon,
  iconBackground,
  isLastItem,
}) => {
  return (
    <li>
      <div className="relative pb-8">
        {!isLastItem && (
          <span
            className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex items-start space-x-3">
          <TimelineIcon iconBackground={iconBackground} Icon={Icon} />
          <div className="min-w-0 flex-1 pt-1.5">
            <p className="text-sm text-gray-500">
              {content}{' '}
              <a href={href} className="font-medium text-gray-900">
                {target}
              </a>
            </p>
            <p className="whitespace-nowrap text-right text-sm text-gray-500">
              <time dateTime={datetime}>{date}</time>
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

// Icon component for the timeline
interface TimelineIconProps {
  iconBackground: string;
  Icon: any;
}

const TimelineIcon: React.FC<TimelineIconProps> = ({
  iconBackground,
  Icon,
}) => {
  return (
    <div>
      <span
        className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${iconBackground}`}
      >
        <Icon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    </div>
  );
};

// Main component to render the timeline
const ActivityFeed: React.FC = () => {
  // We should activity/event information here and prop it down to the other components
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          Activity Feed
        </h3>
        <ul role="list" className="flow-root mt-4">
          {timeline.map((event, eventIdx) => (
            <TimelineEvent
              key={event.id}
              content={event.content}
              target={event.target}
              href={event.href}
              date={event.date}
              datetime={event.datetime}
              Icon={event.icon}
              iconBackground={event.iconBackground}
              isLastItem={eventIdx === timeline.length - 1}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ActivityFeed;
