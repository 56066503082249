import React, { FC } from 'react';
import StatsCard from './StatsCard';

const stats = [
  {
    num: '45',
    txt: 'seconds to grade an essay',
  },
  {
    num: '20,000',
    txt: 'essays graded to date',
  },
  {
    num: '300',
    txt: 'schools across the country',
  },
];

const StatsBanner: FC = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row justify-around text-center px-2 py-16 max-w-9xl bg-indigo-200">
        {stats.map((stat, index) => (
          <StatsCard key={index} num={stat.num} txt={stat.txt} />
        ))}
      </div>
    </>
  );
};

export default StatsBanner;
