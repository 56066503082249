import React from 'react';

import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

const mockAssignments = [
  {
    name: 'Personal Narrative Essay',
    initials: 'PNE',
    href: '#',
    submissions: 28,
    bgColor: 'bg-blue-300',
    dueDate: 'Due Oct 15',
  },
  {
    name: 'Research Project: Climate Change',
    initials: 'RP',
    href: '#',
    submissions: 20,
    bgColor: 'bg-green-300',
    dueDate: 'Due Nov 1',
  },
  {
    name: 'Book Analysis: To Kill a Mockingbird',
    initials: 'BA',
    href: '#',
    submissions: 18,
    bgColor: 'bg-red-300',
    dueDate: 'Due Oct 22',
  },
  {
    name: 'Poetry Compilation',
    initials: 'PC',
    href: '#',
    submissions: 22,
    bgColor: 'bg-yellow-300',
    dueDate: 'Due Nov 12',
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

interface AssignmentsProps {
  uid: string;
}

const Assignments = ({ uid }: AssignmentsProps) => {
  // We should fetch assignments here and prop it to assignment item component
  const assignments = mockAssignments;

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-semibold leading-6 text-gray-900">
          Assignments
        </h3>
        <div>
          <ul
            role="list"
            className={
              /* "mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4" */
              'mt-3 grid grid-cols-1 gap-5'
            }
          >
            {assignments.map((assignment) => (
              <li
                key={assignment.name}
                className="col-span-1 flex rounded-md shadow-sm"
              >
                <AssignmentItem {...assignment} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

interface AssignmentItemProps {
  name: string;
  initials: string;
  href: string;
  members?: number;
  bgColor: string;
}

const AssignmentItem = ({
  name,
  initials,
  href,
  members,
  bgColor,
}: AssignmentItemProps) => {
  return (
    <>
      <div
        className={classNames(
          bgColor,
          'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-black'
        )}
      >
        {initials}
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <a
            href={href}
            className="font-medium text-gray-900 hover:text-gray-600"
          >
            {name}
          </a>
          <p className="text-gray-500">{members} Members</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          <button
            type="button"
            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Assignments;
