import { useEffect, useState } from 'react';
import { AssignmentStatusProvider } from '../AssignmentList/AssignmentStatusProvider';
import { GradingStatus } from '../AssignmentList/GradingStatus';
import { Assignment } from '../../../Types';

function useGradingStatus(
  assignmentStatusProvider: AssignmentStatusProvider,
  assignment?: Assignment
) {
  const [gradingStatus, setGradingStatus] = useState<GradingStatus | undefined>(
    undefined
  );

  useEffect(() => {
    const gradingJobId = assignment?.grading_job_id;
    if (!gradingJobId) return;

    const unsubscribeFromStatusUpdates = assignmentStatusProvider.subscribe(
      gradingJobId,
      (status: GradingStatus) => {
        setGradingStatus(status);

        if (status.doneRunning && unsubscribeFromStatusUpdates) {
          unsubscribeFromStatusUpdates();
        }
      }
    );

    return () => {
      unsubscribeFromStatusUpdates();
    };
  }, [assignment]);

  return gradingStatus;
}

export { useGradingStatus };
