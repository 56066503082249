import React, { ReactNode } from 'react';
import Footer from './components/footer/Footer';
import { usePageViews } from './analytics/usePageViews';

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps): JSX.Element {
  usePageViews();
  return (
    <div className="flex flex-col min-h-screen w-full">
      {/* Todo: Come back here and figure out how to add back the header? */}
      {/* <Header /> */}
      <main className="flex-grow">{children}</main>
      <div className="mt-auto z-100">
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
