import { useState, useEffect } from 'react';
import { useApi } from '../contexts/ApiProvider';
import { GradingResult, Assignment, CloudApi } from '../Types';
import { parseGradingJson, convertJsonGradingOutputToGradingResult } from '.';
import { useQuery } from '@tanstack/react-query';

async function fetchSingleGradingResultByPath(
  api: CloudApi,
  assignment: Assignment,
  outputFileName: string
): Promise<GradingResult> {
  const userId = api.getCurrentUserId();
  const folderPath = api.getOutputPath(userId, assignment.grading_job_id);
  const filePath = folderPath + '/' + outputFileName;

  const fileContent = await api.downloadFileAsText(filePath);
  const gradingResult = parseGradingJson(fileContent, outputFileName);

  if (!gradingResult.inputPath) {
    throw new Error('The grading result does not contain an input path.');
  }

  const inputFileContent = await api.downloadFileAsText(
    gradingResult.inputPath
  );
  gradingResult.inputFileContent = inputFileContent;
  gradingResult.outputFileName = outputFileName; // TODO: THIS SUCKS - FIX

  return gradingResult;
}

async function fetchSingleGradingResultByAssignment(
  api: CloudApi,
  assignment: Assignment,
  submissionId: string
): Promise<GradingResult | null> {
  const assignmentId = assignment.assignment_id;
  const gradingJsonData = await api.getGradingOutput(
    assignmentId,
    submissionId
  );
  if (!gradingJsonData) {
    return null;
  }
  const gradingResult =
    convertJsonGradingOutputToGradingResult(gradingJsonData);
  return gradingResult;
}

const useFetchSingleGradingResult = (
  assignment?: Assignment,
  outputPath?: string | null,
  submissionId?: string | null
) => {
  const { api } = useApi();

  const {
    data: gradingResultByAssignment,
    isLoading: isLoadingByAssignment,
    isFetching: isFetchingByAssignment,
    error: errorByAssignment,
  } = useQuery<GradingResult | null, Error>({
    queryKey: [
      'gradingResultByAssignment',
      assignment?.assignment_id,
      submissionId,
    ],
    queryFn: async () => {
      if (assignment && submissionId) {
        return await fetchSingleGradingResultByAssignment(
          api,
          assignment,
          submissionId
        );
      }
      return null;
    },
    staleTime: 0, // always fetch the latest data because the grading result could have been edited
  });

  const {
    data: gradingResultByPath,
    isLoading: isLoadingByPath,
    isFetching: isFetchingByPath,
    error: errorByPath,
  } = useQuery<GradingResult, Error>({
    queryKey: ['gradingResultByPath', assignment?.assignment_id, outputPath],
    queryFn: async () => {
      if (assignment && assignment.grading_job_id && outputPath) {
        return await fetchSingleGradingResultByPath(
          api,
          assignment,
          outputPath
        );
      }
      throw new Error('Assignment or output path is missing.');
    },
  });

  const gradingResult = gradingResultByAssignment || gradingResultByPath;
  const isLoading = isLoadingByAssignment || isLoadingByPath;
  const isFetching = isFetchingByAssignment || isFetchingByPath;
  const error = errorByAssignment || errorByPath;

  return { gradingResult, isLoading, isFetching, error };
};

export { useFetchSingleGradingResult };
