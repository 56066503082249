import React, {
  FC,
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';
import { FirebaseApi } from '../FirebaseApi';
import { SegmentService } from '../analytics/SegmentService';
import { SegmentAnalyticsApi } from '../analytics/SegmentAanlyticsApi';
import { ApiContextType, ApiProviderProps } from '../Types';

const ApiContext = createContext<ApiContextType | undefined>(undefined);

const firebaseApi = new FirebaseApi();
SegmentService.initialize();
const segmentAnalyticsApi = new SegmentAnalyticsApi();

const ApiProvider: FC<ApiProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebaseApi.getAuth().onAuthStateChanged((user) => {
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const contextValue: ApiContextType = {
    api: firebaseApi,
    analyticsApi: segmentAnalyticsApi,
    loading, // Include loading state in the context
  };

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

const useApi = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};

export { ApiProvider, useApi };
