import { FirebaseFileDownloader } from '../../../FirebaseFileDownloader';
import { AssignmentReference } from './AssignmentReference';
import { Assignment } from '../../../Types';
import { AssignmentsProvider } from './AssignmentsProvider';

export interface AssignmentProvider {
  getByAssignmentReference(
    assignmentReference: AssignmentReference
  ): Promise<Assignment>;
  getById(id: string): Promise<Assignment>;
}

export class FirebaseAssignmentProvider implements AssignmentProvider {
  constructor(
    private readonly _firebaseFileDownloader: FirebaseFileDownloader,
    private readonly _assignmentsProvider: AssignmentsProvider
  ) {}

  async getById(id: string): Promise<Assignment> {
    const assignments = await this._assignmentsProvider.get();

    const assignmentReferences = assignments.filter(
      (a) => a.assignment_id === id
    );
    if (assignmentReferences.length == 0) {
      throw new Error(`no assignment with id ${id} found`);
    }

    return await this.getByAssignmentReference(assignmentReferences[0]);
  }

  async getByAssignmentReference(
    assignmentReference: AssignmentReference
  ): Promise<Assignment> {
    const rawMetadataContent =
      await this._firebaseFileDownloader.downloadFileAsText(
        assignmentReference.assignment_metadata_info.assignment_metadata_path
      );
    const metadataContent = JSON.parse(rawMetadataContent);

    const assignment: Assignment = {
      assignment_id: assignmentReference.assignment_id,
      assignment_metadata_info: assignmentReference.assignment_metadata_info,
      metadata_content: metadataContent,
      created_at: assignmentReference.created_at,
      grading_job_id: assignmentReference.grading_job_id,
      conversion_job_id: assignmentReference.conversion_job_id,
    };

    return assignment;
  }
}
