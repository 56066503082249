import { SVGProps, ForwardRefExoticComponent, RefAttributes } from 'react';
import {
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  BookOpenIcon,
  ArrowLeftOnRectangleIcon,
  DocumentArrowUpIcon,
  FolderIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/24/outline';

///////////////////
// FEATURE FLAGS //
///////////////////

// @ts-ignore: error TS2339: Property 'env' does not exist on type 'ImportMeta'.
export const isProfileEnabled = import.meta.env.VITE_ENABLE_PROFILE === 'true';
export const isProduction = import.meta.env.MODE === 'production';

///////////
// OTHER //
///////////

export const assignmentMetadataVersion = 'v0';
export const MAX_FILES = 40;

interface DashboardNavigationItem {
  name: string;
  href: string;
  icon: ForwardRefExoticComponent<
    SVGProps<SVGSVGElement> & RefAttributes<SVGSVGElement>
  >;
  current: boolean;
}

export const dashboardNavigation: (DashboardNavigationItem | null)[] = [
  // { name: "Dashboard", href: "/dashboard", icon: HomeIcon, current: false },
  {
    name: 'Assignments',
    href: '/assignments',
    icon: BookOpenIcon,
    current: false,
  },
  {
    name: 'Create Assignment',
    href: '/create-assignment',
    icon: DocumentArrowUpIcon,
    current: false,
  },
  // {
  //   name: 'Pricing',
  //   href: '/pricing',
  //   icon: CurrencyDollarIcon,
  //   current: false,
  // },
  // {
  //   name: "Review",
  //   href: "/review",
  //   icon: DocumentDuplicateIcon,
  //   current: false,
  // },
  // { name: "Reports", href: "/reports", icon: ChartPieIcon, current: false },
  isProfileEnabled
    ? {
        name: 'Profile',
        href: '/profile',
        icon: UsersIcon, // Replace with your actual icon component
        current: false,
      }
    : null,
  {
    name: 'Sign Out',
    href: '/logout',
    icon: ArrowLeftOnRectangleIcon,
    current: false,
  },
].filter(Boolean);

export const usersCollection: string = 'users';
export const jobsCollection: string = 'jobs';
export const assignmentsCollection: string = 'assignments';
export const assignmentMetadataCollection: string = 'assignment_metadata';

export const highlightColors = [
  'bg-yellow-200',
  'bg-blue-200',
  'bg-green-200',
  'bg-pink-200',
  'bg-indigo-200',
  'bg-red-200',
  'bg-purple-200',
  'bg-teal-200',
  'bg-orange-200',
  'bg-yellow-300',
  'bg-blue-300',
  'bg-green-300',
  'bg-pink-300',
  'bg-indigo-300',
  'bg-red-300',
  'bg-purple-300',
  'bg-teal-300',
  'bg-orange-300',
  //... You can extend this list further if needed
];
