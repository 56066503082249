import React, { FC } from 'react';
import HeroNav from '../components/hero/HeroNav';
import AboutUs from '../components/about/AboutUs';
import BigStatement from '../components/about/BigStatement';

const About: FC = () => {
  return (
    <>
      <div>
        <HeroNav />
        <AboutUs />
        <BigStatement />
      </div>
    </>
  );
};

export default About;
