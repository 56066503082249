import { useState } from 'react';
import { AssignmentMetadataContentV1, Rubric } from '../../Types';
import { createRubric } from '../assignments/createRubric';
import { AssignmentMetadataInfo } from './AssignmentMetadataInfo/AssignmentMetadataInfo';
import { createAssignmentMetadataContent } from './createAssignmentMetadataContent';

export class AssignmentMetadataContentModel {
  constructor(
    public readonly AssignmentMetadataContent: AssignmentMetadataContentV1,
    private readonly _setAssignmentMetadataContent: (
      assignmentMetadataContent: AssignmentMetadataContentV1
    ) => void,
    public readonly OriginalAssignmentMetadataContent: AssignmentMetadataContentV1,
    private readonly _setOriginalAssignmentMetadataContent: (
      assignmentMetadataContent: AssignmentMetadataContentV1
    ) => void,
    public readonly AssignmentMetadataInfo: AssignmentMetadataInfo,
    private readonly _setAssignmentMetadataInfo: (
      assignmentMetadataInfo: AssignmentMetadataInfo
    ) => void
  ) {}

  setRubrics(rubrics: Rubric[]) {
    this._setAssignmentMetadataContent({
      ...this.AssignmentMetadataContent,
      rubric: rubrics,
    });
  }

  setValueForKey(value: string, name: string) {
    this._setAssignmentMetadataContent({
      ...this.AssignmentMetadataContent,
      [name]: value,
    });
  }

  addRubric() {
    const newRubrics = [...this.AssignmentMetadataContent.rubric];

    newRubrics.push(createRubric());

    this.setRubrics(newRubrics);
  }

  removeRubric(rubric: Rubric) {
    const oldRubrics = [...this.AssignmentMetadataContent.rubric];
    const newRubrics = oldRubrics.filter((r) => r.id !== rubric.id);

    this.setRubrics(newRubrics);
  }

  updateRubric(rubric: Rubric): void {
    const oldRubrics = [...this.AssignmentMetadataContent.rubric];
    const newRubrics = oldRubrics.map((r) => (r.id === rubric.id ? rubric : r));

    this.setRubrics(newRubrics);
  }

  setAssignmentMetadataContent(
    assignmentMetadataContent: AssignmentMetadataContentV1
  ) {
    this._setAssignmentMetadataContent(assignmentMetadataContent);
  }

  setOriginalAssignmentMetadataContent(
    assignmentMetadataContent: AssignmentMetadataContentV1
  ) {
    this._setOriginalAssignmentMetadataContent(assignmentMetadataContent);
  }

  setAssignmentMetadataInfo(assignmentMetadataInfo: AssignmentMetadataInfo) {
    this._setAssignmentMetadataInfo(assignmentMetadataInfo);
  }

  reset() {
    this.setAssignmentMetadataContent(createAssignmentMetadataContent());
    this.setOriginalAssignmentMetadataContent(
      createAssignmentMetadataContent()
    );
    this.setAssignmentMetadataInfo({
      assignment_metadata_id: '',
      assignment_metadata_path: '',
    });
  }

  static create(
    assignmentMetadataContentArg: AssignmentMetadataContentV1 = createAssignmentMetadataContent()
  ) {
    const [assignmentMetadataContent, setAssignmentMetadataContent] =
      useState<AssignmentMetadataContentV1>(assignmentMetadataContentArg);
    const [
      originalAssignmentMetadataContent,
      setOriginalAssignmentMetadataContent,
    ] = useState<AssignmentMetadataContentV1>(assignmentMetadataContentArg);
    const [assignmentMetadataInfo, setAssignmentMetadataInfo] =
      useState<AssignmentMetadataInfo>({
        assignment_metadata_id: '',
        assignment_metadata_path: '',
      });
    return new AssignmentMetadataContentModel(
      assignmentMetadataContent,
      setAssignmentMetadataContent,
      originalAssignmentMetadataContent,
      setOriginalAssignmentMetadataContent,
      assignmentMetadataInfo,
      setAssignmentMetadataInfo
    );
  }
}
