import { Rubric } from '../../Types';
import { createUUID } from './createUUID';

export function createRubric(): Rubric {
  return {
    id: createUUID(),
    name: '',
    description: '',
    points_possible: '',
  };
}
