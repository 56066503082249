import React from 'react';
import { FC } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import ReviewDetailView from './views/review/ReviewDetailView';
import LogoutView from './views/logout/LogoutView';
import AssignmentsView from './views/assignments/AssignmentsView';
import CreateAssignmentView from './views/assignments/CreateAssignmentView';
import { Container } from './dependencyInjection/Container';
import ProfileView from './views/profile/RootProfileView';
import PricingView from './views/pricing/PricingView';
import PrivacyPolicyView from './views/privacyPolicy/PrivacyPolicyView';
import TermsOfServiceView from './views/termsOfService/TermsOfServiceView';
import { SubmissionViewerView } from './views/viewer/submission/SubmissionViewerView';
import Contact from './views/Contact';
import About from './views/About';
import { GradingResultProvider } from './components/review/GradingResultContext';

type AuthenticatedRoutesProps = {
  container: Container;
};

export const AuthenticatedRoutes: FC<AuthenticatedRoutesProps> = ({
  container,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/assignments" />} />
      <Route path="profile" element={<ProfileView container={container} />} />
      <Route
        path="assignments"
        element={<AssignmentsView container={container} />}
      />
      <Route
        path="assignments/:id"
        element={
          <ReviewDetailView
            container={container}
            getAssignmentId={() => {
              return useParams<{ id: string }>().id!;
            }}
          />
        }
      />
      <Route path="logout" element={<LogoutView />} />
      <Route path="*" element={<Navigate to="/" />} />
      <Route
        path="/create-assignment"
        element={<CreateAssignmentView container={container} />}
      />
      {/* <Route path="pricing" element={<PricingView />} /> */}
      <Route path="about" element={<About />} />
      <Route path="privacy" element={<PrivacyPolicyView />} />
      <Route path="terms" element={<TermsOfServiceView />} />
      <Route path="contact" element={<Contact />} />
      <Route
        path="export/:assignmentId/:submissionId"
        element={
          <GradingResultProvider>
            <SubmissionViewerView />
          </GradingResultProvider>
        }
      />
    </Routes>
  );
};

export default AuthenticatedRoutes;
