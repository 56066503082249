import React, { FC } from 'react';
import Step1 from '../../images/hero/step1.png';
import Step2 from '../../images/hero/step2.png';
import Step3 from '../../images/hero/step3.png';
import scribble from '../../images/hero/scribble_steps.svg';

// Create a FeatureBlock component
const Steps: FC = () => {
  return (
    <div className="flex-grow bg-gradient-to-b from-indigo-50 to-indigo-200 flex flex-col items-center py-8 px-4 text-xl md:text-6xl font-suranna">
      <div className="flex flex-col w-3/4 self-center max-w-6xl">
        <h2 className="self-start">
          in <span className="md:text-8xl">3</span> simple steps leave each
          student
        </h2>
        <h2 className="self-end">
          <span className="relative z-30 ml-[-30px]">
            <span className="inline-flex flex-col items-center">
              personalized
              <img className="h-4 sm:h-8" src={scribble} />
            </span>
          </span>{' '}
          feedback in minutes
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-around mt-6">
        <img className="px-5 py-3 md:py-0 object-scale-down h-80" src={Step1} />
        <img className="px-5 py-3 md:py-0 object-scale-down h-80" src={Step2} />
        <img className="px-5 py-3 md:py-0 object-scale-down h-80" src={Step3} />
      </div>
    </div>
  );
};

export default Steps;
