import React from 'react';
import { ArrowRightIcon, PencilIcon } from '@heroicons/react/24/solid';

type AssignmentSelectionButtonsProps = {
  editCallback?: () => void;
  reuseCallback: () => void;
  editDescription?: string;
  reuseDescription?: string;
};

const AssignmentSelectionButtons: React.FC<AssignmentSelectionButtonsProps> = ({
  editCallback,
  reuseCallback,
  editDescription = 'Duplicate & Edit Assignment',
  reuseDescription = 'Reuse Assignment',
}) => {
  return (
    <div className="flex py-4 justify-end">
      {editCallback && (
        <button
          onClick={(event) => {
            // Prevents the event from reaching the parent, which would collapse the li and reset the assignmentMetadataContent
            event.stopPropagation();
            editCallback();
          }}
          className="owler-indigo-button mx-3"
        >
          {editDescription}
          <PencilIcon className="w-5 h-5 ml-2" />
        </button>
      )}
      <button
        onClick={(event) => {
          // Prevents the event from reaching the parent, which would collapse the li and reset the assignmentMetadataContent
          event.stopPropagation();
          reuseCallback();
        }}
        className="owler-indigo-button"
      >
        {reuseDescription}
        <ArrowRightIcon className="w-5 h-5 ml-2" />
      </button>
    </div>
  );
};

export default AssignmentSelectionButtons;
