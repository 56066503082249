import React, { FC } from 'react';
import demo from '../../images/hero/demo.mp4';
import scribble from '../../images/hero/scribble_fast.svg';

const HeroDisplay: FC = () => {
  return (
    <>
      <div className="relative isolate px-6 lg:px-8 z-0 sm:mb-10">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden sm:-top-80"
          aria-hidden="true"
        ></div>
        <div className="relative">
          <div className="my-6 mx-6 flex justify-between">
            <div className="">
              <div className="flex flex-col">
                <h1 className="sm:ml-3 text-5xl font-bold text-gray-900 sm:text-[88px]">
                  <span className="font-suranna">
                    Leave high-quality feedback and grade essays
                  </span>
                  <span className="font-fasthand text-indigo-600 relative z-30 ml-[-30px]">
                    <span className="inline-flex flex-col items-center">
                      fast
                      <img
                        className="h-4 sm:h-8"
                        src={scribble}
                        alt="scribble svg"
                      />
                    </span>
                  </span>
                </h1>
              </div>

              <p className="mt-6 text-md sm:text-md text-gray-600 sm:pr-24">
                Owler’s mission is to reduce teacher burnout and improve student
                outcomes.
                <br />
                We started by helping teachers review work and leave feedback
                faster, and more easily.
              </p>

              <div className="mt-10 flex">
                <a
                  href="/signup"
                  className="text-white shadow bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 rounded-3xl text-sm sm:text-lg px-8 py-4 text-center self-center font-bold"
                >
                  TRY IT FOR FREE
                </a>
              </div>
            </div>

            <div className="hidden sm:ml-10 sm:block my-7">
              <video
                className="rounded-lg border-4 border-indigo-500 shadow-lg shadow-indigo-600/50"
                autoPlay
                loop
                muted
              >
                <source src={demo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroDisplay;
