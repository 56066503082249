import React, { FC } from 'react';

const TermsOfService: FC = () => {
  return (
    <div className="max-w-md mx-auto m-10 bg-white overflow-hidden md:max-w-3xl">
      <div>
        <h2 className="text-center text-2xl font-semibold text-gray-900">
          Owler AI Terms of Service
        </h2>
        <p className="my-8 text-center text-gray-500">
          Last updated: February 20th, 2024
        </p>
        <p className="owler-p">
          <span className="font-bold">
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE. IF
            YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, PLEASE DO NOT
            ACCESS THIS SITE, USE THE SERVICES, PERMIT YOUR STUDENTS TO USE THE
            SERVICES, OR OTHERWISE INDICATE ACCEPTANCE OF THESE TERMS.
          </span>
        </p>
        <p className="owler-p">
          These Terms of Use ("<span className="font-bold">Terms</span>" or "
          <span className="font-bold">Terms of Use</span>") constitute an
          agreement between you ("<span className="font-bold">you</span>" or the
          "user") and Owler AI, Inc. and its affiliates, subsidiaries or assigns
          (collectively, "<span className="font-bold">Owler AI</span>," "
          <span className="font-bold">we</span>," "
          <span className="font-bold">us</span>" or "
          <span className="font-bold">our</span>"). This website, (the "
          <span className="font-bold">Site</span>"),{' '}
          <a className="owler-a" href="https://owlerai.com/">
            https://owlerai.com/
          </a>{' '}
          and our platform (the "<span className="font-bold">Platform</span>")
          are owned and operated by Owler AI. Owler AI allows you to access and
          use the Site and Platform, the information we provide, and any other
          services (together with the "<span className="font-bold">Site</span>"
          and the "<span className="font-bold">Platform</span>," the "
          <span className="font-bold">Services</span>") on the condition that
          you accept these Terms of Use.
        </p>
        <p className="owler-p">
          <span className="font-bold">ARBITRATION NOTICE</span>: EXCEPT FOR
          CERTAIN TYPES OF DISPUTES DESCRIBED IN THE "
          <span className="font-bold">
            ARBITRATION PROVISION AND CLASS ACTION WAIVER
          </span>
          " SECTION BELOW, AND UNLESS YOU OPT-OUT AS SET FORTH IN THAT SAME
          SECTION, YOU AGREE THAT DISPUTES BETWEEN YOU AND OWLER AI OR OTHER
          PARTIES DESCRIBED HEREIN WILL BE RESOLVED BY BINDING, INDIVIDUAL
          ARBITRATION AT THE ELECTION OF EITHER PARTY, AND YOU WAIVE YOUR RIGHT
          TO A JURY TRIAL AND YOUR RIGHT TO BRING OR RESOLVE ANY DISPUTE AS OR
          TO PARTICIPATE IN A CLASS, PRIVATE ATTORNEY GENERAL, REPRESENTATIVE,
          OR COLLECTIVE ACTION IN COURT OR IN ARBITRATION.
        </p>
        <p className="owler-p">
          We reserve the right at any time to change: (i) the terms and
          conditions of these Terms of Use; (ii) the Services, including
          terminating, eliminating, supplementing, modifying, adding or
          discontinuing any content or feature or data or service on or
          available through the Services or the hours that they are available;
          (iii) any fees or charges, if any, related to the use the Services;
          and (iv) the equipment, hardware or software required to use and
          access the Services.
        </p>
        <p className="owler-p">
          Any changes we make to these Terms of Use will be effective
          immediately upon posting on the Site. Please return to the Site
          periodically to ensure you are familiar with the most current version
          of these Terms of Use.
        </p>
        <h3 className="mt-8 font-bold">1. YOU AGREE TO OUR PRIVACY POLICY</h3>
        <p className="owler-p">
          Please review our{' '}
          <a className="owler-a" href="/privacy">
            Privacy Policy
          </a>
          . Our Privacy Policy is incorporated by reference in these Terms. By
          using the Services, you consent to and agree to be bound by the
          Privacy Policy.
        </p>
        <p className="owler-p">
          When you use the Services, you also consent to receive communications
          from us electronically via the Services, email, or otherwise. You
          agree to provide accurate, current, and complete information about
          yourself when you use the Services and to update such information
          while you continue to use the Services.
        </p>
        <h3 className="mt-8 font-bold">2. USE OF THE SERVICES</h3>
        <p className="owler-p">
          If you access this Site from outside of the United States, you do so
          at your own risk and are responsible for compliance with local,
          national, or international laws. In particular, you understand that
          this Site, the Services, or both may not be available in all countries
          and that you are responsible for ensuring that it is lawful for you to
          use this Site and receive the Services in your location.
        </p>
        <h3 className="mt-8 font-bold">3. ELIGIBILITY</h3>
        <p className="owler-p">
          You represent and warrant that you: (a) are above the legal age of
          majority in your jurisdiction of residence; (b) have not previously
          been suspended or removed from the Site or our Services; and (c) have
          full power and authority to enter into these Terms, and in doing so
          will not violate any other agreement to which you are a party.
        </p>
        <h3 className="mt-8 font-bold">
          4. PROHIBITION ON SUBMISSION OF PERSONAL DATA
        </h3>
        <p className="owler-p">
          You are expressly prohibited from submitting into the Platform any
          Personal Data. "
          <span className="font-bold">Data Protection Laws</span>" means any
          applicable international, national, federal, state, local, municipal,
          or territorial law, regulation, rule, guideline, guidance, or industry
          standard concerning or relating to data privacy, security, or breach
          notification, including, but not limited to the Children's Online
          Privacy Protection Act, the Student Online Personal Information
          Protection Act, and the Family Educational Rights and Privacy Act. "
          <span className="font-bold">Personal Data</span>" means (i)
          information that is linked or reasonably linkable to an identified or
          identifiable individual; or (ii) personal data, personal information,
          personally identifiable information, education records, or an
          equivalent term under Data Protection Laws. You are solely responsible
          for ensuring that all essays, work product, or other information
          submitted into the Platform does not contain Personal Data.
        </p>
        <h3 className="mt-8 font-bold">5. MOBILE DEVICES</h3>
        <p className="owler-p">
          You understand that wireless service through Wi-Fi or a participating
          mobile service provider may not be available in all areas at all times
          and may be affected by product, software, coverage, or other service
          changes made by your mobile service provider or otherwise. You agree
          that you are solely responsible for all message and data charges that
          apply to use of your mobile device to access the Services. All such
          charges are billed by and payable to your mobile service provider.
          Please contact your participating mobile service provider for pricing
          plans, participation status and details.
        </p>
        <h3 className="mt-8 font-bold">6. CODE OF CONDUCT</h3>
        <p className="owler-p">
          You agree that you will not attempt to, nor permit any third party to,
          enter restricted areas of Owler AI's computer systems or perform
          functions that these Terms do not authorize you to perform. You accept
          sole responsibility for all of your activities using the Services. You
          may not use the Services in a manner that:
          <ul className="owler-p list-[lower-alpha] pl-7">
            <li className="py-1">
              commits or attempts to commit tax fraud or any other violation of
              applicable tax laws, regulations, rules, or codes;
            </li>
            <li className="py-1">
              harasses, abuses, bullies, stalks, threatens, defames, or
              otherwise infringes or violates the rights of any party (including
              but not limited to rights of publicity or other proprietary
              rights);
            </li>
            <li className="py-1">is unlawful, fraudulent, or deceptive;</li>
            <li className="py-1">
              uses technology or other means to access content or systems of
              Owler AI in a manner that is not authorized by Owler AI;
            </li>
            <li className="py-1">
              uses any robot, spider, site search/retrieval application or other
              manual or automatic device to retrieve, index, "scrape," "data
              mine" or in any way reproduce or circumvent the navigational
              structure or presentation of the Services, without Owler AI's
              express prior written consent;
            </li>
            <li className="py-1">
              uses or launches any automated system, including, without
              limitation, "robots," "spiders," or "offline readers," to access
              content or systems of Owler AI;
            </li>
            <li className="py-1">
              uses tools which hack or alter the Services, or that allow you to
              connect to the Services' private binary interface or utilize any
              interfaces other than those provided by us to you;
            </li>
            <li className="py-1">
              attempts to introduce viruses or any other computer code, files,
              or programs that interrupt, destroy, or limit the functionality of
              any computer software, hardware, or telecommunications equipment;
            </li>
            <li className="py-1">
              attempts to gain unauthorized access to Owler AI's computer
              network;
            </li>
            <li className="py-1">
              encourages conduct that would constitute a criminal offense or
              that gives rise to civil liability;
            </li>
            <li className="py-1">
              violates these Terms or any other Owler AI policies;
            </li>
            <li className="py-1">
              attempts to damage, disable, overburden, or impair Owler AI
              servers or networks;
            </li>
            <li className="py-1">
              mirrors or frames any part of the Services without Owler AI's
              express prior written consent;
            </li>
            <li className="py-1">
              fails to comply with applicable third-party terms; or
            </li>
            <li className="py-1">
              constitutes any other inappropriate conduct, as determined by us
              in our sole discretion.
            </li>
          </ul>
        </p>
        <p className="owler-p">
          We reserve the right, in our sole discretion, to take any actions we
          deem necessary and appropriate to preserve the integrity of the
          Services.
        </p>
        <h3 className="mt-8 font-bold">7. OUR MATERIALS</h3>
        <p className="owler-p">
          Owler AI may make certain digital reproductions of materials,
          information, content, software, or other materials (including but not
          limited to intellectual property, database rights, graphics, videos,
          text, and logos) available to you from the Services from time to time
          (collectively, the "
          <span className="font-bold">Owler AI Materials</span>"). The Services
          (including its organization, presentation and Owler AI Materials) are
          the property of Owler AI and its licensors and may be protected by
          intellectual property laws including laws relating to copyrights,
          trademarks, trade names, internet domain names and other similar
          rights. Owler AI or its licensors own all related trademarks and
          logos, and you agree not to copy or use them in any manner except as
          permitted.
        </p>
        <p className="owler-p">
          By using the Services, we grant you a non-exclusive,
          non-transferrable, non-sublicensable right and license to use the
          Owler AI Materials for your personal use only. If you download, access
          or use any Owler AI Materials, you agree that such materials: (i) may
          only be used for your personal use; (ii) may not be modified, used to
          create a derivative work, incorporated into any other work or
          otherwise exploited without Owler AI's prior written permission; (iii)
          shall not be submitted to, processed by, or otherwise fed into any
          artificial intelligence or automated processing software; and (iv)
          shall only be used in compliance with any additional license terms
          accompanying such materials.
        </p>
        <h3 className="mt-8 font-bold">8. AVAILABILITY</h3>
        <p className="owler-p">
          We cannot and do not promise that the Services will be uninterrupted,
          secure, or error-free. We reserve the right to interrupt/suspend the
          Services, or any part thereof, with or without prior notice for any
          reason.
        </p>
        <h3 className="mt-8 font-bold">9. ACCESS TO THE INTERNET</h3>
        <p className="owler-p">
          You are solely responsible for obtaining and maintaining all internet,
          computer hardware and other equipment needed to access and use the
          Services, and you shall be solely responsible for all charges and fees
          related thereto.
        </p>
        <p className="owler-p">
          Please note that the Internet is not a fully secure medium and any
          communication may be lost, intercepted or altered. Owler AI is not
          liable for any damages related to communications to, or from, the
          Services.
        </p>
        <h3 className="mt-8 font-bold">10. CURRENCY OF SERVICES</h3>
        <p className="owler-p">
          Owler AI cannot and does not guarantee or accept any responsibility or
          liability for the accuracy, currency or completeness of the
          information on this Site and the Services. We have the right, but not
          the obligation, to correct any errors, inaccuracies or omissions and
          to change or update the Services (including the Owler AI Materials) at
          any time, without prior notice to you.
        </p>
        <h3 className="mt-8 font-bold">
          11. LINKS TO OTHER SITES AND SERVICES
        </h3>
        <p className="owler-p">
          The Services may contain links to outside services and resources, the
          availability and content of which Owler AI does not control. Owler AI
          does not assume any responsibility or liability for the actions,
          products, and content of these and any other sites. Any concerns
          regarding any such services or resources should be directed to the
          service or resource. The inclusion of these links does not imply that
          Owler AI monitors or endorses these sites.
        </p>
        <h3 className="mt-8 font-bold">12. SECURITY</h3>
        <p className="owler-p">
          Owler AI makes no warranty whatsoever to you, express or implied,
          regarding the security of the Site, including with respect to the
          ability of unauthorized persons to intercept or access information
          transmitted by you through the Site. Owler AI is not responsible for
          any losses resulting from the loss or theft of your device, the loss
          or theft of your information transmitted from or stored on your
          devices, or any losses arising from the use of your device by someone
          whom you have given access. You are required to notify us of any
          unauthorized use of the Site by using the contact information in the "
          <span className="font-bold">Contact Information</span>" section below.
        </p>
        <p className="owler-p">
          Due to technical difficulties with the Internet, internet software or
          transmission problems could produce inaccurate or incomplete copies of
          information contained on this Site or the Services. Due to the ability
          to share certain content and materials, computer viruses or other
          destructive programs may also be inadvertently downloaded from this
          Site or the Services. Owler AI recommends that you install appropriate
          anti-virus or other protective software.
        </p>
        <p className="owler-p">
          OWLER AI SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY SOFTWARE, COMPUTER
          VIRUSES OR OTHER HARMFUL FILES OR PROGRAMS THAT MAY AFFECT YOUR
          COMPUTER EQUIPMENT OR OTHER PROPERTY FROM USING THIS SITE OR THE
          SERVICES.
        </p>
        <h3 className="mt-8 font-bold">13. SEVERABILITY/NO WAIVER</h3>
        <p className="owler-p">
          If any provision of these Terms of Use is found to be unenforceable,
          then that provision shall be deemed severable from these Terms of Use
          and shall not affect the validity and enforceability of any remaining
          provisions. Owler AI's failure to enforce the strict performance of
          any provision of these Terms or the additional terms and conditions
          for any Service will not constitute a waiver of our right to
          subsequently enforce such provision or any other provisions of these
          Terms or the additional terms and conditions.
        </p>
        <h3 className="mt-8 font-bold">
          14. DISCLAIMERS: SITE, SERVICES AND MATERIALS PROVIDED "AS-IS"
        </h3>
        <p className="owler-p">
          THIS SITE, THE SERVICES AND THE OWLER AI MATERIALS ARE PROVIDED "AS
          IS," "WHERE IS," AND "WITH ALL FAULTS," WITHOUT REPRESENTATION,
          WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED REPRESENTATIONS, WARRANTIES
          OR CONDITIONS OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
          PARTICULAR PURPOSE. OWLER AI DOES NOT REPRESENT OR WARRANT THAT THIS
          SITE, THE SERVICES, OR THE OWLER AI MATERIALS WILL MEET YOUR
          REQUIREMENTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
          OBTAINED BY YOU FROM OWLER AI, OR THROUGH THE SITE SHALL CREATE ANY
          WARRANTY REGARDING THE SERVICES NOT EXPRESSLY STATED IN THESE TERMS.
          YOU UNDERSTAND AND ACKNOWLEDGE THAT ADDITIONAL DISCLAIMERS,
          LIMITATIONS, AND NOTICES REGARDING THE SERVICES AND ITS CONTENT AND
          DATA MAY BE PROVIDED BY OWLER AI FROM TIME TO TIME WITHIN THE
          SERVICES.
        </p>
        <p className="owler-p">
          These Terms were written in English (US). Any translated version is
          provided solely for your convenience using third party translation
          services such as Google Translate. You understand and agree that Owler
          AI in no way controls or is responsible for any third-party product or
          service accessed through the Site.
        </p>
        <p className="owler-p">
          YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. THE SITE IS
          INTENDED FOR GENERAL INFORMATIONAL PURPOSES ONLY.{' '}
          <span className="font-bold">
            WE MAKE NO REPRESENTATION OR WARRANTY REGARDING THE ACCURACY OF
            TRANSLATIONS OF THE SERVICES.
          </span>{' '}
          PLEASE SEEK INDEPENDENT TRANSLATION SERVICES TO VERIFY ANY INFORMATION
          ON WHICH YOU RELY. NOTHING STATED, POSTED, OR AVAILABLE THROUGH ANY
          SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, AN OFFICIAL
          AND VERIFIED TRANSLATION OF THE SERVICES.
        </p>
        <p className="owler-p">
          Please note that some jurisdictions do not allow the disclaimer of
          certain representations or warranties, so the disclaimer in this
          Section 14 will apply to you to the extent permitted by applicable
          law.
        </p>
        <h3 className="mt-8 font-bold">15. LIMITATION OF LIABILITY</h3>
        <p className="owler-p">
          YOU AGREE THAT NONE OF THE PROTECTED PARTIES (AS DEFINED BELOW) SHALL
          HAVE ANY LIABILITY TO YOU UNDER ANY THEORY OF LIABILITY OR INDEMNITY
          IN CONNECTION WITH YOUR USE OF THE SITE AND/OR SERVICES. YOU HEREBY
          RELEASE AND FOREVER WAIVE ANY AND ALL CLAIMS YOU MAY HAVE AGAINST THE
          PROTECTED PARTIES (INCLUDING BUT NOT LIMITED TO CLAIMS BASED UPON THE
          NEGLIGENCE OF ANY OF THE PROTECTED PARTIES) FOR LOSSES OR DAMAGES YOU
          SUSTAIN IN CONNECTION WITH YOUR USE OF THE SITE AND/OR SERVICES.
        </p>
        <p className="owler-p">
          NOTWITHSTANDING THE FOREGOING PARAGRAPH AND TO THE MAXIMUM EXTENT
          PERMITTED BY LAW, THE TOTAL LIABILITY OF THE PROTECTED PARTIES, FOR
          ANY CLAIM, COST, DAMAGE, OR LOSS ARISING OUT OF OR RELATING TO THE
          SERVICES, INCLUDING FOR ANY WARRANTIES THAT MAY NOT BE EXCLUDED, SHALL
          NOT EXCEED THE LESSER OF THE AMOUNT YOU PAID TO US DURING THE TWELVE
          (12) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY OR $10.00
          (TEN DOLLARS) (USD) (THE "
          <span className="font-bold">LIABILITY CAP</span>"). OWLER AI AND YOU
          AGREE THAT THE LIABILITY CAP SHALL INCLUDE ALL FORMS OF DAMAGES.
        </p>
        <p className="owler-p">
          Some jurisdictions do not allow the exclusion or limitation of
          liability for certain types of damages. In such jurisdictions, our
          liability is limited to the greatest extent permitted by law.
        </p>
        <h3 className="mt-8 font-bold">16. INDEMNIFICATION</h3>
        <p className="owler-p">
          You will defend, indemnify, and hold Owler AI, each third-party
          service provider, their respective affiliates, subsidiaries, and
          parent companies, and their respective officers, directors, employees,
          agents, information providers, and partners (collectively, the "
          <span className="font-bold">Protected Parties</span>") harmless from
          and against any actual or threatened suit, actions, proceedings (at
          law or in equity), claims, damages, payments, deficiencies, fines,
          judgments, settlements, liabilities, losses, costs, and expenses
          (including reasonable attorneys' fees, costs, penalties, interest, and
          disbursements) arising from or related to (a) your conduct with
          respect to the Services and/or (b) violation (or alleged violation) of
          these Terms by you or any other person accessing the Site or Services
          on your behalf. Under no circumstance will Owler AI be liable for
          damages of any kind that result from your use of, or the inability to
          use, the Services.
        </p>
        <p className="owler-p">
          Please note that some jurisdictions limit the extent to which a party
          may indemnify another. As such, this provision will apply to you to
          the extent permitted by applicable law.
        </p>
        <h3 className="mt-8 font-bold">17. TERM & TERMINATION</h3>
        <p className="owler-p">
          Owler AI may, from time to time, but is in no way obligated to, permit
          you to access and use this Site and the Services in accordance with
          these Terms of Use and in the manner more particularly set out herein.
          You acknowledge and agree (i) that access to this Site and the
          Services may not be available from time to time, may be amended,
          revised, replaced, suspended or terminated in whole or in part at any
          time and without notice, and (ii) that Owler AI shall not, in any
          event, be responsible to you in any way should you be unable to access
          this Site and the Services at any time or from time to time. We may
          terminate these Terms of Use for convenience with no notice to you.
        </p>
        <p className="owler-p">
          Upon termination of these Terms of Use, you shall immediately cease
          and desist from all use of this Site and the Services.
        </p>
        <h3 className="mt-8 font-bold">
          18. ARBITRATION PROVISION AND CLASS ACTION WAIVER
        </h3>
        <p className="owler-p">
          <span className="font-bold">
            THIS ARBITRATION PROVISION AFFECTS YOUR RIGHTS; PLEASE READ
            CAREFULLY BEFORE AGREEING TO THESE TERMS OF USE.
          </span>
        </p>
        <p className="owler-p">
          <span className="font-bold">Agreement to Arbitrate</span>. Except as
          otherwise explicitly provided in this Arbitration Provision, Owler AI,
          together with its parent companies, wholly or majority-owned
          subsidiaries, affiliates, commonly-owned companies, successors,
          assigns, and any of these entities' employees, officers, directors,
          and agents and their successors, assigns, affiliates, and service
          providers (collectively, the "
          <span className="font-bold">Transaction Parties</span>") and you can
          elect to resolve any past, present, or future dispute or claim ("
          <span className="font-bold">Dispute</span>") arising from or relating
          in any way to (i) the use of the Services, (ii) these Terms of Use, or
          (iii) the relationship between you and any Transaction Party in
          connection with any of the foregoing that cannot be resolved directly
          between you and a Transaction Party, by binding arbitration under the
          Consumer Arbitration Rules ("
          <span className="font-bold">the Consumer Rules</span>") of the
          American Arbitration Association ("
          <span className="font-bold">AAA</span>"), rather than in court.
          (Solely for purposes of this Arbitration Provision, the term
          Transaction Parties also includes any third party providing any goods
          and/or services in connection with the use of the Site and the
          Services or any of the foregoing on behalf of a Transaction Party, if
          that third party is named as a defendant along with a Transaction
          Party in the same proceeding or a related proceeding.)
        </p>
        <p className="owler-p">
          <span className="font-bold">"Dispute" Defined</span>. Except as
          otherwise explicitly provided in this Arbitration Provision, "
          <span className="font-bold">Dispute</span>" broadly includes, without
          limitation: any claims based in contract, statute, constitution,
          ordinance, tort, fraud, consumer rights, misrepresentation, equity, or
          any other legal theory; initial claims, counterclaims, cross-claims,
          and third-party claims; federal, state, and local claims; and claims
          which arose before the date of your use of the Services, including,
          but not limited to, any dispute or claim arising before the date you
          accessed the Site or the Services or agreed to these Terms of Use and
          any dispute or claim relating to (by way of example and not
          limitation): (i) the use, denial, or termination of the Services
          and/or the events leading up thereto; (ii) any disclosure,
          advertisement, application, solicitation, promotion, or oral or
          written statement, warranty, or representation made by or on behalf of
          a Transaction Party; (iii) any product or service provided by or
          through a Transaction Party or third parties in connection with the
          use of the Services or the relationship between you and a Transaction
          Party and any associated fees; (iv) a Transaction Party's use or
          failure to protect any personal information you give a Transaction
          Party in connection with the use of the Services or your relationship
          with the Transaction Party; (v) enforcement of any and all of the
          obligations a party may have to another party in connection with the
          use of the Services or agreement governing the same; or (vi)
          compliance with applicable laws and/or regulations.
        </p>
        <p className="owler-p">
          <span className="font-bold">Exceptions to "Dispute."</span>{' '}
          Notwithstanding any of the foregoing: (i) disputes or controversies
          about the validity, enforceability, coverage, or scope of this
          Arbitration Provision or any part thereof are for a court and not an
          arbitrator to decide; however, disputes or controversies about these
          Terms of Use or your agreements governing the use of the Services or
          with Transaction Parties as a whole are for an arbitrator and not a
          court to decide; (ii) any Disputes seeking to enforce or protect, or
          concerning the validity of intellectual property rights, will not be
          subject to binding arbitration under this Arbitration Provision; and
          (iii) any party may proceed with their individual claims in small
          claims court (or an equivalent court) if that option is available in
          the applicable jurisdiction and the amount in controversy falls within
          the small claims court's (or the equivalent court's) jurisdictional
          limits; but if that action is transferred, removed, or appealed to a
          different court, arbitration can be elected. Moreover, this
          Arbitration Provision will not apply to any Dispute that was already
          pending in court before this Arbitration Provision took effect.
        </p>
        <p className="owler-p">
          <span className="font-bold">Governing Law</span>. The Federal
          Arbitration Act ("<span className="font-bold">FAA</span>"), 9 U.S.C. 1
          et seq., and federal arbitration law apply to this Arbitration
          Provision. There is no judge or jury in arbitration and court review
          of an arbitration award is limited, but an arbitrator can award an
          individual the same damages and relief as a court in an individual
          case and must apply and follow applicable substantive law, consistent
          with the FAA, and the terms of these Terms of Use and any agreement
          governing the use of the Site and the Services. The arbitrator shall
          apply applicable statutes of limitations and honor privilege rules.
          Any judgment on the award rendered by the arbitrator will be final,
          subject to any appeal rights under the FAA, and may be entered in any
          court of competent jurisdiction. No arbitration award involving the
          parties will have any preclusive effect as to issues or claims in any
          dispute involving anyone who is not a party to the arbitration, nor
          will an arbitration award in prior disputes involving other parties
          have preclusive effect in an arbitration between the parties to this
          Arbitration Provision.
        </p>
        <p className="owler-p">
          <span className="font-bold">
            JURY TRIAL WAIVER. YOU UNDERSTAND THAT ABSENT YOUR CONSENT TO
            ARBITRATION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A
            TRIAL BY JURY.
          </span>
        </p>
        <p className="owler-p">
          <span className="font-bold">Arbitration Procedures</span>. Either you
          or a Transaction Party can initiate arbitration through the AAA or by
          filing a motion to compel arbitration of claims filed in court.
          Regardless of who elected arbitration or how arbitration was elected,
          the party asserting the claim (i.e., the party seeking money damages
          or other relief from a court or an arbitrator) is responsible for
          starting the arbitration proceeding. If the AAA cannot serve and we
          cannot agree on a substitute, a court with jurisdiction shall select
          the arbitrator, who will apply the AAA rules and the procedures
          specified in this Arbitration Provision. Any arbitrator must be a
          practicing attorney with ten or more years of experience practicing
          law or a retired judge. Any arbitration will be governed by the
          then-current Consumer Rules, and its Procedures for the Resolution of
          Disputes through Document Submission ("
          <span className="font-bold">Document Submission Procedures</span>").
          For more information about the AAA and its rules, you may contact the
          AAA at American Arbitration Association, 120 Broadway, Floor 21, New
          York, N.Y. 10271, 1-800-778-7879,{' '}
          <a className="owler-a" href="www.adr.org">
            www.adr.org
          </a>
          . The Document Submission Procedures are included in the Consumer
          Rules. The parties shall pay filing, administrative and arbitrator
          fees in accordance with the Consumer Rules. If you cannot afford to
          pay your share of the fees and cannot obtain a waiver from the AAA,
          you can make a written good faith request for a Transaction Party to
          pay or advance such fees. Each party will bear the expense of its own
          attorneys, experts and witnesses, regardless of which party prevails,
          unless applicable law, these Terms of Use or an applicable agreement
          gives a party the right to recover such expenses from the other party.
          If the arbitrator determines that any party's claim or defense is
          frivolous or wrongfully intended to oppress or harass the other party,
          the arbitrator may award sanctions in the form of fees and expenses
          reasonably incurred by the other party if such sanctions could be
          imposed under Rule 11 of the Federal Rules of Civil Procedure. The
          arbitration will be conducted by a single arbitrator solely based on
          written submissions and will not require any personal appearance by
          the parties or witnesses unless the arbitrator determines that a
          telephonic or in-person conference or hearing is necessary based on
          the request of one or more of the parties. If an in-person hearing is
          necessary, it will be held in the federal judicial district in which
          you reside or at another location that is reasonably convenient to all
          parties.
        </p>
        <p className="owler-p">
          <span className="font-bold">CLASS ACTION WAIVER</span>. You and the
          Transaction Parties each agree that if arbitration of a Dispute is
          elected, the Dispute will be resolved in arbitration (not in court)
          only on an individual basis and not as a class, collective or other
          representative action. To the fullest extent permitted under the FAA:
          (a) no arbitration will be joined or consolidated with any other
          unless all parties otherwise agree in writing; (b) there is no right
          or authority for any Dispute to be arbitrated on a class-action,
          collective action or private attorney general basis or to utilize
          class action procedures; and (c) there is no right or authority for
          any Dispute to be brought in a purported representative capacity on
          behalf of the general public or any other persons. No arbitrator shall
          have the authority to issue any relief that applies to any person or
          entity other than Transaction Parties and/or you individually.
        </p>
        <p className="owler-p">
          <span className="font-bold">Miscellaneous; Conflicts</span>. Even if
          all parties have opted to litigate a Dispute in court, a party may
          elect arbitration with respect to any claim made by a new party or any
          claim later asserted by a party in that or any related or unrelated
          lawsuit (including a claim initially asserted on an individual basis
          but modified to be asserted on a class, representative, or multi-party
          basis). Nothing in that litigation shall constitute a waiver of any
          rights under this Arbitration Provision. If any portion of this
          Arbitration Provision is inconsistent with the Consumer Rules, with
          these Terms of Use, or your agreements governing the use of the
          Services, or with an arbitration provision in any agreement with a
          Transaction Party, this Arbitration Provision shall govern.
        </p>
        <p className="owler-p">
          <span className="font-bold">Survival; Severance</span>. This
          Arbitration Provision shall survive termination of the Site and the
          Services or these Terms of Use or of any agreement into which you
          enter with a Transaction Party; and, further, you understand and agree
          that this Arbitration Provision applies not only to these Terms of Use
          but also to any subsequent agreement (including without limitation any
          agreement governing the use of Site or Services) into which you enter
          with a Transaction Party. If any portion of this Arbitration Provision
          is deemed invalid or unenforceable, it shall not invalidate the
          remaining portions of this Arbitration Provision, except that: (a) if
          the Class Action Waiver is limited, voided or found unenforceable with
          respect to a Dispute that does not seek public injunctive relief and
          that determination becomes final after all appeals have been
          exhausted, then this Arbitration Provision (except for this sentence)
          shall be null and void with respect to such proceeding. The parties
          acknowledge and agree that under no circumstances will a class action
          be arbitrated; and (b) if a claim is brought seeking public injunctive
          relief and a court determines that the restrictions in the Class
          Action Waiver or elsewhere in this Arbitration Provision prohibiting
          the arbitrator from awarding relief on behalf of third parties are
          unenforceable with respect to such claim and that determination
          becomes final after all appeals have been exhausted, the claim for
          public injunctive relief will be determined in court and any
          individual claims seeking monetary relief will be arbitrated. In such
          a case the parties will request that the court stay the claim for
          public injunctive relief until the arbitration award pertaining to
          individual relief has been entered in court. In no event will a claim
          for public injunctive relief be arbitrated.
        </p>
        <p className="owler-p">
          <span className="font-bold">RIGHT TO OPT OUT</span>: If you do not
          want this Arbitration Provision to apply, you must send us a signed
          notice within thirty (30) calendar days of the date on which you
          electronically submit an application for a product or service offered
          by a Transaction Party through the Site or Services. You must send the
          notice in writing (and not electronically) to{' '}
          <a className="owler-a" href="mailto:privacy@owlerai.com">
            privacy@owlerai.com
          </a>
          . You must provide your name, address, telephone number, and state
          that you "opt out" of the Arbitration Provision. Opting out will not
          affect the other provisions of these Terms of Use or any other
          agreement governing the Site or with a Transaction Party. If you do
          not opt out, you will be bound by this Arbitration Provision in these
          Terms of Use and any other agreement governing a Service or with a
          Transaction Party. Please note that if you enter an agreement with a
          Transaction Party that contains a different arbitration provision with
          a right to opt out, opting out of this Arbitration Provision is not a
          rejection of the arbitration provision in the other agreement. You
          will need to separately opt out of the arbitration provision in the
          other agreement if you do not want it to apply.
        </p>
        <h3 className="mt-8 font-bold">19. MISCELLANEOUS</h3>
        <p className="owler-p">
          These Terms constitute the entire agreement of the parties with
          respect to the subject matter hereof and supersede all previous
          written or oral agreements between the parties with respect to such
          subject matter. No waiver by either party of any breach or default
          hereunder shall be deemed to be a waiver of any preceding or
          subsequent breach or default. The section headings used in these Terms
          shall not be given any legal import.
        </p>
        <p className="owler-p">
          These Terms of Use shall be governed by and construed in accordance
          with the laws of the State of Delaware (without regard to provisions
          relating to conflicts of law), except as to copyright and trademark
          matters, which are covered by American federal laws. You agree that
          any legal action or proceeding between Owler AI and you for any
          purpose concerning these Terms or the parties' obligations hereunder
          shall be resolved individually, without resort to any form of class
          action, exclusively in the United States District Court for the
          Northern District of Illinois, or, if there is not subject matter
          jurisdiction, in the courts of the State of Illinois, and you agree to
          submit to the jurisdiction of these courts. You waive any venue,
          personal jurisdiction, and inconvenient forum objections or defenses
          to such courts.
        </p>
        <p className="owler-p">
          These Terms of Use are not assignable, transferable, or sublicensable
          by you except with Owler AI's prior written consent.
        </p>
        <p className="owler-p">
          Owler AI may assign its rights and duties under these Terms of Use at
          any time without notice to you.
        </p>
        <p className="owler-p">
          No waiver by either party of any breach or default hereunder shall be
          deemed to be a waiver of any preceding or subsequent breach or
          default.
        </p>
        <h3 className="mt-8 font-bold">20. CONTACT INFORMATION</h3>
        <p className="owler-p">
          If you need to contact us regarding this Site, the Services or these
          Terms of Use, please reach out to us at{' '}
          <a className="owler-a" href="mailto:privacy@owlerai.com">
            privacy@owlerai.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
