import React from 'react';
import ProfileHeader from './ProfileHeader';
import GeneralInformation from './GeneralInformation';
import Assignments from './Assignments';
import ActivityFeed from './ActivityFeed';

type ProfileCompositeProps = {
  authUser: any;
};

// Mocks
const mockName = 'Marie Clay';
const mockTitle = 'Teacher';
const mockStats = { classes: 1, students: 10, assignmentsGraded: 20 };

const ProfileComposite = ({ authUser }: ProfileCompositeProps) => {
  const uid = authUser.uid;
  // We should fetch user information here and prop it to profile header and general information components

  return (
    <div className=" p-8">
      {/* Profile header with statistics */}
      <ProfileHeader name={mockName} title={mockTitle} stats={mockStats} />

      {/* Cards section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <GeneralInformation
          uid={uid}
          schoolName="Hartford High School"
          department="English Language Arts"
          city="Hartford"
          state="CT"
          zip="06103"
          subjects={['Literature', 'Writing', 'Poetry']}
          badges={['Grammar', 'Citations', 'Creative Writing']}
        />
        <Assignments uid={uid} />
        <ActivityFeed />
      </div>
    </div>
  );
};

export default ProfileComposite;
