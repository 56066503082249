import { ReactNode } from 'react';
import { FirebaseApi } from './FirebaseApi';
import { AssignmentMetadataInfo } from './components/AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';
import { AssignmentReference } from './components/assignments/AssignmentList/AssignmentReference';
import { SegmentAnalyticsApi } from './analytics/SegmentAanlyticsApi';

export interface NavigationItem {
  name: string;
  href: string;
  current?: boolean;
  icon?: any;
}

export interface Team {
  id: number;
  name: string;
  href: string;
  initial: string;
  current: boolean;
}

export interface Rubric {
  id: string;
  name: string;
  description: string;
  points_possible: string;
  color?: string;
  highlighted_text?: string[];
  points_earned?: string;
  explanation?: string;
}

export interface AssignmentMetadataContent {
  [key: string]: any; // general getter by string
  department: string;
  teacher_type: string;
  school_type: string;
  course: string;
  question_background: string;
  question: string;
  rubric: Rubric[];
  version: string;
  grade_level?: string;
}

export class AssignmentMetadataContentV1 implements AssignmentMetadataContent {
  [key: string]: any; // general getter by string
  readonly version: string = 'v1';
  constructor(
    public department: string,
    public teacher_type: string,
    public school_type: string,
    public course: string,
    public question_background: string,
    public question: string,
    public rubric: Rubric[],
    public grade_level?: string,
    public google_classroom?: GoogleClassroomMetadataV1
  ) {}
}

export interface GoogleClassroomMetadataV1 {
  course_id: string;
  course_work_id: string;
}

export interface Student {
  name: string;
  score: string;
  email: string;
  date: string;
}

export interface AssignmentDetail {
  name: string;
  students: Student[];
}

export interface Assignment extends AssignmentReference {
  metadata_content: AssignmentMetadataContent;
  href?: string;
}

export interface GradingResult {
  outputFileName?: string | null;
  grade: string;
  rubricGrading: RubricGrading;
  explanation: string;
  suggestions: string[];
  followUpQuestions: string[];
  grammarMistakes: any[];
  inputPath?: string;
  inputFileContent?: string;
}

export type RubricGrading = Array<{ [key: string]: Rubric }>;

export interface DashboardProps {
  currentNavigation: string;
  children: React.ReactNode;
}

export interface Breadcrumb {
  name: string;
  href: string;
  current: boolean;
}

export interface ReviewBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export interface CloudApi {
  signup(email: string, password: string): Promise<void>;
  login(email: string, password: string): Promise<void>;
  logout(): Promise<void>;
  getCurrentUser(): Promise<any>;
  isLoggedIn(): boolean;
  getCurrentUserId(): string;
  getCurrentUserIdToken(): Promise<string>;
  getUploadPath(userId: string, fileName: string, subFolder?: string): string;
  getAssignmentMetadataPath(
    userId: string,
    fileName: string,
    assignmentMetadataId: string
  ): string;
  getPath(userId: string, fileName: string, subFolder: string): string;
  getOutputPath(userId: string, jobId: string): string;
  listFiles(folderPath: string): Promise<any[]>;
  downloadFileAsText(filePath: string): Promise<string>;
  uploadFile(userId: string, file: File): Promise<string>;
  upload(file: File, filePath: string): Promise<string>;
  createAssignment(
    userId: string,
    assignmentId: string,
    assignmentMetadataPath: string
  ): Promise<string>;
  createAssignmentMetadataInfo(
    userId: string,
    assignmentMetadataContent: AssignmentMetadataContent
  ): Promise<AssignmentMetadataInfo>;
  getGradingOutput(
    assignmentId: string,
    submissionId: string
  ): Promise<GradingResult | null>;
  createDatabaseFieldAccessor<T>(
    documentPath: string[],
    fieldPath: string[]
  ): DatabaseFieldAccessor<T>;
}

export interface AnalyticsApi {
  logSignupPageCompletion(userId: string): Promise<void>;
  logLoginPageCompletion(userId: string): Promise<void>;
  logLogoutPageCompletion(userId: string): Promise<void>;
  logAssignmentListPageOpen(userId: string): Promise<void>;
  logAssignmentsPageCreateAssignment(userId: string): Promise<void>;
  logAssignmentPreviewOpen(userId: string, assignmentId: string): Promise<void>;
  logAssignmentPreviewOpen(userId: string, assignmentId: string): Promise<void>;
  logCreateAssignment(userId: string): Promise<void>;
  logCreateRubric(userId: string): Promise<void>;
  logCreateRubricPrevious(userId: string): Promise<void>;
  logAddRubricCategory(userId: string): Promise<void>;
  logRemoveRubricCategory(userId: string): Promise<void>;
  logUploadStudentSubmissions(userId: string): Promise<void>;
  logUploadStudentSubmissionsPrevious(userId: string): Promise<void>;
  logUploadFiles(userId: string): Promise<void>;
  logPastedSubmission(userId: string): Promise<void>;
  logRemoveUploadedSubmission(userId: string): Promise<void>;
  logAssignmentViewCopyShareableLink(userId: string): Promise<void>;
  logAssignmentViewPrevious(userId: string): Promise<void>;
  logAssignmentViewNext(userId: string): Promise<void>;
  logAssignmentFormSubmit(userId: string): Promise<void>;
  logAssignmentFormGoBack(userId: string): Promise<void>;
  logRubricValidatedAndSubmitted(userId: string): Promise<void>;
  logReviewSliderPerformance(userId: string): Promise<void>;
  logReviewTextEditedExplanation(userId: string): Promise<void>;
  logReviewTextEditedSuggestions(userId: string): Promise<void>;
  logReviewTextEditedFollowUp(userId: string): Promise<void>;
  logReviewSliderScore(userId: string): Promise<void>;
  logReviewTextEditedFeedback(userId: string): Promise<void>;
  logSelectExistingAssignmentOpen(
    userId: string,
    assignment_id: string
  ): Promise<void>;
  logSelectExistingAssignmentClose(
    userId: string,
    assignment_id: string
  ): Promise<void>;
  logDuplicateAndEditAssignment(userId: string): Promise<void>;
  logReuseAssignment(userId: string): Promise<void>;
  reset(): void;
}

export interface JobBase {
  created_at: string;
  job_type: string;
  percent_complete: number;
  status: string;
  timestamp: string;
}

export interface VariableType {
  [key: string]: any;
}

export interface Job extends JobBase, VariableType {}

export interface ApiProviderProps {
  children: ReactNode;
}

export interface ApiContextType {
  api: FirebaseApi;
  analyticsApi: SegmentAnalyticsApi;
  loading: boolean;
}

export interface MockContextType {
  useMocks: boolean;
  mockContentProvider: {
    getMockAssignmentDetails: () => any;
    getMockAssignmentQuestion: () => any;
    getMockAssignmentMetadataContent: () => any;
    getMockBreadcrumbs: () => any;
    getMockAssignmentList: () => any;
    getMockGradingResult: () => any;
    getMockJobId: () => any;
    getMockStudentSubmission: () => any;
  };
  toggleMock: () => void;
  enableMock: () => void;
  disableMock: () => void;
}

export interface MockProviderProps {
  children: ReactNode;
}

export interface Submission {
  fileName: string;
  submissionId?: string;
  outputPath?: string;
  submissionPath?: string;
  token?: string;
}

export interface DatabaseFieldAccessor<T> {
  read: () => Promise<T | null>;
  write: (value: T) => Promise<void>;
}

export interface GoogleClassroomCourse {
  id: string;
  name: string;
  courseState: string;
  assignments: GoogleClassroomCourseWork[];
  room: string | null;
  section: string | null;
  creationTime: string;
}

export interface GoogleClassroomCourseWork {
  id: string;
  title: string;
  description: string | null;
  maxPoints: number | null;
  state: string;
  creationTime: string;
}

export interface GoogleClassroomSubmission {
  id: string;
  fileId: string;
  firebaseUrl: string;
  student_id: string;
}
