import React, { FC } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import { Container } from '../../dependencyInjection/Container';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../contexts/ApiProvider';
import Spinner from '../../components/profile/Spinner';
import ProfileComposite from '../../components/profile/ProfileComposite';

type ProfileViewProps = {
  container: Container;
};

const ProfileView: FC<ProfileViewProps> = ({ container }) => {
  const { api } = useApi();

  const { isLoading, data: authUser } = useQuery({
    queryKey: ['auth'],
    queryFn: () => api.getCurrentUser,
  });

  let innerContainer = <ProfileComposite authUser={authUser} />;

  // Render loading screen if isLoading is true
  if (isLoading) {
    innerContainer = <Spinner />;
  }

  return <Dashboard currentNavigation="Profile" children={innerContainer} />;
};

export default ProfileView;
