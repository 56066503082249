import React, { FC } from 'react';

type StatProps = {
  num: string;
  txt: string;
};

const StatsCard: FC<StatProps> = ({ num, txt }) => {
  return (
    <div className="flex m-2 md:m-0 justify-center flex-col">
      <h2 className="font-poppins text-3xl md:text-5xl">{num}</h2>
      <p className="font-inter text-s md:text-xl md:py-3">{txt}</p>
    </div>
  );
};

export default StatsCard;
