import React, { FC } from 'react';
import ContactForm from './contact/ContactForm';
import HeroNav from '../components/hero/HeroNav';

const Contact: FC = () => {
  return (
    <>
      <HeroNav />
      <ContactForm />
    </>
  );
};

export default Contact;
