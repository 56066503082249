import React, { FC, useEffect, useState } from 'react';
import ToText from '../utils/toText';
import HeroNav from '../components/hero/HeroNav';

interface Article {
  title: string;
  link: string;
  description: string;
}

interface ArticleComponentProps {
  article: Article;
}

const ArticleComponent: FC<ArticleComponentProps> = ({ article }) => {
  const thumbnail = ((article.description as any)
    .toString()
    .match(/<img[^>]+src="([^">]+)"/) || [])[1];
  const contentMatch =
    (article.description as any).toString().match(/<p>(.*?)<\/p>/) || [][1];
  const content = contentMatch.length > 1 ? contentMatch[1].slice(0, 250) : '';

  return (
    <>
      <div className="flex flex-col max-w-18 m-8 border-4 rounded-xl border-black bg-white font-inter">
        {thumbnail && (
          <img
            src={thumbnail}
            className="relative bg-cover bg-center rounded-t-lg border-black border-b-4"
            alt="Thumbnail"
          />
        )}
        <div className="text-center m-2">
          <div className="m-5 text-md font-bold">
            {article.title.toUpperCase()}
          </div>
          <p className="mb-4 px-6">{`${ToText(content)}`}...</p>
        </div>
        <a
          href={article.link}
          className="inline-flex text-white mb-3 shadow bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-small rounded-xl text-md px-20 sm:px-32 py-2.5 text-center self-center"
        >
          Read More
        </a>
      </div>
    </>
  );
};

const Resources: FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  // TODO: replace medium @ with Owler medium account
  useEffect(() => {
    fetch(
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@athena_owlerai'
    )
      .then((res) => res.json())
      .then((data) => setArticles(data.items))
      .catch((error) => console.error('Error fetching articles:', error));
  }, []);

  return (
    <>
      <HeroNav />
      <div className="bg-gradient-to-b from-indigo-50 to-indigo-200 sm:p-16">
        <h1 className="text-center sm:text-left text-6xl font-suranna mb-3 mx-3 sm:mx-0">
          Learning Bytes: AI Insights for Educators
        </h1>
        <div className="flex flex-col sm:grid sm:grid-cols-3 sm:items-start">
          {articles.map((article, index) => (
            <ArticleComponent key={index} article={article} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Resources;
