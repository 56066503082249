import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

function useFirebaseLoginState(): boolean | undefined {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const auth = getAuth();
    auth.authStateReady().then(() => {
      const refreshUserIsLoggedIn = () => {
        setUserIsLoggedIn(auth.currentUser?.uid !== undefined);
      };

      refreshUserIsLoggedIn();
      auth.onAuthStateChanged(refreshUserIsLoggedIn);
    });
  });

  return userIsLoggedIn;
}

export { useFirebaseLoginState };
