import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SegmentService } from './SegmentService';

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    SegmentService.getInstance().page(location.pathname, {
      title: document.title,
      url: window.location.href,
      path: location.pathname,
    });
  }, [location]);
}

export { usePageViews };
