import {
  Firestore,
  collection,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore';
import {
  usersCollection,
  assignmentMetadataCollection,
} from '../../../constants';
import { AssignmentMetadataInfo } from './AssignmentMetadataInfo';

export interface AssignmentMetadataInfoListProvider {
  get(): Promise<AssignmentMetadataInfo[]>;
}

export class FirebaseAssignmentMetadataInfoListProvider
  implements AssignmentMetadataInfoListProvider
{
  constructor(
    private readonly _firestore: Firestore,
    private readonly _userID: string
  ) {}

  async get(): Promise<AssignmentMetadataInfo[]> {
    const assignmentMetadataColRef = collection(
      this._firestore,
      usersCollection,
      this._userID,
      assignmentMetadataCollection
    );
    const q = query(assignmentMetadataColRef, orderBy('created_at', 'desc'));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => {
      const firebaseAssignmentMetadataInfo = doc.data();

      const assignmentMetadataInfo: AssignmentMetadataInfo = {
        assignment_metadata_path:
          firebaseAssignmentMetadataInfo.assignment_metadata_path,
        assignment_metadata_id:
          firebaseAssignmentMetadataInfo.assignment_metadata_id,
        created_at: firebaseAssignmentMetadataInfo.created_at.toDate(),
        course: firebaseAssignmentMetadataInfo.course,
        question_truncated: firebaseAssignmentMetadataInfo.question_truncated,
        version: firebaseAssignmentMetadataInfo.version,
      };

      return assignmentMetadataInfo;
    });
  }
}
