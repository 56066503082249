import { AssignmentsProvider } from '../AssignmentList/AssignmentsProvider';
import { useQuery } from '@tanstack/react-query';

function useAssignments(assignmentsProvider: AssignmentsProvider) {
  const fetchAssignments = () => assignmentsProvider.get();

  const {
    data: assignments,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['assignments'],
    queryFn: fetchAssignments,
    staleTime: 0, // always fetch
  });

  return { assignments, refetch, isLoading };
}

export { useAssignments };
