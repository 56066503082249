import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AssignmentReference } from '../AssignmentList/AssignmentReference';
import { Assignment } from '../../../Types';
import { AssignmentProvider } from '../AssignmentList/AssignmentProvider';

function useAssignment(
  assignmentReference: AssignmentReference,
  assignmentProvider: AssignmentProvider
) {
  const { data: assignment, refetch } = useQuery({
    queryKey: ['useAssignment:', assignmentReference.assignment_id],
    queryFn: () =>
      assignmentProvider.getByAssignmentReference(assignmentReference),
    staleTime: 60 * 10, // 10 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return { assignment, refetch };
}

export { useAssignment };
