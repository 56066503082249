import {
  DocumentSnapshot,
  Firestore,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { GradingStatus } from './GradingStatus';
import { GradingStatuses } from './GradingStatuses';

export interface AssignmentStatusProvider {
  subscribe(
    jobId: string,
    statusUpdate: (status: GradingStatus) => void
  ): () => void;
}

export class FirebaseAssignmentStatusProvider
  implements AssignmentStatusProvider
{
  constructor(
    private readonly _firestore: Firestore,
    private readonly _userID: string
  ) {}

  subscribe(
    jobId: string,
    statusUpdate: (status: GradingStatus) => void
  ): () => void {
    const jobDocumentReference = doc(
      this._firestore,
      'users',
      this._userID,
      'jobs',
      jobId
    );

    const unsubscribe = onSnapshot(
      jobDocumentReference,
      (snapshot: DocumentSnapshot) => {
        const jobStatus: GradingStatuses = ((): GradingStatuses => {
          const jobData = snapshot?.data();

          if (!jobData) {
            return GradingStatuses.Failed;
          }

          if (jobData.percent_complete === 100) {
            return GradingStatuses.Completed;
          }

          switch (jobData.status) {
            case 'created':
              return GradingStatuses.InProgress;
            case 'converting_plaintext':
              return GradingStatuses.InProgress;
            case 'running_gpt':
              return GradingStatuses.InProgress;
            case 'complete':
              return GradingStatuses.Completed;
            case 'error':
              return GradingStatuses.Failed;
          }

          throw new Error(`unknown job status received: ${jobData.status}`);
        })();

        statusUpdate(new GradingStatus(jobStatus));
      }
    );

    return unsubscribe;
  }
}
