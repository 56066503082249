// funciton to handle auth errors with registration and login
// https://firebase.google.com/docs/auth/admin/errors other possible errors that might have to be accounted for in the future
export function handleAuthError(
  error: any,
  setError: (message: string) => void
) {
  switch (error.code) {
    case 'auth/email-already-in-use':
      setError(
        'This email is already in use. Please try with a different email.'
      );
      break;
    case 'auth/weak-password':
      setError('The password is too weak. Please enter a stronger password.');
      break;
    case 'auth/invalid-email':
      setError('The email address is not valid. Please enter a valid email.');
      break;
    case 'auth/user-not-found':
      setError('Email not found. Please create an account below.');
      break;
    case 'auth/wrong-password':
      setError('Incorrect password. Please try again.');
      break;
    default:
      setError('An unknown error occurred. Please try again.');
      break;
  }
}
