import React, { FC } from 'react';
import scribble from '../../../public/images/scribble_about.svg';

const AboutUs: FC = () => {
  return (
    <>
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-col px-8 sm:w-2/5 sm:py-12 sm:px-20">
          <p className="text-2xl font-poppins text-blue-800 mb-8 sm:mb-10">
            OWLER'S MISSION
          </p>

          <h2 className="font-suranna text-3xl sm:text-4xl leading-relaxed sm:leading-loose mb-6 sm:mb-12">
            ... is to enhance educational experiences through the development of
            responsible AI tools. By automating review and meaningful feedback ,
            we aim to empower teachers, reduce burnout, and provide students
            with the support they need to succeed
          </h2>

          <img className="mb-6 sm:mb-0 w-3/4" src={scribble} />
        </div>

        {/* TODO: can we map these while keeping the specific italics? */}
        <div className="flex flex-col bg-indigo-800 px-8 py-8 sm:w-3/5 sm:py-12 sm:px-20 font-roboto text-white">
          <p className="text-2xl font-poppins mb-10">OUR BELIEFS</p>

          <div className="flex flex-col mb-20">
            <h2 className="text-3xl pb-4">
              <i>All</i> students, deserve access to the latest advances in
              EDtech
            </h2>
            <p className="leading-loose">
              Owler’s pricing, product design, and team is dedicated to
              democratizing the powers of AI for all classrooms
            </p>
          </div>

          <div className="flex flex-col mb-20">
            <h2 className="text-3xl pb-4">
              Artificial intelligence must be deployed effectively
            </h2>
            <p className="leading-loose">
              {' '}
              Owler is designed to not provide grades, but to create streamlined
              and standardized scoring that can be used to support thoughtful
              assessment by teachers
            </p>
          </div>

          <div className="flex flex-col mb-20">
            <h2 className="text-3xl pb-4">
              The best products center <i>teachers</i> & <i>students</i>
            </h2>
            <p className="leading-loose">
              Owler keeps the teacher and student experience integrated at every
              stage of the product and our organization
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
