import React, { FC } from 'react';

const whyOwlerContent = {
  title: 'Why Owler?',
  subPoints: [
    {
      title: 'Personalized Feedback +',
      content:
        'Providing unique feedback can take hours. Owler, powered by a properiety A.I. algorithm, leverages your rubric to provide targeted feedback to each student',
    },
    {
      title: 'Grading +',
      content:
        'Our easy to use interface allows teachers to apply points and mark-ups with the click of a button! Owler’s dashboard allows teachers to track student progress over time, against learning objectives, and by state standards',
    },
  ],
};

const WhyOwler: FC = () => {
  return (
    <>
      <div className="bg-indigo-950 text-white py-12 font-roboto flex justify-center items-center">
        <div className="w-full sm:w-2/3 mx-4 sm:mx-16 flex flex-col sm:flex-row">
          <div className="self-center sm:self-start mt-16 sm:mr-12 sm:mt-24">
            <h2 className="text-6xl font-bold">{whyOwlerContent.title}</h2>
          </div>
          <div className="sm:w-2/3 self-center p-6">
            <div className="mt-6">
              <h2 className="text-2xl sm:text-4xl font-bold">
                Owler does NOT remove teachers from the grading process
              </h2>
              <p className="text-sm sm:text-lg">
                Reliably brings necessary pieces of essays to the teacher's
                attention while leaving high-quality, personalized feedback for
                each student
              </p>
            </div>
            {whyOwlerContent.subPoints.map((point, index) => (
              <div key={index} className="mt-6">
                <h3 className="text-l sm:text-xl font-semibold">
                  {point.title}
                </h3>
                <p className="text-sm sm:text-lg">{point.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyOwler;
