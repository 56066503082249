import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  browserLocalPersistence,
  setPersistence,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  addDoc,
  onSnapshot,
  setDoc,
  serverTimestamp,
  updateDoc,
  query,
  orderBy,
  getDocs,
  DocumentSnapshot,
} from 'firebase/firestore';
import {
  ref as storageRef,
  getStorage,
  uploadBytesResumable,
  listAll,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid'; // Import uuid library
import {
  jobsCollection,
  usersCollection,
  assignmentsCollection,
  assignmentMetadataCollection,
} from './constants';
import {
  CloudApi,
  Assignment,
  Job,
  AssignmentMetadataContentV1,
  GradingResult,
  DatabaseFieldAccessor,
} from './Types';
import firebaseApp from './firebaseAppFactory';
import { AssignmentMetadataInfo } from './components/AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';
import { FirebaseFileDownloader } from './FirebaseFileDownloader';
import { User, UserData } from './models/user';
import { extractFileNameFromPath } from './utils';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const app = firebaseApp;
// const analytics = getAnalytics(app);
const auth = getAuth(app);
// Stay logged in
setPersistence(auth, browserLocalPersistence);

const firestore = getFirestore(); // get instance of Firestore
const storage = getStorage(app); // get instance of Firebase Storage

class FirebaseApi implements CloudApi {
  getEmailAndUserIdFromUserCredential(userCredential: any): any {
    const user = userCredential.user;
    if (user !== null) {
      const { email, uid } = user;
      return { email, userId: uid };
    } else {
      throw new Error('User data unavailable');
    }
  }

  async signup(email: string, password: string): Promise<any> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      return this.getEmailAndUserIdFromUserCredential(userCredential);
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  }

  async login(email: string, password: string): Promise<any> {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return this.getEmailAndUserIdFromUserCredential(userCredential);
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  }

  async logout(): Promise<void> {
    try {
      await signOut(getAuth());
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  }

  getCurrentUser(): any {
    return getAuth().currentUser;
  }

  getCurrentUserId(): string {
    return this.getCurrentUser().uid;
  }

  isLoggedIn(): boolean {
    return this.getCurrentUser() !== null;
  }

  getAuth() {
    return auth;
  }

  async getCurrentUserIdToken(): Promise<string> {
    if (this.getCurrentUser() === null) {
      throw new Error('User is not logged in');
    }
    const tokenResult = await this.getCurrentUser().getIdTokenResult();
    const expirationTime =
      new Date(tokenResult.expirationTime).getTime() / 1000; // convert to seconds
    const currentTime = new Date().getTime() / 1000; // convert to seconds
    const tokenRefreshThreshold = 60 * 10; // 10 minutes
    if (expirationTime - currentTime < tokenRefreshThreshold) {
      return await this.getCurrentUser().getIdToken(true);
    }
    return tokenResult.token;
  }

  getFirestoreRef(userId: string, collection: string, id: string): any {
    return doc(firestore, 'users', userId, collection, id);
  }

  async getFirestoreDocument(
    userId: string,
    collection: string,
    id: string
  ): Promise<any> {
    const docRef = this.getFirestoreRef(userId, collection, id);
    const docSnap = await getDoc(docRef);
    return docSnap;
  }

  getUploadPath(userId: string, fileName: string, subFolder?: string): string {
    let path = 'uploads';
    if (subFolder !== null) {
      path += '/' + subFolder;
    }
    return this.getPath(userId, fileName, path);
  }

  getAssignmentMetadataPath(
    userId: string,
    fileName: string,
    assignmentMetadataId: string
  ): string {
    const path = 'assignment_metadata/' + assignmentMetadataId;
    return this.getPath(userId, fileName, path);
  }

  getPath(userId: string, fileName: string, subFolder: string): string {
    return 'users/' + userId + '/' + subFolder + '/' + fileName;
  }

  getOutputPath(userId: string, jobId: string): string {
    return this.getPath(userId, jobId, 'output');
  }

  async listFiles(folderPath: string): Promise<string[]> {
    const storageInstance = getStorage();
    const docRef = storageRef(storageInstance, folderPath);

    try {
      const listResult = await listAll(docRef);
      const fileNames = listResult.items.map((item) => item.name);
      return fileNames;
    } catch (error) {
      console.error(`Error listing files in directory: ${folderPath}`, error);
      throw error;
    }
  }

  async downloadFileAsText(filePath: string): Promise<string> {
    const firebaseFileDownloader = new FirebaseFileDownloader(storage);
    return firebaseFileDownloader.downloadFileAsText(filePath);
  }

  async uploadAssignmentMetadata(
    userId: string,
    file: File,
    assignmentMetadataId: string
  ): Promise<string> {
    const filePath = this.getAssignmentMetadataPath(
      userId,
      file.name,
      assignmentMetadataId
    );
    return this.upload(file, filePath);
  }

  async uploadFile(
    userId: string,
    file: File,
    subFolder?: string
  ): Promise<string> {
    const filePath = this.getUploadPath(userId, file.name, subFolder);
    return this.upload(file, filePath);
  }

  async upload(file: File, filePath: string): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const metadata = {
          contentType: file.type,
        };
        const docRef = storageRef(storage, filePath);
        const uploadTask = uploadBytesResumable(docRef, file, metadata);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload for ${file.name} is ${progress}% done`);
          },
          (error) => {
            console.error(`Error uploading ${file.name}:`, error);
            reject(error);
          },
          () => {
            console.log('Upload complete');
            resolve(filePath);
          }
        );
      } catch (error) {
        console.error(`Error uploading: `, error);
        reject(error);
      }
    });
  }

  async createAssignmentMetadataInfo(
    userId: string,
    assignmentMetadataContent: AssignmentMetadataContentV1
  ): Promise<AssignmentMetadataInfo> {
    try {
      const promptArgsFilename = 'prompt_args.json';
      const assignmentMetadataId = uuidv4(); // Generate a unique UUID
      const blob = new Blob(
        [
          JSON.stringify(
            { ...assignmentMetadataContent, assignmentMetadataId },
            null,
            2
          ),
        ],
        { type: 'application/json' }
      );
      const file = new File([blob], promptArgsFilename, {
        type: 'application/json',
      });
      const assignmentMetadataPath = await this.uploadAssignmentMetadata(
        userId,
        file,
        assignmentMetadataId
      );

      const assignmentMetadataRef = this.getFirestoreRef(
        userId,
        assignmentMetadataCollection,
        assignmentMetadataId
      );

      await setDoc(assignmentMetadataRef, {
        assignment_metadata_id: assignmentMetadataId,
        assignment_metadata_path: assignmentMetadataPath,
        course: assignmentMetadataContent.course,
        question_truncated: assignmentMetadataContent.question,
        version: assignmentMetadataContent.version,
        created_at: serverTimestamp(),
      });

      return {
        assignment_metadata_path: assignmentMetadataPath,
        assignment_metadata_id: assignmentMetadataId,
      };
    } catch (e) {
      console.error('Error creating rubric:', e);
      throw e;
    }
  }

  async createAssignment(
    userId: string,
    assignmentMetadataPath: string,
    assignmentMetadataId: string
  ): Promise<string> {
    try {
      const assignmentId = uuidv4(); // Generate a unique UUID
      const assignmentRef = this.getFirestoreRef(
        userId,
        assignmentsCollection,
        assignmentId
      );

      await setDoc(assignmentRef, {
        assignment_id: assignmentId,
        assignment_metadata_path: assignmentMetadataPath,
        assignment_metadata_id: assignmentMetadataId,
        created_at: serverTimestamp(),
      });
      return assignmentId;
    } catch (e) {
      console.error('Error creating assignment:', e);
      throw e;
    }
  }

  async getAssignment(userId: string, assignmentId: string): Promise<any> {
    try {
      const assignmentDocRef = this.getFirestoreRef(
        userId,
        assignmentsCollection,
        assignmentId
      );
      const assignmentDocSnap = await getDoc(assignmentDocRef);

      if (!assignmentDocSnap.exists()) {
        console.error(
          `Assignment ${assignmentId} not found for user ${userId}`
        );
        return null;
      }

      return assignmentDocSnap.data();
    } catch (e) {
      console.error('Error fetching assignment:', e);
      throw e;
    }
  }

  async updateAssignmentWithJobIds(
    userId: string,
    assignmentId: string,
    conversionJobId: string,
    gradingJobId: string
  ): Promise<void> {
    try {
      const assignmentDocRef = this.getFirestoreRef(
        userId,
        assignmentsCollection,
        assignmentId
      );

      await updateDoc(assignmentDocRef, {
        conversion_job_id: conversionJobId,
        grading_job_id: gradingJobId,
      });

      console.log(
        `Assignment ${assignmentId} updated with conversion job ID ${conversionJobId} and grading job ID ${gradingJobId}`
      );
    } catch (e) {
      console.error('Error updating assignment with job ID:', e);
      throw e;
    }
  }

  async createJob(userId: string, jobType: string): Promise<string> {
    try {
      const jobsRef = collection(
        firestore,
        usersCollection,
        userId,
        jobsCollection
      );

      const newJobDoc = await addDoc(jobsRef, {
        status: 'created',
        percent_complete: null,
        created_at: serverTimestamp(),
        job_type: jobType,
      });
      return newJobDoc.id;
    } catch (e) {
      console.error('Error creating job:', e);
      throw e;
    }
  }

  monitorFirestoreDocument(
    userId: string,
    collection: string,
    id: string,
    onSnapshotCallback: (snapshot: DocumentSnapshot) => void,
    onErrorCallback: (error: any) => void
  ): () => void {
    const docRef = this.getFirestoreRef(userId, collection, id);

    const unsubscribe = onSnapshot(docRef, (snapshot: DocumentSnapshot) => {
      if (!snapshot.exists) {
        unsubscribe();
        const errorObj = {
          message: `Document does not exist in ${collection}. Doc ID: ${id}`,
          stage: 'error',
        };
        onErrorCallback(errorObj);
        return;
      }

      // Call the provided onSnapshotCallback
      onSnapshotCallback(snapshot);
    });

    return unsubscribe;
  }

  monitorJobStatus(
    userId: string,
    jobId: string,
    setPercentFunc: (percent: number) => void,
    onCompleteCallback: () => void,
    onErrorCallback: (error: any) => void
  ): () => void {
    return this.monitorFirestoreDocument(
      userId,
      jobsCollection,
      jobId,
      (snapshot: DocumentSnapshot) => {
        const jobData = snapshot.data();
        if (jobData === undefined) {
          throw new Error('Job data is null');
        }
        if (jobData.percent_complete === 100) {
          onCompleteCallback();
        } else if (jobData.status === 'error') {
          const errorObj = {
            message: jobData.errorMessage || 'Error occurred. Job ID: ' + jobId,
            stage: 'error',
          };
          onErrorCallback(errorObj);
        } else if (jobData.percent_complete !== undefined) {
          setPercentFunc(jobData.percent_complete);
        }
      },
      onErrorCallback
    );
  }

  async getAssignmentList(userId: string): Promise<Assignment[]> {
    try {
      const assignmentsColRef = collection(
        firestore,
        usersCollection,
        userId,
        assignmentsCollection
      );

      // Construct the query to fetch assignments ordered by created_at in descending order
      const q = query(assignmentsColRef, orderBy('created_at', 'desc'));

      const querySnapshot = await getDocs(q);

      let assignments: Assignment[] = [];
      querySnapshot.forEach((doc) => {
        assignments.push(doc.data() as Assignment);
      });

      return assignments;
    } catch (e) {
      console.error('Error fetching assignments:', e);
      throw e;
    }
  }

  async getJob(jobId: string): Promise<Job | null> {
    try {
      const userId = this.getCurrentUserId();
      const jobDocRef = this.getFirestoreRef(userId, jobsCollection, jobId);
      const jobDocSnap = await getDoc(jobDocRef);

      if (!jobDocSnap.exists()) {
        console.error(`Job ${jobId} not found`);
        return null;
      }

      return jobDocSnap.data() as Job;
    } catch (e) {
      console.error('Error fetching job:', e);
      throw e;
    }
  }

  async updateAssignmentStatus(
    userId: string,
    assignmentId: string,
    status: string
  ): Promise<void> {
    try {
      const assignmentDocRef = this.getFirestoreRef(
        userId,
        assignmentsCollection,
        assignmentId
      );
      await updateDoc(assignmentDocRef, {
        status: status,
        timestamp: serverTimestamp(),
      });
    } catch (e) {
      console.error('Error updating assignment status:', e);
      throw e;
    }
  }

  // Async function to get a User document from Firestore
  async getUserDocument(userId: string): Promise<User | null> {
    try {
      const userDocRef = doc(firestore, usersCollection, userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        return userDocSnap.data() as User;
      } else {
        console.log(`No such document user=${userId}!`);
        return null;
      }
    } catch (error) {
      console.error(`Error getting document for user=${userId}:`, error);
      throw error;
    }
  }

  // Async function to set a User document in Firestore
  async setUserDocument(userId: string, userData: UserData): Promise<void> {
    try {
      const userDocRef = doc(firestore, usersCollection, userId);
      const userWithTimestamp = {
        ...userData,
        // Set createdAt only if it doesn't exist in userData
        createdAt: userData.createdAt ? userData.createdAt : serverTimestamp(),
        lastModified: serverTimestamp(),
      };
      await setDoc(userDocRef, userWithTimestamp);
      console.log(`Document successfully written for user=${userId}!`);
    } catch (error) {
      console.error(`Error writing document for user=${userId}: `, error);
      throw error;
    }
  }

  async getGradingOutput(
    assignmentId: string,
    submissionId: string
  ): Promise<GradingResult | null> {
    try {
      const userId = this.getCurrentUserId();
      const assignmentDocRef = doc(
        firestore,
        usersCollection,
        userId,
        assignmentsCollection,
        assignmentId
      );
      const assignmentDocSnap = await getDoc(assignmentDocRef);

      if (!assignmentDocSnap.exists()) {
        console.log(`Assignment ${assignmentId} not found for user ${userId}`);
        return null;
      }

      const submissionsColRef = collection(assignmentDocRef, 'submissions');
      const submissionDocRef = doc(submissionsColRef, submissionId);
      const submissionDocSnap = await getDoc(submissionDocRef);

      if (!submissionDocSnap.exists()) {
        console.log(
          `Submission ${submissionId} not found for assignment ${assignmentId}`
        );
        return null;
      }

      const submissionData = submissionDocSnap.data();
      const outputColRef = collection(submissionDocRef, 'output');
      const gradingOutputDocRef = doc(outputColRef, 'gradingOutput');
      const gradingOutputDocSnap = await getDoc(gradingOutputDocRef);

      if (!gradingOutputDocSnap.exists()) {
        console.log(`Grading output not found for submission ${submissionId}`);
        return null;
      }

      const gradingResult = {
        ...gradingOutputDocSnap.data(),
        outputFileName: extractFileNameFromPath(submissionData.output_path),
        inputPath: submissionData.submission_path,
        inputFileContent: await this.downloadFileAsText(
          submissionData.submission_path
        ),
      } as GradingResult;

      return gradingResult;
    } catch (error) {
      console.error('Error fetching grading output:', error);
      throw error;
    }
  }

  createDatabaseFieldAccessor<T>(
    documentPath: string[],
    fieldPath: string[]
  ): DatabaseFieldAccessor<T> {
    const collectionPath = documentPath.slice(0, -1);
    const collectionRef = collection(
      firestore,
      ...(collectionPath as [string, string])
    );
    const documentId = documentPath[documentPath.length - 1];
    const docRef = doc(collectionRef, documentId);
    const read = async (): Promise<T | null> => {
      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          let fieldValue = data;

          if (fieldPath) {
            for (const path of fieldPath) {
              if (
                fieldValue &&
                typeof fieldValue === 'object' &&
                path in fieldValue
              ) {
                fieldValue = fieldValue[path];
              } else {
                return null;
              }
            }
          }

          return fieldValue as T;
        } else {
          console.log(`Document not found at path: ${documentPath.join('/')}`);
          return null;
        }
      } catch (error) {
        console.error(
          `Error reading field path ${fieldPath.join(
            '.'
          )} from document at path: ${documentPath.join('/')}:`,
          error
        );
        throw error;
      }
    };

    const write = async (value: T): Promise<void> => {
      try {
        // Create the nested field path
        const nestedFieldPath = `${fieldPath.join('.')}`;

        // Prepare the update data using the nested field path
        const updateData = { [nestedFieldPath]: value };

        // Perform the update operation
        await updateDoc(docRef, updateData);
        console.log(
          `Field '${nestedFieldPath}' updated with '${value}' for document at path: ${documentPath.join(
            '/'
          )}`
        );
      } catch (error) {
        console.error(
          `Error writing field '${fieldPath.join(
            '.'
          )}' for document at path: ${documentPath.join('/')}:`,
          error
        );
        throw error;
      }
    };

    return { read, write };
  }
}

export { FirebaseApi };
