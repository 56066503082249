import { useState, useEffect } from 'react';
import { useApi } from '../contexts/ApiProvider';
import { useMock } from '../contexts/MockProvider';
import { GradingResult, Assignment, AssignmentMetadataContent } from '../Types';
import { parseGradingJson, decodeBase64 } from '.';
import axios, { type AxiosResponse } from 'axios';
import { isProduction } from '../constants';

export type ExportResult = {
  gradingResult: GradingResult;
  assignmentMetadataContent: AssignmentMetadataContent;
};

export type FetchState = {
  loading: boolean;
  error: Error | null;
  data: ExportResult | null;
};

async function fetchExportedData(
  assignmentId: string,
  submissionId: string,
  accessToken: string
): Promise<ExportResult> {
  const url = (() => {
    return isProduction ? '/api/v1/fetch_data' : 'http://localhost:8080';
  })();
  const headers = {
    'Content-Type': 'application/json',
  };

  const data = {
    payload: {
      assignment_id: assignmentId,
      submission_id: submissionId,
      token: accessToken,
    },
  };
  const response: AxiosResponse = await axios.post(url, data, { headers });

  let fetchedOutput = parseGradingJson(decodeBase64(response.data.output));

  fetchedOutput.inputFileContent = decodeBase64(response.data.submission);
  return {
    gradingResult: fetchedOutput,
    assignmentMetadataContent: JSON.parse(
      decodeBase64(response.data.assignment_metadata)
    ),
  };
}

const useFetchSingleGradingResultFromExport = (
  assignment_id: string,
  submission_id: string,
  access_token: string
): FetchState => {
  const [state, setState] = useState<FetchState>({
    loading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    if (!assignment_id || !submission_id || !access_token) {
      console.error('Invalid arguments');
      setState({
        loading: false,
        error: new Error('Invalid arguments'),
        data: null,
      });
      return;
    }

    setState((prevState) => ({ ...prevState, loading: true }));

    fetchExportedData(assignment_id, submission_id, access_token)
      .then((result) => {
        setState({ loading: false, error: null, data: result });
      })
      .catch((error) => {
        console.error(error);
        setState({ loading: false, error, data: null });
      });
  }, [assignment_id, submission_id, access_token]);

  return state;
};

export { useFetchSingleGradingResultFromExport };
