import React from 'react';

const BetaBanner = () => {
  return (
    <div className="bg-indigo-500 text-white text-center my-4 mx-10 py-2 px-4 rounded-md shadow-md">
      <p className="text-sm md:text-base font-medium">
        Owler is in beta and is free to use while in beta.{' '}
        <a href="/signup" className="underline hover:text-indigo-100">
          Try free now
        </a>
      </p>
    </div>
  );
};

export default BetaBanner;
