import React, { FC } from 'react';

type HorizontalDividerProps = {};

const HorizontalDivider: FC<HorizontalDividerProps> = () => {
  return (
    <hr className="h-0.5 border-t-0 bg-transparent bg-gradient-to-r from-transparent via-gray-400 to-transparent opacity-25 dark:opacity-100" />
  );
};

export default HorizontalDivider;
