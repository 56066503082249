import React from 'react';
import HorizontalDivider from '../hero/HorizontalDivider';

const Footer: React.FC = () => {
  return (
    <div>
      <HorizontalDivider />
      <footer className="bg-white text-gray-800 py-20 px-4">
        <div className="max-w-6xl mx-auto grid grid-cols-3 md:grid-cols-3 gap-8">
          {/* Column 1 - Brand name and description */}
          <div>
            <h2 className="text-2xl font-bold mb-2">
              <a href="/">Owler AI</a>
            </h2>
            <p className="mb-4">
              The reliable way to review, grade, and leave personalized feedback
              on student work
            </p>
          </div>
          {/* Column 2 - Links */}
          <div>
            <ul>
              <li className="mb-2">
                <p>We'd love to hear from you, say hello: </p>
                <a href="mailto:hello@owlerai.com" className="owler-a">
                  hello@owlerai.com
                </a>
              </li>
            </ul>
          </div>
          {/* Column 3 - Links */}
          <div>
            <ul>
              <li className="mb-2">
                <a href="/privacy" className="owler-a">
                  Privacy Policy
                </a>
              </li>
              <li className="mb-2">
                <a href="/terms" className="owler-a">
                  Terms of Service
                </a>
              </li>
              <li className="mb-2">
                <a href="/contact" className="owler-a">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-8">
          <p>© Copyright 2024 Owler A.I.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
