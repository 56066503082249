import React, { FC } from 'react';
import TestimonialCard from './TestimonialCard';
import MrsBaker from '../../images/hero/mrs-baker.png';
import MrsBrown from '../../images/hero/mrs-brown.png';
import MrShafer from '../../images/hero/mr-shafer.png';

const testimonials = [
  {
    quote:
      "I might cry from the relief I feel in using this product. From the bottom of my teacher's heart, thank you for making a product such as this",
    author: 'Mrs. Baker (Middle School English)',
    img: `${MrsBaker}`,
  },
  {
    quote:
      'The feedback is very close to what I would like to give students, and it cut my work load dramatically. Hallelujah!',
    author: 'Mrs. Brown (High School English)',
    img: `${MrsBrown}`,
  },
  {
    quote: 'It feels like magic, this gets me 80% of the way there!',
    author: 'Mr. Shafer (IB U.S. History)',
    img: `${MrShafer}`,
  },
];

const Testimonials: FC = () => {
  return (
    <>
      <div className="bg-white px-6 py-20 lg:px-8 z-10 ">
        <div className="mx-auto max-w-6xl py-10">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight text-gray-900 sm:text-5xl font-poppins">
              What Teachers Across The Country Are Saying
            </h2>
            <div className="mt-10 grid grid-cols-1 gap-4 sm:grid-cols-3">
              {testimonials.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  quote={testimonial.quote}
                  author={testimonial.author}
                  img={testimonial.img}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mx-auto bg-indigo-950 text-white text-center font-bold py-4 justify-center w-full">
        <h3 className="py-3 text-l md:text-3xl px-16">
          Helping hundreds of teachers save time grading
        </h3>
        <h3 className="py-3 text-l md:text-3xl px-16">
          Providing better, faster feedback to thousands of students
        </h3>
      </div>
    </>
  );
};

export default Testimonials;
