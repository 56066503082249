import React, { FC } from 'react';

const BigStatement: FC = () => {
  return (
    <>
      <div className="flex flex-col bg-gradient-to-b from-indigo-50 to-indigo-200 items-end font-bold p-20">
        <h2 className="font-poppins text-right text-3xl sm:text-5xl pb-10">
          We enable educators to <br /> focus on what they do best
        </h2>
        <h1 className="font-poppins text-5xl sm:text-7xl">
          ...teaching and inspiring
        </h1>
      </div>
    </>
  );
};

export default BigStatement;
