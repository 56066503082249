import React, { useEffect, useState } from 'react';
import { Rubric } from '../../Types';
import { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useApi } from '../../contexts/ApiProvider';

interface RubricRowProps {
  index: number;
  rubric: Rubric;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  rubricErrors: { [key: string]: string };
}

const RubricRow: React.FC<RubricRowProps> = ({
  index,
  rubric,
  assignmentMetadataContentModel,
  rubricErrors,
}) => {
  const { api, analyticsApi } = useApi();
  const userId = api.getCurrentUserId();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const updatedRubric = { ...rubric, [name]: value };
    assignmentMetadataContentModel.updateRubric(updatedRubric);
  };

  return (
    <tr key={index} className="hover:bg-gray-50">
      <td className="w-1/4 px-6 py-4">
        <label htmlFor={`rubric-name-${index}`} className="sr-only">
          Rubric Category
        </label>
        <input
          id={`rubric-name-${index}`}
          type="text"
          name="name"
          placeholder="Rubric Category"
          onChange={handleChange}
          value={rubric.name}
          className="w-full p-2 border rounded focus:ring-indigo-500 focus:border-indigo-500"
        />
        {rubricErrors[`name-${index}`] && (
          <p className="text-red-500 text-sm mt-1">
            {rubricErrors[`name-${index}`]}
          </p>
        )}
      </td>
      <td className="w-1/2 px-6 py-4">
        <label htmlFor={`rubric-description-${index}`} className="sr-only">
          Description
        </label>
        <input
          id={`rubric-description-${index}`}
          type="text"
          name="description"
          placeholder="Description"
          onChange={handleChange}
          value={rubric.description}
          className="w-full p-2 border rounded focus:ring-indigo-500 focus:border-indigo-500"
        />
        {rubricErrors[`description-${index}`] && (
          <p className="text-red-500 text-sm mt-1">
            {rubricErrors[`description-${index}`]}
          </p>
        )}
      </td>
      <td className="w-1/4 px-6 py-4">
        <div className="flex items-center">
          <label htmlFor={`rubric-points-${index}`} className="sr-only">
            Points Possible
          </label>
          <input
            id={`rubric-points-${index}`}
            type="number"
            name="points_possible"
            placeholder="Points"
            value={rubric.points_possible}
            className="w-full p-2 mr-4 border rounded focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
          />
          <button
            onClick={() => {
              assignmentMetadataContentModel.removeRubric(rubric);
              analyticsApi.logRemoveRubricCategory(userId);
            }}
            aria-label="Remove rubric"
            className="p-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <XCircleIcon className="h-6 w-6" />
          </button>
        </div>
        {rubricErrors[`points_possible-${index}`] && (
          <p className="text-red-500 text-sm mt-1">
            {rubricErrors[`points_possible-${index}`]}
          </p>
        )}
      </td>
    </tr>
  );
};

export default RubricRow;
