import React from 'react';

type ListElementSubHeaderProps = {
  date: string;
  children?: React.ReactNode; // Adding a children prop to accept additional elements
};

const ListElementSubHeader: React.FC<ListElementSubHeaderProps> = ({
  date,
  children,
}) => {
  return (
    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
      <p className="whitespace-nowrap">
        Created on <time dateTime={date}>{date}</time>
      </p>
      {children}
    </div>
  );
};

export default ListElementSubHeader;
