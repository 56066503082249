import React, { FC } from 'react';
import passFail from '../../images/hero/pass_fail.svg';
import pencil from '../../images/hero/pencil.svg';
import timer from '../../images/hero/timer.svg';
import exam from '../../images/hero/exam.svg';

const Benefits: FC = () => {
  return (
    <>
      <div className="max-w-9xl bg-indigo-800 text-white">
        <h1 className="font-poppins self-center text-center pt-16 md:pt-24 text-3xl sm:text-5xl">
          What Owler Does For Teachers
        </h1>

        <div className="md:flex md:justify-around text-center p-10">
          <div className="grid grid-cols-2 md:flex md:flex-row">
            {/* Build Rubrics */}
            <div className="flex justify-center flex-col">
              <img
                className="block scale-50 md:scale-75 self-center w-28"
                src={passFail}
              />

              <h2 className="font-poppins text-l md:text-2xl text-white">
                Build
                <br />
                rubrics
              </h2>
              <p className="hidden sm:block font-inter p-10 text-indigo-100">
                Create or leverage a pre-built rubric to more objectively grade
                assignments across classes
              </p>
            </div>

            {/* Personalized Feedback */}
            <div className="flex justify-center flex-col">
              <img
                className="block scale-50 md:scale-75 self-center w-28"
                src={pencil}
              />
              <h2 className="font-poppins text-l md:text-2xl text-white">
                Personalized
                <br />
                feedback
              </h2>
              <p className="hidden sm:block font-inter p-10 text-indigo-100">
                Leaves personalized{' '}
                <b>feedback for each student in 45 / seconds per essay</b>
              </p>
            </div>

            {/* Review Faster */}
            <div className="flex justify-center flex-col">
              <img
                className="block scale-50 md:scale-75 self-center w-28"
                src={timer}
              />
              <h2 className="font-poppins text-l md:text-2xl text-white">
                Review
                <br />
                faster
              </h2>
              <p className="hidden sm:block font-inter p-10 text-indigo-100">
                Facilitates a faster review by{' '}
                <b>
                  highlighting where in the essay the student addressed each
                  rubric criteria
                </b>
              </p>
            </div>

            {/* Second-opinion grading */}
            <div className="flex justify-center flex-col">
              <img
                className="block scale-50 md:scale-75 self-center w-28"
                src={exam}
              />
              <h2 className="font-poppins text-l md:text-2xl text-white">
                Second-opinion
                <br />
                grading
              </h2>
              <p className="hidden sm:block font-inter py-10 text-white-100">
                Indicates preliminary essay quality to provide{' '}
                <b>a second, objective opinion</b> across students and classes
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
