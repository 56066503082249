// src/SegmentService.ts

import { AnalyticsBrowser } from '@segment/analytics-next';

const apiKey = 'tsXXkOX7jJk2D1zQu2ap6reS0NJhGzWl';

class SegmentService {
  private static instance: AnalyticsBrowser;

  public static initialize(): AnalyticsBrowser {
    if (!this.instance) {
      this.instance = AnalyticsBrowser.load({ writeKey: apiKey });
    }
    return this.instance;
  }

  public static getInstance(): AnalyticsBrowser {
    if (!this.instance) {
      throw new Error('SegmentService has not been initialized.');
    }
    return this.instance;
  }

  public static reset(): void {
    this.instance.reset();
  }
}

export { SegmentService };
