import { get } from 'http';
import { GradingResult } from '../Types';
import { Buffer } from 'buffer';

// Function to truncate a string to a given number of words
export function truncateToNWords(str: string, n_words: number): string {
  const tokens = str.split(/\s+/); // Split string by spaces
  if (n_words >= tokens.length) {
    return str; // Return original string if it has n_words or fewer
  }
  return tokens.slice(0, n_words).join(' ') + '...'; // Return first n_words followed by "..."
}

// Function to get a date from seconds and nanoseconds
export function getDateFromSecondsAndNanoseconds(
  seconds: number,
  nanoseconds: number
): Date {
  const milliseconds = seconds * 1000 + nanoseconds / 1000000;
  return new Date(milliseconds);
}

// Function to format a date object to a string
export function formatDateToString(date: Date): string {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()]; // getMonth() returns a zero-based index
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

// Function to capitalize the first character of a string
export function capitalizeFirstChar(str: string): string {
  if (!str) return str; // Return the original string if it's empty or null
  const firstChar = str.charAt(0);
  if (firstChar === firstChar.toLowerCase()) {
    return firstChar.toUpperCase() + str.slice(1);
  }
  return str; // Return the original string if the first character is already uppercase
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth', // for a smooth scrolling effect
  });
}

export function parseGradingJson(
  jsonString: string,
  outputFileName?: string
): GradingResult {
  const jsonData = JSON.parse(jsonString);
  return convertJsonGradingOutputToGradingResult(
    jsonData[0].output,
    outputFileName,
    jsonData[0].input_file_path
  );
}

export function convertJsonGradingOutputToGradingResult(
  output: any,
  outputFileName?: string,
  inputPath?: string
): GradingResult {
  const grade = output.grade;
  const explanation = output.explanation;
  const suggestions = output.suggestions;
  const followUpQuestions = getProperty<string[]>(
    output,
    'followUpQuestions',
    'follow_up_questions'
  );
  const grammarMistakes = getProperty<any[]>(
    output,
    'grammarMistakes',
    'grammar_mistakes'
  );
  let rubricGrading = output.rubric_grading;
  const inputFileContent = output.inputFileContent;

  // prefer to use the outputFileName and inputPath from output, because we may have loaded
  // from firestore or from the
  if (output.outputFileName) {
    outputFileName = output.outputFileName;
  }
  if (output.inputPath) {
    inputPath = output.inputPath;
  }

  if (!Array.isArray(rubricGrading)) {
    rubricGrading = Object.entries(rubricGrading).map(([key, value]) => ({
      [key]: value,
    }));
  }

  return {
    grade,
    rubricGrading,
    explanation,
    suggestions,
    followUpQuestions,
    grammarMistakes,
    inputPath,
    outputFileName,
    inputFileContent,
  };
}

// Function to decode base64 to utf-8 string
export function decodeBase64(base64String: string): string {
  return Buffer.from(base64String, 'base64').toString('utf-8');
}

export function convertGradedFilePathToFileName(path: string): string {
  return extractFileNameFromPath(path).replace('.txt', '');
}

export function extractFileNameFromPath(path: string): string {
  return path.split('/').pop()!;
}

function getProperty<T>(
  obj: any,
  camelCaseProp: string,
  snakeCaseProp: string
): T {
  if (obj[camelCaseProp] !== undefined) {
    return obj[camelCaseProp];
  } else {
    if (obj[snakeCaseProp] === undefined) {
      throw new Error(
        `Both ${camelCaseProp} and ${snakeCaseProp} are undefined`
      );
    }
    return obj[snakeCaseProp];
  }
}

export function containsInvalidCharactersForFirestore(str: string): boolean {
  // these characters are invalid as firestore document keys
  const invalidCharsRegex = /[~*/\[\]]/;
  return invalidCharsRegex.test(str);
}
