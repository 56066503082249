// App.js
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './Layout';
import { ApiProvider } from './contexts/ApiProvider';
import { MockProvider, useMock } from './contexts/MockProvider';
import { useFirebaseLoginState } from './useFirebaseLoginState';
import UnauthenticatedRoutes from './UnauthenticatedRoutes';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import { Container } from './dependencyInjection/Container';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

type AppProps = {
  createContainer: () => Container;
};

const App: FC<AppProps> = ({ createContainer: containerFactory }) => {
  return (
    <MockProvider>
      <AppContent createContainer={containerFactory} />
    </MockProvider>
  );
};

const queryClient = new QueryClient();

const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
  key: 'owler-session-storage',
});

persistQueryClient({
  queryClient,
  persister: sessionStoragePersister,
});

const AppContent: FC<AppProps> = ({ createContainer }) => {
  // const { enableMock } = useMock();
  // enableMock();

  const userIsLoggedIn = useFirebaseLoginState();

  if (userIsLoggedIn === undefined) {
    return <div id="splash screen"></div>;
  }

  const routes = userIsLoggedIn ? (
    <AuthenticatedRoutes container={createContainer()} />
  ) : (
    <UnauthenticatedRoutes />
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        <BrowserRouter>
          <Layout>{routes}</Layout>
        </BrowserRouter>
      </ApiProvider>
    </QueryClientProvider>
  );
};

export default App;
