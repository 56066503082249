import React from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import { Container } from '../../dependencyInjection/Container';
import AssignmentView from '../../components/assignments/AssignmentView';
import { GradingResultProvider } from '../../components/review/GradingResultContext';

type ReviewDetailViewProps = {
  getAssignmentId: () => string;
  container: Container;
};

const ReviewDetailView: React.FC<ReviewDetailViewProps> = ({
  container,
  getAssignmentId,
}) => {
  return (
    <Dashboard
      currentNavigation={'Assignments'}
      children={
        <GradingResultProvider>
          <AssignmentView
            container={container}
            getAssignmentId={getAssignmentId}
          />
        </GradingResultProvider>
      }
    />
  );
};

export default ReviewDetailView;
