import React from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import axios, { type RawAxiosRequestHeaders } from 'axios';

import { useApi } from '../../contexts/ApiProvider';
import type { Assignment, GoogleClassroomSubmission } from '../../Types';
import type { AssignmentMetadataContentModel } from '../AssignmentMetadata/AssignmentMetadataContentModel';
import { AssignmentMetadataInfo } from '../AssignmentMetadata/AssignmentMetadataInfo/AssignmentMetadataInfo';
import { isProduction } from '../../constants';

let _uniqueId = 1;
function uniqueId() {
  return (_uniqueId += 1);
}

const FILE_LIMIT = 40;

export function ManageNewSubmissionsView({
  assignment,
  assignmentMetadataContentModel,
  noSubmissionsListText = 'No submissions yet. Add submissions by uploading files or pasting the submission text.',
  onClickPrevious,
  onCompleteUpload,
  onCreateGradingJob,
}: {
  assignment?: Assignment;
  assignmentMetadataContentModel: AssignmentMetadataContentModel;
  noSubmissionsListText?: string;
  onClickPrevious?: () => void;
  onCompleteUpload?: () => void;
  onCreateGradingJob: (gradingJobId: string) => void;
}) {
  const { api, analyticsApi } = useApi();
  const userId = api.getCurrentUserId();

  const fileRef = React.useRef<HTMLInputElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const [status, setStatus] = React.useState<{
    status: 'in_flight' | 'success' | 'error';
    message: string;
  } | null>(null);
  React.useEffect(() => {
    if (status?.status === 'success') {
      setTimeout(() => {
        setStatus(null);
      }, 3000);
    }
  }, [status]);

  const [newSubmissions, setNewSubmissions] = React.useState<
    (
      | {
          file: File;
          type: 'file';
        }
      | {
          name: string;
          text: string;
          type: 'plaintext';
        }
    )[]
  >([]);

  const [googleClassroomSubmissions, setGoogleClassroomSubmissions] =
    React.useState<GoogleClassroomSubmission[]>([]);

  const [isLoading, setIsLoading] = React.useState<boolean>();

  async function uploadFiles(
    assignmentId: string
  ): Promise<null | { student_id: string; file_path: string }[]> {
    // invariant
    if (!newSubmissions) return null;

    const studentData = [];
    const filesToUpload = newSubmissions
      .map((r) => {
        switch (r.type) {
          case 'file':
            if (!r.file) return null;
            return r.file;
          case 'plaintext':
            if (!r.text) return null;
            return new File(
              [new Blob([r.text], { type: 'text/plain' })],
              `${uniqueId()}-${r.name}.txt`
            );
        }
      })
      .filter(Boolean) as File[];

    for (let [index, file] of filesToUpload.entries()) {
      try {
        setStatus({
          message: `Uploading file ${index + 1} of ${filesToUpload.length}`,
          status: 'in_flight',
        });
        const filePath = await api.uploadFile(
          userId,
          file,
          assignmentId ?? undefined
        );
        // TODO: client-side file validation
        studentData.push({
          student_id: 'dummy_id_' + file.name,
          file_path: filePath,
        });
      } catch (error) {
        setStatus({
          message: 'Error uploading file',
          status: 'error',
        });
        return null;
      }
    }

    return studentData;
  }

  const handleGrading = React.useCallback(async () => {
    let assignmentId: string | null = null;
    let assignmentMetadataInfo: AssignmentMetadataInfo | null = null;

    // pre-assignment creation checks
    if (newSubmissions.length > FILE_LIMIT) {
      setStatus({
        message: `Too many submissions. Tried to upload ${newSubmissions.length} files. Maximum 40 files allowed.`,
        status: 'error',
      });
      setTimeout(() => {
        setStatus(null);
      }, 5000);
      return;
    }

    // create assignment
    if (assignment) {
      assignmentId = assignment.assignment_id;
      assignmentMetadataInfo = assignment.assignment_metadata_info;
    } else {
      /**
       * Create a new assignment metadata info if the user has made changes
       * to the metadata content or if the assignment metadata info is
       * not found.
       */
      if (
        !assignmentMetadataContentModel.AssignmentMetadataInfo ||
        assignmentMetadataContentModel.AssignmentMetadataContent !==
          assignmentMetadataContentModel.OriginalAssignmentMetadataContent
      ) {
        assignmentMetadataInfo = await api.createAssignmentMetadataInfo(
          userId,
          assignmentMetadataContentModel.AssignmentMetadataContent
        );
        assignmentMetadataContentModel.setAssignmentMetadataInfo(
          assignmentMetadataInfo
        );
      } else {
        assignmentMetadataInfo =
          assignmentMetadataContentModel.AssignmentMetadataInfo;
      }
      if (!assignmentMetadataInfo) {
        throw new Error('`assignmentMetadataInfo` not found.');
      }
      assignmentId = await api.createAssignment(
        userId,
        assignmentMetadataInfo?.assignment_metadata_path,
        assignmentMetadataInfo?.assignment_metadata_id
      );
    }

    setStatus({
      message: 'Uploading files...',
      status: 'in_flight',
    });

    try {
      // TODO: Learn what this does.
      // if the user uploaded a prompt_args.json, this function will use it instead of the form data
      // await processPromptArgsFile(files);

      // Uploading files...
      const gcStudentData = googleClassroomSubmissions.map((submission) => ({
        student_id: submission.student_id,
        file_path: submission.firebaseUrl,
      }));
      const uploadedStudentData = await uploadFiles(assignmentId);
      // Note, `studentData` will be `null` if ANY file fails to upload.
      const studentData = [...gcStudentData, ...(uploadedStudentData ?? [])];
      if (!studentData || !studentData.length) {
        setStatus({
          message: 'Error uploading files',
          status: 'error',
        });
        setTimeout(() => {
          setStatus(null);
        }, 5000);
        return;
      }

      // Creating jobs...
      const conversionJobId = await api.createJob(userId, 'convert_files');
      const gradingJobId = await api.createJob(userId, 'grading');
      await api.updateAssignmentWithJobIds(
        userId,
        assignmentId,
        conversionJobId,
        gradingJobId
      );
      onCreateGradingJob(gradingJobId);
      setStatus({
        message: 'Grading...',
        status: 'in_flight',
      });

      // Queue up grading jobs...
      const url = (() => {
        return isProduction ? '/api/v1/queue' : 'http://localhost:8080';
      })();
      const headers = await (async (): Promise<RawAxiosRequestHeaders> => {
        const idToken = await api.getCurrentUserIdToken();
        return {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
          'X-Firebase-Token': `Bearer ${idToken}`,
        };
      })();
      const data = {
        payload: {
          conversion_job_id: conversionJobId,
          grading_job_id: gradingJobId,
          file_list: studentData.map((d) => d.file_path),
          assignment_id: assignmentId,
          assignment_metadata_path:
            assignmentMetadataInfo.assignment_metadata_path,
        },
        task_type: 'convert_and_grade',
      };

      await axios.post(url, data, { headers });

      api.monitorJobStatus(
        userId,
        gradingJobId,
        (percent) => {
          if (percent > 30) {
            setStatus({
              message: 'Still grading...',
              status: 'in_flight',
            });
          }
        },
        async () => {
          setStatus({
            message: 'Done!',
            status: 'success',
          });
          setNewSubmissions([]);
          onCompleteUpload?.();
        },
        () =>
          setStatus({
            message: 'Error!',
            status: 'error',
          })
      );
    } catch (error) {
      setStatus({
        message: 'Error!',
        status: 'error',
      });
    }
  }, [api, newSubmissions, googleClassroomSubmissions]);

  const handleImport = async () => {
    const userId = api.getCurrentUserId();
    const currentLocation = window.location.href;
    if (
      !assignmentMetadataContentModel.AssignmentMetadataContent.google_classroom
    )
      return;
    const { course_id: courseId, course_work_id: courseWorkId } =
      assignmentMetadataContentModel.AssignmentMetadataContent.google_classroom;
    try {
      setIsLoading(true);
      const courseURL = isProduction
        ? '/api/v1/import_submissions'
        : 'http://localhost:8085';
      const { data } = await axios.get<GoogleClassroomSubmission[]>(
        `${courseURL}?user_id=${userId}&course_id=${courseId}&coursework_id=${courseWorkId}`
      );

      setGoogleClassroomSubmissions(
        data.map((submission) => ({
          ...submission,
          firebaseUrl: decodeURIComponent(
            submission.firebaseUrl.replace(
              'https://storage.googleapis.com/owlerai.appspot.com/',
              ''
            )
          ),
        }))
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.setItem('retryAfterConsent', 'true');
        const consentURL = isProduction
          ? '/api/v1/oauth2authorize'
          : 'http://localhost:8082';
        window.location.href = `${consentURL}?user_id=${userId}&original_url=${currentLocation}`;
      } else {
        console.error('error', error);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="mx-auto max-w-5xl p-6 space-y-6 text-grey-800">
      <h1 className="w-full text-xl font-semibold max-w-5xl">
        Upload Student Submissions
      </h1>
      <p>
        Let's upload some student documents to grade. You can upload up to 40
        files. Owler accepts Docx (.docx), PDF (.pdf), and text (.txt) files.
        You can also copy and paste submissions using the box below.
      </p>
      {assignmentMetadataContentModel.AssignmentMetadataContent
        .google_classroom && (
        <>
          <button
            onClick={handleImport}
            disabled={isLoading}
            className={`${
              isLoading ? 'owler-disabled-button' : 'owler-indigo-button'
            }`}
          >
            Import From Google Classroom
          </button>
          {isLoading && <div>Loading...</div>}
        </>
      )}
      <div className="p-4 owler-border-gray-lg">
        <div className="flex gap-x-4 items-stretch">
          <div className="flex flex-[2]">
            {newSubmissions.length || googleClassroomSubmissions.length ? (
              <div className="w-full">
                {newSubmissions.map((s, index) => (
                  <div
                    className="flex p-2 m-1 gap-3 items-center owler-border-gray-md owler-hover-indigo-border"
                    key={index}
                  >
                    <div className="max-w-[600px] min-w-0">
                      {(() => {
                        switch (s.type) {
                          case 'file':
                            return s.file.name;
                          case 'plaintext':
                            return s.name;
                        }
                      })()}
                    </div>
                    {!status && (
                      <TrashIcon
                        className="stroke-slate-500 w-4 ml-auto cursor-pointer hover:stroke-slate-950"
                        onClick={() => {
                          setNewSubmissions(newSubmissions.toSpliced(index, 1));
                          analyticsApi.logRemoveUploadedSubmission(userId);
                        }}
                      />
                    )}
                  </div>
                ))}
                {googleClassroomSubmissions.map((s, index) => (
                  <div
                    className="flex p-2 m-1 gap-3 items-center owler-border-gray-md owler-hover-indigo-border"
                    key={s.fileId}
                  >
                    <div className="grow">
                      {s.firebaseUrl.split('/').slice(-1)}
                    </div>
                    {!status && (
                      <TrashIcon
                        className="stroke-slate-500 ml-auto cursor-pointer hover:stroke-slate-950 w-[15px] shrink-0"
                        onClick={() => {
                          setGoogleClassroomSubmissions(
                            googleClassroomSubmissions.toSpliced(index, 1)
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full self-center text-center italic text-gray-600 text-sm py-8">
                {noSubmissionsListText}
              </div>
            )}
          </div>
          <div className="flex-1">
            <input
              className="inset-0 w-full h-full opacity-0 cursor-pointer"
              hidden={true}
              id="file-upload"
              multiple
              onChange={(e) => {
                if (e.target.files) {
                  setNewSubmissions([
                    ...Array.from(e.target.files).map((f) => ({
                      file: f,
                      type: 'file' as const,
                    })),
                    ...newSubmissions,
                  ]);
                  analyticsApi.logUploadFiles(userId);
                }
                if (fileRef.current) fileRef.current.value = '';
              }}
              ref={fileRef}
              type="file"
            />
            <div className="p-2 bg-slate-100 owler-border-gray-lg">
              <label
                htmlFor="file-upload"
                className="block w-full p-2 text-center border-2 border-gray-300 border-dashed rounded-md cursor-pointer owler-hover-indigo-border hover:border-2 hover:bg-indigo-100"
              >
                Upload Files
              </label>
            </div>
            <div className="text-center my-2">or</div>
            <div className="p-2 bg-slate-100 owler-border-gray-lg">
              <div className="mb-2">Paste student submission</div>
              <div className="flex flex-col gap-y-2">
                <input
                  className="w-full border-gray-400 rounded-md"
                  disabled={status?.status === 'in_flight'}
                  placeholder="Submission name"
                  ref={inputRef}
                  type="text"
                />
                <textarea
                  className="w-full border-gray-400 rounded-md"
                  disabled={status?.status === 'in_flight'}
                  placeholder="Submission content"
                  ref={textareaRef}
                />
                <button
                  className="owler-indigo-button flex-1 disabled:bg-slate-300 disabled:cursor-not-allowed"
                  onClick={() => {
                    if (inputRef.current && textareaRef.current) {
                      setNewSubmissions([
                        {
                          name: inputRef.current.value,
                          text: textareaRef.current.value,
                          type: 'plaintext',
                        },
                        ...newSubmissions,
                      ]);
                      analyticsApi.logPastedSubmission(userId);
                      inputRef.current.value = '';
                      textareaRef.current.value = '';
                    }
                  }}
                >
                  Add Response
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <button className="owler-indigo-button" onClick={onClickPrevious}>
          Previous
        </button>
        <button
          className="owler-indigo-button flex-2 disabled:bg-slate-400 disabled:cursor-not-allowed"
          disabled={
            (!newSubmissions.length && !googleClassroomSubmissions.length) ||
            Boolean(status)
          }
          onClick={handleGrading}
        >
          Submit
          <ArrowRightIcon className="w-5 h-5 ml-2" />
        </button>
      </div>
      <div>
        {status && (
          <div
            className={`p-4 text-center rounded-md ${
              status.status === 'success'
                ? 'bg-green-100 text-green-800'
                : status.status === 'in_flight'
                  ? 'bg-gray-100 text-gray-800'
                  : 'bg-red-100 text-red-800'
            }`}
          >
            {status.message}
          </div>
        )}
      </div>
    </div>
  );
}
