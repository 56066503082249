import React, { FC } from 'react';

const PrivacyPolicy: FC = () => {
  return (
    <div className="max-w-md mx-auto m-10 bg-white overflow-hidden md:max-w-3xl">
      <div>
        <h2 className="text-center text-2xl font-semibold text-gray-900">
          Owler AI Privacy Policy
        </h2>
        <p className="my-8 text-center text-gray-500">
          Last updated: February 20th, 2024
        </p>
        <p className="owler-p">
          Your privacy is important to us at Owler AI (collectively "
          <span className="font-bold">Owler AI</span>," "
          <span className="font-bold">we</span>," "
          <span className="font-bold">us</span>," or "
          <span className="font-bold">our</span>"), and we are committed to
          safeguarding, preserving, and respecting your privacy rights. This
          online privacy statement (the "
          <span className="font-bold">Statement</span>" or "
          <span className="font-bold">Policy</span>") describes how we collect,
          use, disclose, and secure the personal information we gather about you
          through our website, <a className="owler-a">https://owlerai.com/</a>,
          (the "<span className="font-bold">Site</span>"), our platform, and
          when you otherwise interact with us (collectively, the "
          <span className="font-bold">Services</span>").
        </p>
        <p className="owler-p">
          For purposes of this Statement, personal information means data that
          classifies as personal information, personal data, personally
          identifiable information, or similar terms under applicable data
          privacy and security laws and regulations. It does not include data
          excluded or exempted from those laws and regulations, such as
          aggregated, anonymized, or deidentified data. Nothing in this
          Statement will constitute an admission or evidence that any particular
          data privacy or information security law or regulation applies to
          Owler AI generally or in any specific context.
        </p>
        <p className="owler-p">
          Owler AI provides a platform that is used by school administrators and
          educators in connection with the services they provide. As explained
          in our Terms of Use, we prohibit users from submitting personal
          information into our platform. To the extent Owler AI collects,
          processes, or hosts any personal information on behalf of schools or
          educators, it is doing so as a service provider or processor, and not
          on its own behalf. This Policy does not govern how that personal
          information is collected and used. You should see the school,
          administrator, or other controlling privacy policy for information on
          their data practices.
        </p>
        <h3 className="mt-8 font-bold">1. You Consent to This Statement</h3>
        <p className="owler-p">
          You should read this Statement carefully, so that you understand our
          privacy practices and our Terms of Use. By accessing, browsing,
          downloading, or otherwise using the Services, you confirm that you
          have read, understood, and agreed with this Statement. If you do not
          agree to this Statement, you may not use the Services.
        </p>
        <p className="owler-p">
          This Statement and the Terms of Use apply regardless of how the
          Services are accessed and will cover any technologies or devices by
          which we make the Services available to you.
        </p>
        <p className="owler-p">
          We may provide you with additional privacy notices where we believe it
          is appropriate to do so. It is important that you read this Statement
          together with any other privacy notice or terms we may provide on
          specific occasions, so that you are fully aware of how and why we are
          using your data. This Statement supplements these other notices and is
          not intended to override them.
        </p>
        <p className="owler-p">
          If you have any questions or concerns about our personal information
          policies or practices, you can contact us in the methods described in
          the "<span className="font-bold">Contact Us</span>" section below.
        </p>
        <h3 className="mt-8 font-bold">
          2. What Types of Information Do We Collect?
        </h3>
        <p className="owler-p">
          We collect information you voluntarily provide directly to us,
          information that we collect automatically when you interact with the
          Services, and information collected from third parties. The categories
          of personal information that we collect and the purposes for which we
          collect that information are described below.
        </p>
        <h4 className="mt-4 font-bold">
          (a) Categories of Personal Information We Collect
        </h4>
        <p className="owler-p">
          The following list describes the categories of personal information we
          collect.
        </p>
        <ul className="owler-p list-disc pl-5">
          <li className="py-1">
            <span className="italic">Analytics Information</span>. We collect
            certain analytics information automatically as you navigate our
            Services. This includes cookies, tracking pixels, tags or similar
            tools, which may collect information about your browser, device,
            geolocation, and interactions with the Services, Site, platform, or
            emails. For more information, please view the "
            <span className="font-bold">How Do We Use "Cookies"</span> and{' '}
            <span className="font-bold">Other Tracking Technologies?"</span>{' '}
            section below. We collect this information for the purposes of
            account management, advertising and marketing, analytics and
            research, customer service, providing the Services, and website
            security and maintenance.
          </li>
          <li className="py-1">
            <span className="italic">Contact Information</span> includes name,
            email address, phone number, and other contact information you may
            submit. We collect this information when you provide it directly to
            us such as signing up for newsletters, requesting information about
            Owler AI services, creating an account, or filling out a form. We
            collect this information for the purposes of advertising and
            marketing, analytics and research, providing the Services, and
            customer service.
          </li>
          <li className="py-1">
            <span className="italic">Payment and Commercial Information</span>{' '}
            includes name, address, phone number, third party payment service
            provider-related information, debit or credit card information,
            purchase and participation history, or other payment processing
            information. We, or our service providers, collect this information
            for the purposes of account management, customer service, providing
            the Services, and website security and maintenance.
          </li>
          <li className="py-1">
            <span className="italic">
              Responses to Surveys and Questionnaires
            </span>{' '}
            includes information you provide to us when you respond to marketing
            materials, promotions, contests, or other surveys. We collect this
            information for the purposes of advertising and marketing, analytics
            and research, and customer service.
          </li>
          <li className="py-1">
            <span className="italic">
              Teacher Account and Contact Information
            </span>{' '}
            includes first name, last name, email address, telephone number,
            school, school district, school address, grade level and curriculum
            from teachers, school administrators, and other school officials
            that use the Services. We collect this information when you provide
            it directly to us such as signing up for the Services, signing up
            for newsletters, requesting information about Owler AI services,
            creating an account, or filling out a form. We collect this
            information for the purposes of account creation and maintenance,
            advertising and marketing, analytics and research, customer service,
            providing the Services, and website security and maintenance.
          </li>
          <li className="py-1">
            <span className="italic">User-Generated Content</span>. If you post,
            upload, comment, or otherwise submit content on the Services, we may
            collect the personal information about you that you submit. For
            example, if you interact with our social media accounts, we may
            collect your name and username. Be aware that as a default, any
            information you post on the Services, including without limitation
            reviews, comments, and text, may be available to and searchable by
            all users of the Services. We collect this information for the
            purposes of advertising and marketing, and analytics and research.
          </li>
        </ul>
        <h4 className="mt-4 font-bold">
          (b) Other Ways We May Collect, Use, or Share the Information
        </h4>
        <p className="owler-p">
          We may also reserve the right to collect, use, or share personal
          information for the following purposes:
        </p>
        <ul className="owler-p list-disc pl-5">
          <li className="py-1">
            <span className="italic">To Provide the Services</span>. We will use
            your personal information to provide the Services, including to
            improve operations and offerings, and for security purposes. Please
            note that our Services include processing data using open source
            artificial intelligence tools. As explained in our Terms of Use, you
            are strictly prohibited from submitting personal data into our
            platform.
          </li>
          <li className="py-1">
            <span className="italic">To Present the Site</span>. We will use
            personal information to present our Site and its contents in a
            suitable and effective manner for you and your device.
          </li>
          <li className="py-1">
            <span className="italic">For Legal Purposes</span>. We reserve the
            right to cooperate with local, provincial, state, federal and
            international officials in any investigation requiring either
            personal information or reports about lawful or unlawful user
            activity on this site. We also reserve the right to share your
            personal information to establish, exercise, or defend our legal and
            property rights, including providing information to others for the
            purposes of fraud prevention. We may also share your personal
            information with any person who we reasonably believe may apply to a
            court or other competent authority for disclosure of that personal
            information where, in our reasonable opinion, such court or
            authority would be reasonably likely to order disclosure of that
            personal information.
          </li>
          <li className="py-1">
            <span className="italic">Business Transactions or Mergers</span>. We
            reserve the right to share your personal information to third
            parties as part of any potential business or asset sale, merger,
            acquisition, investment, round of funding, or similar type of
            transaction. Additionally, if we are entering into a corporate
            transaction with a third party, we may receive personal information
            in connection with the diligence. If we close a transaction, the
            third party may transfer personal information, which we would use as
            described in this Policy.
          </li>
          <li className="py-1">
            <span className="italic">Bankruptcy or Insolvency</span>. In the
            event of bankruptcy, insolvency, or dissolution proceedings, we may
            share your personal information with third parties as part of the
            sale or reorganization process.
          </li>
          <li className="py-1">
            <span className="italic">Service Providers</span>. We use service
            providers to perform various functions on our behalf. Such service
            providers will be under contractual obligations to safeguard your
            personal information and only process it in accordance with our
            instructions, or as otherwise permitted by applicable laws. We may
            also receive personal information from service providers.
          </li>
          <li className="py-1">
            <span className="italic">Business Partners and Affiliates</span>. We
            may collect personal information from and share personal information
            with our business partners and affiliates, including legal advisors
            and financial advisors, co-branded partners, and leaderboards. We
            may combine that information with other information we collect about
            you, but we will always use the information as described in this
            Policy. We may also share information with our affiliates,
            subsidiaries, joint ventures or other companies under common
            control.
          </li>
        </ul>
        <h3 className="mt-8 font-bold">
          3. How Do We Use "Cookies" and Other Tracking Technologies?
        </h3>
        <p className="owler-p">
          We may send one or more cookies to your computer or other device. We
          may also use other similar technologies such as tracking pixels, tags,
          or similar tools when you visit our Services. These technologies can
          collect data regarding your operating system, browser type, device
          type, screen resolution, IP address, and other technical information,
          as well as navigation events and session information as you interact
          with our Services. This information allows us to understand how you
          use the Services.
        </p>
        <h4 className="mt-4 font-bold">(a) What Are Cookies?</h4>
        <p className="owler-p">
          Cookies are small files created by websites, including our Services,
          that reside on your computer’s hard drive and that store information
          about your use of a particular website. When you access our Services,
          we use cookies and other tracking technologies to:
        </p>
        <ul className="owler-p list-disc pl-5">
          <li className="py-1">
            Estimate our audience size and usage patterns;
          </li>
          <li className="py-1">
            Store information about your preferences, allowing us to customize
            our Services according to your individual needs;
          </li>
          <li className="py-1">
            Contact you to provide you with information or services that you
            request from us;
          </li>
          <li className="py-1">
            Advertise new content, events, and services that relate to your
            interests;
          </li>
          <li className="py-1">
            Provide you with more personalized content that is most relevant to
            your interest areas; and
          </li>
          <li className="py-1">Recognize when you return to our Services.</li>
        </ul>
        <p className="owler-p">
          We set some cookies ourselves and others are set by third parties. You
          can manage your cookies preference as described in the "
          <span className="font-bold">Managing Your Cookies</span>" section
          below.
        </p>
        <h4 className="mt-4 font-bold">
          (b) What Types of Cookies Do We Use and Why?
        </h4>
        <p className="owler-p">
          The following chart lists the different types of cookies that we and
          our service providers use on the Services, examples of who serves
          those cookies and links to the privacy notices and opt-out information
          of those cookie servers. Because the specific cookies we use may vary
          over time, as well as differ by the specific page you are browsing,
          the below chart is illustrative only.
        </p>
        <div className="border border-gray-200 rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 border-collapse">
            <thead className="bg-gray-50">
              <th className="px-2 py-2 text-left align-top border">
                Types of Cookies
              </th>
              <th className="px-2 py-2 text-left align-top border">Purpose</th>
              <th className="px-2 py-2 text-left align-top border">
                Who Serves (for example)
              </th>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-2 py-2 text-left align-top border">
                  Essential
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  These cookies are required for the operation of the Services
                  and enable you to move around the Services and use its
                  features. Disabling these cookies can negatively impact the
                  performance of Services.
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  <a
                    className="owler-a"
                    href="https://www.adobe.com/privacy/cookies.html"
                  >
                    Adobe
                  </a>
                  <br />
                  <a
                    className="owler-a"
                    href="https://tools.google.com/dlpage/gaoptout"
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-2 text-left align-top border">
                  Functionality
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  These cookies are used to recognize you when you return to the
                  Services. This enables us to personalize content for you and
                  remember your preferences. These cookies also enable your
                  interactions with the Services such as emailing us and
                  customer support chat.
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  <a
                    className="owler-a"
                    href="https://tools.google.com/dlpage/gaoptout"
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-2 text-left align-top border">
                  Analytics, Performance, and Research
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  These cookies, beacons, and pixels allow us to analyze
                  activities on the Services. They can be used to improve the
                  functioning of the Services. For example, these cookies
                  recognize and count the number of visitors and see how they
                  move around the Services. Analytics cookies also help us
                  measure the performance of our advertising campaigns to help
                  us improve them and to optimize the content on the Services
                  for those who engage with our advertising.
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  <a
                    className="owler-a"
                    href="https://tools.google.com/dlpage/gaoptout"
                  >
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-2 text-left align-top border">
                  Social Networking
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  These cookies are used to enable you to share pages and
                  content that you find interesting on our Services through
                  third-party social networking and other websites. These
                  cookies may also be used for advertising purposes.
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  <a
                    className="owler-a"
                    href="https://policy.pinterest.com/en/privacy-policy"
                  >
                    Pinterest
                  </a>
                </td>
              </tr>
              <tr>
                <td className="px-2 py-2 text-left align-top border">
                  Advertising
                </td>
                <td className="px-2 py-2 text-left align-top border">
                  These cookies and pixels are used to deliver relevant ads,
                  track ad campaign performance, or track email marketing.
                </td>
                <td className="px-2 py-2 text-left align-top border">N/A</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="owler-p">
          We do not use analytical tools in a manner that discloses to third
          parties that a specific person viewed specific video materials.
        </p>
        <h4 className="mt-4 font-bold">
          (c) How Long Do Cookies Stay on My Device?
        </h4>
        <p className="owler-p">
          Some cookies operate from the time you visit the Services until the
          end of that particular browsing session. These cookies, which are
          called "session cookies," expire and are automatically deleted when
          you close your Internet browser.
        </p>
        <p className="owler-p">
          Some cookies will stay on your device between browsing sessions and
          will not expire or automatically delete when you close your Internet
          browser. These cookies are called "persistent cookies" and the length
          of time they will remain on your device will vary from cookie to
          cookie. Persistent cookies are used for a number of purposes, such as
          storing your preferences so that they are available for your next
          visit and to keep a more accurate account of how often you visit the
          Services, how your use of the Services may change over time, and the
          effectiveness of advertising efforts.
        </p>
        <h4 className="mt-4 font-bold">(d) Managing Your Cookies</h4>
        <p className="owler-p">
          It may be possible to block cookies by changing your Internet browser
          settings to refuse all or some cookies. If you choose to block all
          cookies (including essential cookies), you may not be able to access
          all or parts of the Services.
        </p>
        <p className="owler-p">
          You can find out more about cookies and how to manage them by visiting{' '}
          <a href="www.AboutCookies.org">www.AboutCookies.org</a>.
        </p>
        <h4 className="mt-4 font-bold">
          (e) Does the Site Respond to "Do Not Track" Signals?
        </h4>
        <p className="owler-p">
          At this time, our Site does not respond differently based on a user’s
          Do Not Track signal.
        </p>
        <h3 className="mt-8 font-bold">4. Nevada Users’ Rights</h3>
        <p className="owler-p">
          Under Nevada law, certain Nevada consumers may opt out of the sale of
          certain personally identifiable information for monetary consideration
          to a person for that person to license or sell such information to
          additional persons. We do not sell personally identifiable information
          under Nevada law.
        </p>
        <p className="owler-p">
          If you are a Nevada resident who has purchased or leased goods or
          services from us, you may (A) submit a request to opt out of any
          potential future sales, and/or (B) request changes to your Covered
          Information by emailing us at{' '}
          <a className="owler-a" href="mailto:info@owlerai.com">
            info@owlerai.com
          </a>
          . Please note we will take reasonable steps to verify your identity
          and the authenticity of the request.
        </p>
        <h3 className="mt-8 font-bold">5. Advertising and Marketing Choice</h3>
        <p className="owler-p">
          We respect your rights in how your personal information is used and
          shared. We may communicate with you via email, telephone, postal mail,
          and/or your mobile device about our products and services. If at any
          time you would like to unsubscribe from receiving future marketing
          emails, you can email us at{' '}
          <a className="owler-a" href="mailto:info@owlerai.com">
            info@owlerai.com
          </a>{' '}
          or follow the instructions at the bottom of each applicable email, and
          we will promptly remove you from those marketing correspondences that
          you choose to no longer receive. Please note, however, that we may
          still need to contact you regarding other matters.
        </p>
        <h3 className="mt-8 font-bold">
          6. How Long Is Your Personal Information Kept
        </h3>
        <p className="owler-p">
          We will retain your personal information until the personal
          information is no longer necessary to accomplish the purpose for which
          it was provided. We may retain your personal information for longer
          periods for specific purposes to the extent that we are obliged to do
          so in accordance with applicable laws and regulations, to protect you,
          other people, and us from fraud, abuse, an unauthorized access, as
          necessary to protect our legal rights, or for certain business
          requirements.
        </p>
        <p className="owler-p">
          We will delete your personal information when it is no longer
          necessary for the purpose for which it was collected, or upon your
          request, subject to exceptions as discussed in this Statement or under
          applicable law, contract, or regulation.
        </p>
        <p className="owler-p">
          You can access and update certain personal information Owler AI holds
          about you at any time by logging into your account via the Services.
        </p>
        <h3 className="mt-8 font-bold">7. Our Commitment to Data Security</h3>
        <p className="owler-p">
          The security of your personal information is important to us. We take
          various reasonable organizational, administrative, and technical
          measures to protect your personal information from unauthorized
          access, disclosure, alteration, or destruction. If required by law to
          do so, we will notify you and/or the relevant supervisory authority in
          the event of a data breach.
        </p>
        <p className="owler-p">
          However, we cannot and do not guarantee complete security, as it does
          not exist on the Internet.
        </p>
        <h3 className="mt-8 font-bold">
          8. Where Your Personal Information Is Held
        </h3>
        <p className="owler-p">
          We process personal information on our servers in the United States of
          America, and may do so in other countries. If you use our Services or
          otherwise provide us with information from outside of the United
          States, you expressly consent to the transfer of your data to the
          United States, the processing of your data in the United States, and
          the storage of your data in the United States.
        </p>
        <h3 className="mt-8 font-bold">9. Third Party Links</h3>
        <p className="owler-p">
          Owler AI's Services may contain links to third-party websites. When we
          provide links, we do so only as a convenience and we are not
          responsible for any content of any third-party website or any links
          contained within. It is important to note that this Statement only
          applies to Owler AI’s Services. We are not responsible and assume no
          responsibility for any personal information collected, stored, or used
          by any third party as a result of you visiting third-party websites.
          We also advise that you carefully read the privacy notice of any
          third-party websites you choose to visit.
        </p>
        <h3 className="mt-8 font-bold">10. Children's Privacy</h3>
        <p className="owler-p">
          Protecting the privacy of the very young is especially important. Our
          Services are intended for use by teachers and administrators—they are
          not intended for children under 13 years of age, and we do not
          knowingly collect personal information from children under 13. In the
          event that we learn that we have collected personal information from a
          child under age 13 without verification or parental consent, we will
          immediately delete that information. If you believe that we might have
          any information from or about a child under 13, please contact us
          using the information provided in the "
          <span className="font-bold">Contact Us</span>" section below.
        </p>
        <h3 className="mt-8 font-bold">11. Policy Changes</h3>
        <p className="owler-p">
          This Statement may change from time to time. If we need to change this
          Statement at some point in the future, we will post any changes on
          this page. If we make a significant or material change to this
          Statement we will notify you via email. You should check these terms
          when you use the Site. Your continued use of the Services constitutes
          acceptance of the most current version of this Statement.
        </p>
        <h3 className="mt-8 font-bold">12. Contact Us</h3>
        <p className="owler-p">
          If you have any questions about this Privacy Policy, please contact us
          by email at{' '}
          <a className="owler-a" href="mailto:info@owlerai.com">
            info@owlerai.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
