import React, { useState, SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/ApiProvider'; // Adjust the import path if necessary
import { handleAuthError } from '../../utils/authErrorHandler';

export default function SignupView() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { api, analyticsApi } = useApi();

  const handleSignup = async (event: SyntheticEvent) => {
    event.preventDefault();

    try {
      const { userId } = await api.signup(email, password);
      analyticsApi.logSignupPageCompletion(userId);
      navigate('/assignments');
    } catch (error: any) {
      handleAuthError(error, setError);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create a new account
          </h2>
          {error && <p className="text-center text-red-500">{error}</p>}
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSignup}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full justify-center owler-indigo-button"
              >
                Sign Up
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            By signing up, you agree to our
            <br />
            <Link
              to="/terms"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Terms of Service
            </Link>
            {' and '}
            <Link
              to="/privacy"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Privacy Policy
            </Link>
          </p>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{' '}
            <Link
              to="/login"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign in here
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
