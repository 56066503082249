import React, { useState } from 'react';
import { GoogleClassroomCourse, GoogleClassroomCourseWork } from '../../Types';
import { GCAssignmentView } from './GCAssignmentView';

type GCCourseViewProps = {
  course: GoogleClassroomCourse;
  onClick: (id: string) => void;
  isSelected: boolean;
  onSelect: (
    courseTitle: string,
    assignment: GoogleClassroomCourseWork,
    courseId: string
  ) => void;
};

export const GCCourseView = ({
  course,
  onClick,
  isSelected,
  onSelect,
}: GCCourseViewProps) => {
  const [selectedAssignment, setSelectedAssignment] = useState<string>();
  const handleClickAssignment = (id: string) => {
    if (id === selectedAssignment) setSelectedAssignment(undefined);
    else setSelectedAssignment(id);
  };
  return (
    <>
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={() => onClick(course.id)}
      >
        <span className="font-semibold text-lg">{course.name}</span>
        <span className="text-sm text-gray-500">{isSelected ? '▲' : '▼'}</span>
      </div>
      {isSelected && (
        <ul className="list pl-8 space-y-2 mt-2">
          {!course.assignments.length && 'No Assignments'}
          {course.assignments.map((assignment) => (
            <li
              key={assignment.id}
              className={`owler-li-bubble owler-border-gray-lg ${
                selectedAssignment === assignment.id
                  ? 'bg-indigo-100 border-indigo-300'
                  : 'hover:bg-gray-50 bg-white'
              }`}
            >
              <GCAssignmentView
                courseTitle={course.name}
                courseId={course.id}
                assignment={assignment}
                onClick={handleClickAssignment}
                onSelect={onSelect}
                isSelected={selectedAssignment === assignment.id}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
